import React, { useState} from 'react';
import Order from './Order';
import PendingItems from './PendingItems';
import CancelledOrders from './CancelledOrders';
import './myProfile.css';
import { t } from '../../translations/Translate';

function MyOrders() {
    const [currentTab,setCurrentTab]=useState(1);


  return (
    <section className="col-md-12 col-lg-12 col-12 px-0">
        <h6 className="mb-0 page-title">{t('MY ORDERS')}</h6>
        <hr className="w-100 mt-3 mb-0"></hr>
        <div className="col-md-12 collg-12 col-12 px-0">
            <ul className="orders-menu">
                    <li className="orders-menu">
                        <a className={currentTab === 1?"btn-active":"btn-tab"} onClick={()=>setCurrentTab(1)}>{t('Orders')}</a>
                    </li>
                    <li className="orders-menu">
                        <a className={currentTab === 2?"btn-active":"btn-tab"} onClick={()=>setCurrentTab(2)}>{t('Pending orders')}</a>
                    </li>
                    <li className="orders-menu">
                        <a className={currentTab === 3?"btn-active":"btn-tab"} onClick={()=>setCurrentTab(3)}>{t('Cancelled orders')}</a>
                    </li>
                </ul>
                <hr className="w-100 mt-2"></hr>
        </div>

        <div>
            {currentTab === 1 && <Order/>}
            {currentTab === 2 && <PendingItems/>}
            {currentTab === 3 && <CancelledOrders/>}
        </div>
    </section>

  );
}

export default MyOrders;
