import React, {useEffect}  from 'react'
import './orderOverview.css';
import { Link } from "react-router-dom";
import {useParams}  from "react-router-dom"
import { OCAlert } from '@opuscapita/react-alerts';
import { t } from '../../translations/Translate';
import  home_img from '../commoncss/images/home.png';
import '../commoncss/common.css';

// import OrderSummary from 'components/mycart/OrderSummary.js';
import MyCartView from 'components/mycart/MyCartView.js';
const shippingAddress = JSON.parse(localStorage.getItem('shippingAddress'));
const billingAddress = JSON.parse(localStorage.getItem('billingAddress'));

const Orderoverview = () =>{
   const params = useParams();
   useEffect(() => {
      if(params.status === '1')
         OCAlert.alertError(t('Error while making payment please try again'), { timeOut: window.ALERT_ERROR_TIME });
      else if(params.status === '2')
         OCAlert.alertError(t('Your order allocation time is completed'), { timeOut: window.ALERT_ERROR_TIME });

   }, [])

  const orderId = params['orderId']
  return (
     <>
     {/* <div className ="container float-left mr-0">
     <section >
       <div className = "row">
          <div className ="col-md-12"> */}
               {/* <div> */}
                  <MyCartView titleChange={1}/>
                  {/* </div> */}
           {/* </div> */}
        {/* </div> */}
        {/*<div className = "row layout-space">
           <div className = "col">
           <p>SHIPPING ADDRESS<span><Link to = {('/editAddress/'+shippingAddress['id']+'/'+orderId+'?from='+'orderoverview'+'&'+'address='+'shipping')}>edit</Link></span></p>
           <h6>{shippingAddress['title']}</h6>
           <h6>{shippingAddress['firstName'] +shippingAddress['lastName']}</h6>
           <span>{shippingAddress['address1']}</span>
           <span>{shippingAddress['city']}</span>,
           <span>{shippingAddress['zipCode']}</span>
           </div>
           <div className = "col">
           <p>BILLING ADDRESS <Link to = {('/editAddress/'+billingAddress['id']+'/'+orderId+'?from='+'orderoverview'+'&address='+'billing')}>edit</Link></p>
           <h6>{billingAddress['title']}</h6>
           <h6>{billingAddress['firstName'] +billingAddress['lastName']}</h6>
           <span>{billingAddress['address1']}</span>
           <span>{billingAddress['city']}</span>,
           <span>{billingAddress['zipCode']}</span>
           </div>
        </div>*/}
        {/* </section>
    </div> */}
    </>
  )
}
export default Orderoverview;
