import React,{ Component } from 'react';
import './ProductOverview.css';
import { withRouter } from 'react-router-dom';
// import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import { useHistory } from "react-router-dom";
import {Apiservice} from 'services/Apiservices';
import "react-image-gallery/styles/css/image-gallery.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { OCAlert } from '@opuscapita/react-alerts';
import Image from 'react-bootstrap/Image'
import ImageMagnifier from "./ImageMagnifier";
import { t } from 'translations/Translate'
import  home_img from '../commoncss/images/home.png';
import '../commoncss/common.css';
import {GetQtyFromString} from 'services/Helper';

class ProductOverview extends Component {
    constructor(props){
        super(props)
        this.state  = {
            response:[],
            selectedColor:"",
            selectedSize:"",
            quantity:1,
            description:"",
            stock:0,
            variantId:Number(props.match.params.variantId),
            productId:Number(props.match.params.productId),
            title:"",
            brand:"",
            noData:false,
            gender:"",
            price:null,
            sku:"",
            hasUnlimitedStock:false,
            mainImages:"",
            subImages:[],
            minquantityAllowed:0,
            maxQuantityAllowed:0,
            saleId:0,
            category:"",
            freeshipping:"false",
            productUrl:"",
            discountprice:null,
            is_expired:"",
            colorsAvailable:[],
        }
        this.handleMinQuantity = this.handleMinQuantity.bind(this);
        this.handleMaxQuantity = this.handleMaxQuantity.bind(this);
    }
    componentDidMount(){
        var data;
        if(window.location.href.includes('/productoverview/sale')){
            if (localStorage.getItem('user_sales').includes(this.props.match.params.saleId)) {
                data={
                    "id":this.state.productId,
                    "user_id":JSON.parse(localStorage.getItem('user')).id,
                    "saleid":[this.props.match.params.saleId]
                };
            }
            else {
                this.setState({noData:true})
                return OCAlert.alertError(t('User not authorized for this sale'), {timeOut: window.ALERT_ERROR_TIME});
            }
        }else{
          let saleIds = localStorage.getItem('user_sales');

            data={
              "id":this.state.productId,
              "user_id":JSON.parse(localStorage.getItem('user')).id,
              "userSaleIds" : (saleIds!== 'undefined' && saleIds !== null && saleIds !== "" && saleIds.length!==0) ? saleIds.split(","):[],
            };
        }
        Apiservice.service(window.FETCH_PRODUCT_DETAIL, 'POST', data)
        .then( res => {
            if(res.status === 200){
                if(res.data.data!==undefined){
                var response = res.data.data[0];
                if(response!=='undefined' && response!==null){
                    Object.values(response).map((item)=>{
                        if(this.state.variantId === item.variant_Id){

                            this.setState({
                                title:item.product_title,
                                brand:item.product_Brand,
                                price:item.variant_price,
                                stock:item.variant_stock,
                                description:item.product_Description,
                                selectedColor:item.variant_productColor,
                                selectedSize:item.variant_size,
                                variantId:item.variant_Id,
                                productId:item.product_Id,
                                minQty:(item.variant_minQty===null)?1:item.variant_minQty,
                                maxQty:item.variant_maxQty,
                                quantity:(item.variant_minQty===null)?1:item.variant_minQty,
                                hasUnlimitedStock:item.variant_hasUnlimitedStock,
                                subImages:item.variant_SubImages,
                                mainImages:item.variant_mainImage,
                                discountprice:item.discountprice,
                                response:response,
                                is_expired:item.variant_is_expired,
                            })
                        }
                        return "";
                    })
                }
            }
        }
        })
    }
    handleSize(){
        var result = Object.values(this.state.response).map((item)=>{

                           if(this.state.selectedColor === item.variant_productColor){
                               if(this.state.selectedSize === item.variant_size){
                                    return (
                                        <div className="sizeCssChange">
                                            <button onClick={()=>this.handleSizeChanges(item.variant_Id , item.product_Id )} className="sizesFontStyle square opacityofsize borderColorHighlight">{item.variant_size}</button>
                                        </div>
                                    )
                                }else{
                                    return (
                                        <div className="colorsDisplayStyle">
                                            <button onClick={()=>this.handleSizeChanges(item.variant_Id , item.product_Id )} className="sizesFontStyle square ">{item.variant_size}</button>
                                        </div>
                                    )
                                }
                            }

                    })
        return result;
    }

    handleColor(){
        var result = Object.values(this.state.response).map((item)=>{
                if(!this.state.colorsAvailable.includes(item.variant_productColor)){
                     this.state.colorsAvailable.push(item.variant_productColor)
                     if(item.variant_productColor === this.state.selectedColor){
                        return (
                            <div className="colorBorder colorSection opacityofcolor">
                                <button onClick={()=>this.handleColorChanges(item.variant_Id , item.product_Id )} className="circle colorsDisplayStyle opacityofcolor" style={{backgroundColor:item.variant_productColor}}>
                                </button>
                            </div>
                        )

                    }else{
                        return (
                            <div className="colorSection">
                                <button onClick={()=>this.handleColorChanges(item.variant_Id , item.product_Id )} className="circle colorsDisplayStyle" style={{backgroundColor:item.variant_productColor}}>
                                </button>
                            </div>
                        )
                    }

                }

            })
        return result;
    }
    handleColorChanges(variant_Id , variant_Product_Id){
        this.setState({
            colorsAvailable:[]
        })
        Object.values(this.state.response).map((item)=>{
            if(variant_Id === item.variant_Id ){
                this.setState({
                    title:item.product_title,
                    price:item.variant_price,
                    stock:item.variant_stock,
                    selectedColor:item.variant_productColor,
                    selectedSize:item.variant_size,
                    variantId:variant_Id,
                    productId:variant_Product_Id,
                    hasUnlimitedStock:item.variant_hasUnlimitedStock,
                    minQty:(item.variant_minQty===null)?1:item.variant_minQty,
                    maxQty:item.variant_maxQty,
                    quantity:(item.variant_minQty===null)?1:item.variant_minQty,
                    subImages:item.variant_SubImages,
                    mainImages:item.variant_mainImage,
                    discountprice:item.discountprice,
                    is_expired:item.variant_is_expired,
                })
            }
            return "";

        })
       
    }
    handleSizeChanges(variant_Id , variant_Product_Id){
        this.setState({
            colorsAvailable:[]
        })
        Object.values(this.state.response).map((item)=>{
            if(variant_Id === item.variant_Id ){
                this.setState({
                    title:item.product_title,
                    price:item.variant_price,
                    stock:item.variant_stock,
                    selectedColor:item.variant_productColor,
                    selectedSize:item.variant_size,
                    variantId:variant_Id,
                    productId:variant_Product_Id,
                    minQty:(item.variant_minQty===null)?1:item.variant_minQty,
                    maxQty:item.variant_maxQty,
                    quantity:(item.variant_minQty===null)?1:item.variant_minQty,
                    hasUnlimitedStock:item.variant_hasUnlimitedStock,
                    subImages:item.variant_SubImages,
                    mainImages:item.variant_mainImage,
                    discountprice:item.discountprice,
                    is_expired:item.variant_is_expired,
                })
            }
            return "";
        })
    }
    handleImageChanges(image){
        this.setState({
            colorsAvailable:[],
            mainImages:image
        })
    }
    handleVedioChanges(vedio){
        this.setState({
            colorsAvailable:[],
            mainImages:vedio
        })
    }

    addToCart(passedvalue){
        var argumentdata = passedvalue;
        var id = JSON.parse(localStorage.getItem('user')).id;
        let data = {
            "user_id": id,
            "type": "addItem",
            "purchasableId":this.state.variantId,
            "quantity":this.state.quantity === null ? '1' : (this.state.quantity === '0' ? '1' : this.state.quantity),
            'sale_id': this.props.match.params.saleId === undefined ? '' : (this.props.match.params.saleId)
        }
        Apiservice.service(window.ADD_TO_CART, 'POST', data).then(res => {
            if(res.data.STATUS===201){
                document.querySelector('.cartcount').innerHTML = res.data.DATA.totalCartItems;
                document.querySelector('.cartcount-main').innerHTML = res.data.DATA.totalCartItems;
                OCAlert.closeAlerts();
                OCAlert.alertSuccess(t('Product added to cart'), {timeOut: window.ALERT_ERROR_TIME});
                if(argumentdata === "buynow"){
                    window.location = '/mycart';
                }
            }
        })
        .catch(error => {
          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
          if (error.response.data.DATA.message[0].includes('Qty ne doit pas être inférieur à')) {
              let qty = GetQtyFromString(error.response.data.DATA.message[0]);
              OCAlert.closeAlerts();
              OCAlert.alertError(t('Qty must not be less than ') + qty, {timeOut: window.ALERT_ERROR_TIME});
          }
          else if (error.response.data.DATA.message[0].includes('La quantité maximale de commande pour cet article est')) {
              let qty = GetQtyFromString(error.response.data.DATA.message[0]);
              OCAlert.closeAlerts();
              OCAlert.alertError(t('The maximum order quantity for this item is ') + qty, {timeOut: window.ALERT_ERROR_TIME});
          }
          else if (error.response.data.DATA.message[0].includes('La quantité minimale de commande pour cet article est')) {
               let qty = GetQtyFromString(error.response.data.DATA.message[0]); 
              OCAlert.closeAlerts();
              OCAlert.alertError(t('The minimum order quantity for this item is ') + ' ' + qty, {timeOut: window.ALERT_ERROR_TIME});
          }
          else if (error.response.data.DATA.message[0].includes('Il reste seulement')) {
              let qty = GetQtyFromString(error.response.data.DATA.message[0]);
              OCAlert.closeAlerts();
              OCAlert.alertError(t('There are only') +' '+ qty +' '+ t('items left in stock.'), {timeOut: window.ALERT_ERROR_TIME});
          }
          else {
              OCAlert.closeAlerts();
              OCAlert.alertError(error.response.data.DATA.message[0], { timeOut: window.ALERT_ERROR_TIME });
          }
        });
    }

    handleMinQuantity(){
        if(this.state.hasUnlimitedStock === true || this.state.minQty<=this.state.stock){
            if(this.state.quantity > this.state.minQty){
                this.setState({
                    colorsAvailable:[],
                    quantity : this.state.quantity-1
                })
            }
            else{
                OCAlert.closeAlerts();
                OCAlert.alertError(t("Can't decrease the quantity, you already reached minimum quantity"), { timeOut: window.ALERT_ERROR_TIME });
            }
        }
        else{
            this.setState({
                colorsAvailable:[],
                quantity : 1
            })
        }
    }
    
    handleMaxQuantity(){
        if(this.state.hasUnlimitedStock===false){
            if(this.state.maxQty!==null && this.state.maxQty!==0){
                if(this.state.maxQty <= this.state.stock){
                    if((this.state.quantity < this.state.maxQty )){
                        this.setState({
                            colorsAvailable:[],
                            quantity : this.state.quantity+1
                        })
                    }else{
                        OCAlert.closeAlerts();
                        OCAlert.alertError(t('Maximum quantity is exceeded'), { timeOut: window.ALERT_ERROR_TIME });
                    }
                }else{
                    if((this.state.quantity < this.state.stock)){
                        this.setState({
                            colorsAvailable:[],
                            quantity : this.state.quantity+1
                        })
                    }else{
                        OCAlert.closeAlerts();
                        OCAlert.alertError(t('Maximum quantity is exceeded.'), { timeOut: window.ALERT_ERROR_TIME });
                    }
                }
            }else{
                if(this.state.maxQty===null || this.state.maxQty===0 ){
                    if((this.state.quantity < this.state.stock ) ){
                        this.setState({
                            colorsAvailable:[],
                            quantity : this.state.quantity+1
                        })
                    }else{
                        OCAlert.closeAlerts();
                        OCAlert.alertError(t('Maximum quantity is exceeded.'), { timeOut: window.ALERT_ERROR_TIME });
                    }
                }
            }
        }else{
            if(this.state.maxQty===null || this.state.maxQty===0){
                this.setState({
                    colorsAvailable:[],
                    quantity : this.state.quantity+1
                })
            }else if(this.state.maxQty!==null && this.state.maxQty!==0 && this.state.stock===0){
                if(this.state.quantity < this.state.maxQty ){
                    this.setState({
                        colorsAvailable:[],
                        quantity : this.state.quantity+1
                    })
                }else{
                    OCAlert.closeAlerts();
                    OCAlert.alertError(t('Maximum quantity is exceeded.'), { timeOut: window.ALERT_ERROR_TIME });
                }
            }
        }
    }
    render() {
        return(
            <div className='desktop-right-content'>
            <div className="layout-space fixed-layout">
                <nav aria-label="breadcrumb" className="mt-4 pt-1">

                  <ol className="breadcrumb bg-transparent">
                  <li className=""><p class="backButton leftAdjustmentBreadscrum leftAdjustmentBreadscrumdetailpage " onClick={this.props.history.goBack}> </p></li>
                    <li className="breadcrumb-item home"><Link to="/"><img  src={home_img} alt=""/></Link></li>
                    {(window.location.pathname.includes('sale')) && <li className="breadcrumb-item"><button className="breadcrumbButton" onClick={this.props.history.goBack}>{t('Products - sale')}</button></li>}
                    {(window.location.pathname.includes('cart')) && <li className="breadcrumb-item"><Link to="/mycart">{t('My cart')}</Link></li>}
                    {!(window.location.pathname.includes('sale')) && !(window.location.pathname.includes('cart')) && <li className="breadcrumb-item"><button className="breadcrumbButton" onClick={this.props.history.goBack}>{t('Products - filter')}</button></li>}
                    <li className="breadcrumb-item active" aria-current="page">{this.state.title}</li>
                  </ol>
                </nav>
                <Row className="">
                    <Col  className="detail_img_section col-md-5 col-lg-4 col-12">
                        <div className="mainImageDiv">

                                {(this.state.mainImages.includes('.jpeg') ||this.state.mainImages.includes('.png') ||this.state.mainImages.includes('.jpg') || this.state.mainImages.includes('.gif')) &&
                                    <div className="imageAlign">
                                        <div className="lapdesignmainimage">
                                            <ImageMagnifier mainimage={this.state.mainImages} hasUnlimitedStock={this.state.hasUnlimitedStock} stock={this.state.stock}  />
                                        </div>
                                        <div className="mobiledesignmainimage">
                                            <Image src={this.state.mainImages} className="mobileImageWidthSetting" style={{opacity:(this.state.hasUnlimitedStock === false && this.state.stock === 0) ? '0.3' : '' }}/>
                                        </div>

                                    </div>
                                }
                                {(this.state.mainImages.includes('.mp4') || this.state.mainImages.includes('.webm') || this.state.mainImages.includes('.ogv') ||this.state.mainImages.includes('.wmv')) &&
                                    <div className="videoAlign">
                                        <video  controls className="vedioDivForMobile">
                                            <source src={this.state.mainImages} />
                                        </video>
                                    </div>
                                }

                        </div>
                        <Row className="subimagesDiv" style={{opacity:(this.state.hasUnlimitedStock === false && this.state.stock === 0) ? '0.3' : '' }}>
                            {this.state.subImages.map((item) => (
                                ((item)===this.state.mainImages)?

                                <div className="imagesFixedSize borderhighlight">
                                    {(item.includes('.jpeg') || item.includes('.png') || item.includes('.jpg') || item.includes('.gif')) &&
                                        <Image src={item}   thumbnail onClick={()=>this.handleImageChanges(item)} />
                                    }
                                    {(item.includes('.mp4') || item.includes('.webm') || item.includes('.ogg') ||item.includes('.wmv')) &&

                                            <video className="vedioDiv" onClick={()=>this.handleVedioChanges(item)} >
                                                <source src={item} />
                                            </video>

                                    }
                                </div>
                                :<div className="imagesFixedSize">
                                    {(item.includes('.jpeg') || item.includes('.png') || item.includes('.jpg') || item.includes('.gif')) &&
                                        <Image src={item}   thumbnail onClick={()=>this.handleImageChanges(item)} />
                                    }
                                    {(item.includes('.mp4') || item.includes('.webm') || item.includes('.ogg') ||item.includes('.wmv')) &&

                                            <video className="vedioDiv" onClick={()=>this.handleVedioChanges(item)} >
                                                <source src={item} />
                                            </video>

                                    }
                                </div>
                            ))}
                        </Row>
                    </Col>
                    <Col xs="12" md="6" offset="1">

                        <div id="myPortal" className="fixedHoverPosition"/>
                            <div>
                                <h2 className="mt-3 pt-1">{this.state.title}</h2>
                                {this.state.price!==this.state.discountprice &&
                                    <p className="my-3">
                                        <strike className="lightcolor fontweight">{this.state.price.slice(-1)}{this.state.price.slice(0,-1)}</strike>
                                         &nbsp;&nbsp;
                                        <b className="textcolor fontweight">{this.state.discountprice.slice(-1)}{this.state.discountprice.slice(0,-1)}</b>
                                    </p>
                                }
                                {this.state.price===this.state.discountprice && this.state.discountprice !== null &&
                                    <p className="my-2">
                                        <b className="fontweight">{this.state.discountprice.slice(-1)}{this.state.discountprice.slice(0,-1)}</b>
                                    </p>
                                }

                                {this.state.response!=='null' && this.state.response.length!==0 &&
                                    <div id="colordiv">
                                        <h6 className="my-2">{t('Color')}</h6>
                                        <div className="colorsDisplayStyle" >
                                            {this.handleColor()}
                                        </div>
                                    </div>
                                }
                                <p></p>

                                {this.state.selectedSize!==null && this.state.selectedSize!=='null' && this.state.selectedSize!=="" && this.state.selectedSize!=='undefined'   &&
                                    <div id="sizeDiv">
                                        <h6 className="my-2">{t('Size')}</h6>
                                        {this.handleSize()}
                                    </div>
                                }
                                <p></p>
                                {((this.state.response.length!==0 && this.state.stock===0 && this.state.hasUnlimitedStock===false) || (this.state.is_expired!==null && this.state.is_expired!=="" && this.state.response.length!==0)) &&
                                    <div>
                                    <p className="stockFontsize my-2 colorofoutofstock">{t('OUT OF STOCK')}</p>
                                    </div>
                                }

                                {(((this.state.is_expired===null || this.state.is_expired==="") && this.state.stock!==0 && this.state.stock!==null && this.state.stock!=='undefined') || (this.state.hasUnlimitedStock===true)) &&
                                    <div id="quantity">
                                        <h6 className="mt-4 mb-2">{t('Quantity')}
                                             {this.state.stock !=="" && this.state.stock!==0 && this.state.stock!==null && this.state.stock!=='undefined' &&
                                             <span className="stockFontsize my-2 pl-3">{this.state.stock} {t('IN STOCK')}</span>
                                }
                                        </h6>
                                        <div className="colorsDisplayStyle">
                                            <div className="quantity-circle">
                                                <button className="sizesFontStyle quantity-circle" title = {t('Remove quantity')} onClick={this.handleMinQuantity}><span className="minus">--</span></button>
                                            </div>
                                            <div>
                                                <p className="number-quantity"><span className="quantityNumbermargintop">{this.state.quantity}</span></p>
                                            </div>
                                            <div className="marginAlign">
                                                <button className="sizesFontStyle quantity-circle" title = {t('Add quantity')}  onClick={this.handleMaxQuantity}><span className="plus"> +</span></button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(((this.state.is_expired===null || this.state.is_expired==="") && this.state.stock!==0 && this.state.stock!==null && this.state.stock!=='undefined') || (this.state.hasUnlimitedStock===true)) &&
                                    <div className="buttonDiv my-4 py-2">
                                        <button className="addcartButton actionbutton" onClick={()=>this.addToCart("addtocart")}>
                                            {t('Add to cart')}
                                        </button>
                                        <button className="buyNowButton actionbutton bg-color text-white" onClick={()=>this.addToCart("buynow")}>
                                            {t('Buy now')}
                                        </button>
                                    </div>
                               }
                            <p></p>
                            {this.state.description!=="" && this.state.description!==null && this.state.description!=='undefined' &&
                                <div className="col-md-12 col-lg-12 col-12 px-0 mb-5">
                                    <h6 className="mb-2 mt-3">{t('Description')}</h6>
                                    <p dangerouslySetInnerHTML={{__html: this.state.description}} />
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <div className="col-md-12 col-lg-12 col-12 px-0" style = {{display:this.state.noData ? 'block':'none'}}>
                    <h5 className = "textcolor text-center mt-3 no-pro">{t('No products found')}</h5>
                </div>
            </div>
            </div>
        )
    }
}
export default ProductOverview;
