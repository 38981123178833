import React, { useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import { Apiservice } from '../../services/Apiservices';
import { Link } from "react-router-dom";
import './pickuporder.css';
import { OCAlert } from '@opuscapita/react-alerts';
import { useHistory } from 'react-router-dom'
import { t } from '../../translations/Translate';

function EditOrders() {
    const [ordersid, setordersid] = useState();
    const [ApiData, setApiData] = useState();
    const [Racknumber, setRacknumber] = useState();
    const history = useHistory();
    let tab = useLocation();
    
    useEffect(() =>{
        setordersid(tab.state.orderid);
        let userDetails = JSON.parse(localStorage.getItem("user"))
            let data1 ={
              user_id :userDetails['id'],
              order_id:tab.state.ordersid,
              locationId:tab.state.locID,
            } 
          Apiservice.service(window.PICKUPORDERDETAILS, "POST",data1).then(res => {
              if (res.status === 201) {
                setApiData(res.data.DATA);
                Object.keys(res.data.DATA).map(e=>{
                    setRacknumber(res.data.DATA[e].rack_no)
                })

              }
              
          }).catch(e=>{
            if(document.getElementById('loading-icon')!== null){
              document.getElementById('loading-icon').style.display = 'none';
            }
          })

    },[])
    
    // const validateRacknumber = (value) => {
    //     var match = /^[a-zA-Z0-9-]+$/;
    //     var final = (value.match(match)) ? true:false;
    //     return final;
    // }
    const SaveUpdatedOrder = e => {
        e.preventDefault();
            var currentdate = new Date();
            var start = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate(), currentdate.getHours(),currentdate.getMinutes()).toISOString();
            var scanTime = start.replace(start.split('.').pop(), '00Z');

            let userDetails = JSON.parse(localStorage.getItem("user"))
            let updated_data ={
                user_id :userDetails['id'],
                order_id:tab.state.ordersid,
                rack_number:Racknumber,
                "type":"update",
                scan_time:scanTime
            } 
            Apiservice.service(window.SCANNEDORDER, "POST",updated_data).then(res => {
                if (res.status === 201) {
                    OCAlert.alertSuccess('Order updated successfully');
                    history.push('/pickup');
                }
                    
            }).catch( e => {
                if(document.getElementById('loading-icon')!== null){
                    document.getElementById('loading-icon').style.display = 'none';
                }
            })
        
    }
      
    
    function historyBack() {
        history.push('/pickup');
    }  
   
    return (
        
        
        <div className="container customContainer pro_class1">
            <div className="row col-md-12 col-lg-12 col-12 m-0 p-0">
            <nav aria-label="breadcrumb " className="col-md-12 col-lg-12 col-12 px-0  pt-1">
                  <ol className="breadcrumb bg-transparent px-0">
                  <div className="row col-md-12 col-lg-12 col-12 pt-2 p-0">
                    <div className="row col-md-8 col-lg-8 col-8">
                        <div className="col-md-12 col-lg-12 col-12 row p-0">
                            <p class="backButton edit_back_btn" onClick={history.goBack}> </p>
                            <span> <li className="breadcrumb-item active text-align-right site-history" aria-current="page">{t('Pickup locations / Edit order')}</li></span>
                        </div>
                        
                    </div>
                    <div className="col-md-4 col-lg-4 col-4 ml-4 px-0 overviewspace text-align-right">
                    <Link to={{pathname: '/'}} className="go-to-site">Go back to site</Link>
                    </div>
                   </div> 
                  </ol>
            </nav>
            </div>
        <div className=" col-md-10 topsection-wrap m-auto mt-2  pt-2">
       
        
        <div className="col-md-12 col-lg-12 col-12 px-0">
          <div className="row m-auto">
            <div className="col-md-6 col-lg-6 col-6 px-0 overviewspace">
              <h6 className="mb-3 mt-10 page-title">{'EDIT ORDER'}</h6>
            </div>
            
          </div>
        </div>
        <hr className="w-100 mt-2"></hr>

                {ApiData === undefined  ? null : 
                <div>
                {ApiData && Object.keys(ApiData).map(e => (
                    <form className="fieldsWrapp"  id="form" onSubmit={(e) => SaveUpdatedOrder(e)}>
                        <div className="form-group">
                            <div className="col-md-8 col-lg-8 col-12 m-auto px-5 bg-white order-details-box1">
                            <div className="col-md-12 col-lg-12 col-12 mb-1 p-1">
                                {ApiData[e].order_id &&
                                <div className="row m-auto">
                                    <div className="col-md-4 col-5 col-lg-4">
                                        <label>{t('Order number')}</label>
                                    </div>
                                    <div className="col-md-2 col-1 col-lg-2">
                                        {":"}
                                    </div>
                                    <div className="col-md-6 col-12 col-lg-6">
                                        <input
                                        type="text"
                                        name="orderid"
                                        className="form-control edit-field"
                                        value={ApiData[e].order_id}
                                        readOnly
                                        id="orderid"
                                        required
                                    />
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="col-md-12 col-lg-12 col-12 mb-1 p-1" >
                                {ApiData[e].user && 
                                <div className="row m-auto">
                                    <div className="col-md-4 col-5 col-lg-4">
                                        <label>{t('Client')}</label>
                                    </div>
                                    <div className="col-md-2 col-1 col-lg-2">
                                        {":"}
                                    </div>
                                    <div className="col-md-6 col-12 col-lg-6">
                                        <input
                                        type="text"
                                        name="username"
                                        className="form-control edit-field"
                                        value={ApiData[e].user.firstName+" "+ApiData[e].user.lastName}
                                        readOnly
                                        id="username"
                                        required
                                    />
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="col-md-12 col-lg-12 col-12 px-0 bg-white mb-1 p-1" >
                                {ApiData[e].in_res_user &&
                                <div className="row m-auto">
                                    <div className="col-md-4 col-5 col-lg-4">
                                        <label>{t('PL manager')}</label>
                                    </div>
                                    <div className="col-md-2 col-1 col-lg-2">
                                        {":"}
                                    </div>
                                    <div className="col-md-6 col-12 col-lg-6">
                                        <input
                                        type="text"
                                        name="plmanager"
                                        className="form-control edit-field"
                                        value={ApiData[e].in_res_user.firstName+ " " + ApiData[e].in_res_user.lastName}
                                        id="plmanager"
                                        readOnly
                                    />
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="col-md-12 col-lg-12 col-12 px-0 bg-white mb-1 p-1" >
                                <div className="row m-auto">
                                    <div className="col-md-4 col-5 col-lg-4">
                                        <label>{t('Rack number')}</label>
                                    </div>
                                    <div className="col-md-2 col-1 col-lg-2">
                                        {":"}
                                    </div>
                                    <div className="col-md-6 col-12 col-lg-6 ">
                                        <input
                                        type="text"
                                        name="racknum"
                                        className="form-control edit-field"
                                        value={Racknumber}
                                        autoFocus
                                        id="racknum"
                                        required
                                        onChange = {(e) => setRacknumber(e.target.value) }
                                    />
                                    </div>
                                </div>
                            </div>
                           
                            
                            <div className="col-md-12 col-12  edit-save-btn mt-3 mb-4 mr-1" style={{margin: "0px 0px 0px -15px"}}>
                                <button type="submit"  className="save-btn">{t('Save')}</button>
                            </div>
                            </div>
                        </div>
                    </form>
                    
                    ))}
                    </div>}
              
        </div>
       
        </div>

    );
}
export default EditOrders;