import React, { useState, useEffect } from 'react';
import { Apiservice } from '../../services/Apiservices';
import { Link, useHistory } from "react-router-dom";
import { t } from '../../translations/Translate';
import './myProfile.css';
import * as Reactbootstrap from 'react-bootstrap'
import moment from 'moment';
import { useParams } from "react-router";
import { OCAlert } from '@opuscapita/react-alerts';


function OrderDetails() {
  const [ApiData, setApiData] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const handlePopupClose = () => setShowDeletePopup(false);
  const [unauthorised, setUnauthorised] = useState(false);

  const history = useHistory();
  const [get_id] = useState(JSON.parse(localStorage.getItem("user")))
  let params = useParams();
  useEffect(() => {
    const request = {
      "user_id": get_id.id,
      "orderId": params.id,
      "type": "myOrders"
    }
    Apiservice.service(window.MYORDERS, "POST", request).then(res => {
      try {
        if (res.status === 201) {
          setApiData(res.data.DATA);
        }
        else if (res.status === 200) {
          if (res.data.DATA.STATUS === 404) {
            setUnauthorised(true)
            OCAlert.alertError(res.data.DATA.MESSAGE, { timeOut: window.ALERT_ERROR_TIME });
          }
        }
      } catch (e) {
      }
    })
  }, []);
  let img, size, color, qty = 0, n = 0, date, date1;

  var now = new Date(); //todays date
  var end = moment("2020-11-18"); // another date
  var duration = moment.duration(end.diff(now));
  var days = duration.asDays();



  const CancelOrder = (id) => {
    setShowDeletePopup(true)
  }

  const handleConfirmCancel = () => {
    setShowDeletePopup(false)
    const request = {
      "user_id": get_id.id,
      "type": "cancelOrder",
      "orderId": ApiData.orderDetails.id
    }
    Apiservice.service(window.PROCEED_TO_CHECKOUT, "POST", request).then(res => {
      try {
        if (res.status === 200) {
          OCAlert.alertSuccess(t('Order has been cancelled.'), { timeOut: window.ALERT_ERROR_TIME });
          setTimeout(function () { history.push('/myprofile/myorders') }, 1000);
        }
        else if (res.status === 409) {
          OCAlert.alertError(res.data.DATA.message, { timeOut: window.ALERT_SUCCESS_TIME });
        }
      } catch (e) {
        console.error(e);
        OCAlert.alertError(res.data.DATA.message, { timeOut: window.ALERT_SUCCESS_TIME });
      }
    })
  }

  const Invoice = (id) => {
    const request = {
      "orderId": id
    }
    Apiservice.service(window.INVOICE, "POST", request).then(res => {

      try {
        if (res) {
          window.open(res.data)
        }
      } catch (e) {
        console.error(e);
      }
    })
  }

  const getVatPercentage = (e) => {
    let vatPercentage = ''
    ApiData.orderDetails.lineItems[0].adjustments.filter(ele => {
      if (ele.type === 'tax') {
        vatPercentage = (ele.description)
      }
    })
    return vatPercentage
  }

  const Trackstatus = ['INTRANSIT', 'READYTOPICKUP', 'SHIPPED', 'ARRIVED', 'IN-TRANSIT']

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  let totalDiscountAsCurrency, currency;
  if (ApiData)
    currency = ApiData.orderDetails.totalAsCurrency[ApiData.orderDetails.totalAsCurrency.length - 1];
  ApiData && Object.keys(ApiData.orderDetails.lineItems).map(w => {
    qty = qty + ApiData.orderDetails.lineItems[w].qty
  })
  return (
    <section className="col-md-12 col-lg-12 col-12 px-0">
      {unauthorised && <h5 className="text-center mt-3 textcolor">{t('Unauthorized to access order details')}</h5>}
      {!unauthorised && <h6 className="mb-0 page-title">{t('ORDER DETAILS')}</h6>}
      {!unauthorised && <hr className="w-100 mt-3"></hr>}
      {!unauthorised && ApiData &&
        <div>
          <div>
            <p className="float-left order-info ">{t('Ordered on')} <span className="orderNumber">{ApiData.orderDetails.dateOrdered.date}</span> <span className="seperation">|</span> {t('Order')}# <span className="orderNumber">{ApiData.orderDetails.id}</span></p>
            {date1 = moment(((ApiData.orderDetails.shippingDate)), 'YYYY-MM-DDT').format('YYYY-MM-DD'), date = moment.duration(moment(now).diff(date1)), ApiData.paymentMethod !== null && <p className="float-right order-info mobilewrap">{t('Payment method')}: {capitalizeFirstLetter(ApiData.paymentMethod)}</p>}

          </div>
          <div className="col-md-12 col-lg-12 col-12 px-0 w-100 float-left bg-white p-3 mb-5">
            <div className="row m-auto">
              <div className="col-md-4 col-lg-4 col-12 pl-0 pr-4 pt-2 pb-2">
                <div><h6 className="mb-2">{(date.asDays()) + 2 >= 4 ? t('Delivered to') : (ApiData.shipingAddress.attention === 'pickupLocation') ? t('Pickup location') : t('Shipped to')}</h6></div>
                {ApiData.shipingAddress !== null && <div className="order-info"><p className="mb-0">{ApiData.shipingAddress.title}</p><p>{(ApiData.shipingAddress.attention !== 'pickupLocation') && <span>{ApiData.shipingAddress.firstName + " " + ApiData.shipingAddress.lastName}<br></br></span>}{(ApiData.shipingAddress.attention === 'pickupLocation') ? <span><span>{ApiData.shipingAddress.address1}</span><br></br><span>{ApiData.shipingAddress.address2}</span></span> : <span>{ApiData.shipingAddress.custom1} {ApiData.shipingAddress.custom2} {(ApiData.shipingAddress.custom3 !== '' || null) && (t('Box') + ': ' + ApiData.shipingAddress.custom3)}</span>}<br></br><span>{ApiData.shipingAddress.zipCode} {(ApiData.shipingAddress.attention !== 'pickupLocation') && ApiData.shipingAddress.city}</span><br></br><span>{(ApiData.shipingAddress.attention === 'pickupLocation' ? ApiData.shipingAddress.label : ApiData.shipingAddress.countryText)}</span></p></div>}
              </div>
              <div className="col-md-5 col-lg-5 col-12 px-0  pr-5 pt-2">
                <div><h6 className="mb-2">{t('Billing to')}</h6></div>
                {ApiData.billingAddress !== null && <div className="order-info"><p className="mb-0">{ApiData.billingAddress.title}</p><p>{ApiData.billingAddress.firstName + " " + ApiData.billingAddress.lastName}<br></br><span>{ApiData.billingAddress.custom1} {ApiData.billingAddress.custom2} {(ApiData.billingAddress.custom3 !== '' || null) && (t('Box') + ': ' + ApiData.billingAddress.custom3)}</span><br></br><span>{ApiData.billingAddress.zipCode} {ApiData.billingAddress.city}</span><br></br><span>{ApiData.billingAddress.countryText}</span></p></div>}
              </div>
              <div className="col-md-3 col-lg-3 col-12 pl-0 pr-0 pt-2 pb-2">
                <div><h6 className="mb-2">{t('Order summary')}</h6></div>
                <div className="order-info">
                  {<p>{t('Price')} ({qty} {qty > 1 ? t('items') : t('item')})<span className="float-right numbersize">{currency + "" + (ApiData.orderDetails.itemSubtotalAsCurrency).toString().replace(currency, "")}</span></p>}
                  {ApiData.orderDetails.totalTax > 0 && ApiData.orderDetails.lineItems[0].adjustments[0] !== undefined && <p>{t('VAT')} ({getVatPercentage()})<span className="float-right numbersize">{currency + "" + (ApiData.orderDetails.totalTaxAsCurrency).toString().replace(currency, "")}</span></p>}
                  {totalDiscountAsCurrency = (ApiData.orderDetails.totalDiscountAsCurrency).toString().replace("-", ""), <p className={ApiData.orderDetails.totalDiscount === 0 ? "hide-class textcolor" : "textcolor"}>{t('Promo')}<span className="float-right numbersize">{"-" + currency + "" + totalDiscountAsCurrency.toString().replace(currency, "")}</span></p>}
                  <p>{t('Shipping charge')}<span className="float-right numbersize">{currency + "" + (ApiData.orderDetails.totalShippingCostAsCurrency).toString().replace(currency, "")}</span></p>
                  <p className="font-weight-bold">{t('Total')}<span className="float-right numbersize">{currency + "" + ApiData.orderDetails.totalPriceAsCurrency.toString().replace(currency, "")}</span></p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-3 w-100" />
            <div className="col-md-12 col-lg-12 col-12 px-0 w-100 float-left">
              {n = 1, <h6 className={(date.asDays()) + 2 >= 4 ? "mb-2" : "hide-class"}> {t('Delivered')} </h6>}
            </div>
            <div className="col-md-12 col-lg-12 col-12 px-0 w-100 float-left">
              {n = 1, <h6 className={(date.asDays()) + 2 <= 4 ? "mb-2" : "hide-class"}>{t('Shipped')} {ApiData.orderDetails.shippingDate !== null && moment((ApiData.orderDetails.shippingDate), 'YYYY-MM-DDT').format('DD-MM-YYYY')}</h6>}
            </div>
            <div className="col-md-12 col-lg-12 col-12 px-0">
              <div className="row m-auto w-100">
                <div className="col-md-9 col-lg-9 pl-0 mob-order-space">
                  {Object.keys(ApiData.orderDetails.lineItems).map(k => (
                    <div className="col-12 col-md-12 col-lg-12 px-0 pb-1 float-left mb-2" key={k}>
                      {Object.keys(ApiData.variantDetails).map(l => {
                        if (ApiData.orderDetails.lineItems[k].id === ApiData.variantDetails[l].id) {
                          img = ApiData.variantDetails[l].image;
                          size = ApiData.variantDetails[l].size;
                          color = ApiData.variantDetails[l].color;
                        }
                      }),
                        <div className="pro_imgs col-md-2 col-lg-2 col-12 overviewspace">
                          <img src={img} alt="Product Image" className="" />
                        </div>}
                      <div className="col-md-7 col-lg-7 col-12 d-inline-block align-middle  overviewspace mob-order-space alignmentofdesc">
                        <h5 className="mb-0"> <Link to="#" className="text-dark d-inline-block align-middle">{ApiData.orderDetails.lineItems[k].description}</Link></h5>
                        {color !== '' && color !== null && <span className="text-muted font-weight-normal d-block">{t('Color')}: {color}</span>}
                        {size !== '' && size !== null && <span className="text-muted font-weight-normal d-block">{t('Size')}: {size}</span>}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-md-3 pr-0 mob-order-space">
                  {params.cancelledorder === undefined && (ApiData.orderDetails.deliveredDate === null || ApiData.orderDetails.deliveredDate === "") ?
                    <div className={n === 1 ? "col-md-12 px-0 col-lg-12 col-12 d-inline-block text-center overviewspace" : "hide-class"}>
                      <div>
                        <a target="_blank" href={ApiData.orderStatuses.trackingUrl} className={ApiData.orderStatuses.trackingUrl !== null && Trackstatus.includes(ApiData.orderDetails.shippingStatus.toUpperCase()) ? "btn w-100 btn-quote btn-danger text-center mb-2" : "hide-class"}>{t('TRACK ORDER')}</a>
                      </div>

                      {n = 0, <Link to="#" className={ApiData.orderStatuses.shippingStatus !== null ? "hide-class" : "text-center cancel-order"} onClick={() => CancelOrder(ApiData.orderDetails.id)} >{t('ORDER CANCEL')}</Link>}
                      <div>
                        <button className="btn w-100 btn-quote btn-danger text-center" onClick={() => Invoice(ApiData.orderDetails.id)}>{t('INVOICE')}</button>
                      </div>
                    </div> :
                    ((date.asDays()) + 2) <= 14 && ((date.asDays()) + 2) >= 4 ?
                      <div className={n === 1 ? "col-md-12 px-0 col-lg-12 col-12 d-inline-block text-center overviewspace" : "hide-class"}>
                        <div>
                          <button className="btn w-100 btn-quote buttonstyle text-center" onClick={() => Invoice(ApiData.orderDetails.id)}>{t('INVOICE')}</button>
                        </div>
                        {n = 0, <a href={ApiData.orderStatuses.returnTrackingUrl !== null ? ApiData.orderStatuses.returnTrackingUrl : '#'} target="_blank" className="text-center return">{t('RETURN')}</a>}
                      </div> :
                      params.cancelledorder === undefined && <div className={n === 1 ? "col-md-12 px-0 col-lg-12 col-12 d-inline-block text-center overviewspace" : "hide-class"}>
                        <div>
                          {n = 0, <button className="btn w-100 btn-quote buttonstyle text-center" onClick={() => Invoice(ApiData.orderDetails.id)}>{t('INVOICE')}</button>}
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <Reactbootstrap.Modal
        size="sm"
        show={showDeletePopup}
        dialogClassName="search-popup"
        onHide={handlePopupClose}
        backdrop="static"
        aria-labelledby="example-modal-sizes-title-sm"
        keyboard={true}
      >
        <div class="filterhead">
          <h3 >{t('Please confirm')}</h3>
        </div>
        <Reactbootstrap.Modal.Header closeButton className="filter-close">
        </Reactbootstrap.Modal.Header>
        <Reactbootstrap.Modal.Body className="popup-body">
          <div className="form-group mt-3">
            <h5>{t('Are you sure you want to cancel this order?')}</h5>
          </div>
        </Reactbootstrap.Modal.Body>
        <Reactbootstrap.Modal.Footer>
          <button className="btn login-btn col-3 col-md-3 col-lg-2 float-right" onClick={handlePopupClose}>{t('No')}</button>
          <button className="btn login-btn col-3 col-md-3 col-lg-2 float-right" onClick={() => handleConfirmCancel()}>{t('Yes')}</button>
        </Reactbootstrap.Modal.Footer>
      </Reactbootstrap.Modal>
    </section>
  );
}
export default OrderDetails;
