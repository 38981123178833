import React,{useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import {Apiservice} from 'services/Apiservices'
import { t } from '../../translations/Translate'
import './Login.css';
import { OCAlert } from '@opuscapita/react-alerts';
import LoginNav from './LoginNav.js';

const SsoLoginForm = () => {
    useEffect(() => {

     if(JSON.parse(localStorage.getItem("isLogged"))){
       window.location.href = '/';
     }
        //  document.getElementById("img-fluid").style.display="block";
    }, []);
    const history = useHistory();
    const isLogged = JSON.parse(localStorage.getItem("isLogged"));
    const stateId = (isLogged ? 1 : 0);
    return (

        <div className="login-form1" >
        {!isLogged &&
            <div className="text-center" style={{marginTop:"70%"}}>
                <div className="mt-5" style={{height:"100px", width:"100%"}}></div>
                <h3 class="Email-form-class mt-5" > {t('Login to Benjaminn')} </h3>
                <div className="col-md-12 text-center  mb-3 p-0">
                <a href={process.env.REACT_APP_API_URL+"sso?rid=2&state="+stateId}>
                    <div  className="btn login-btn col-12 text-center block mb-2">{t('Login with work account')}</div>
                </a>
                    {/* <div className="col-12 text-center block mt-4">
                        <span className="btn">{t('Login with email')}</span>
                    </div>

                    <div className="btn col-12 text-center block">
                        <span className="btn">{t('Login with phone number')}</span>
                    </div> */}
                </div>
            </div>
        }       
        </div>
    );
}
export default SsoLoginForm;
