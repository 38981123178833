import React, { useState, useEffect} from 'react';
import { Apiservice } from '../../services/Apiservices';
import { Link } from "react-router-dom";
import './pickuporder.css';
import { useHistory } from 'react-router';
import { OCAlert } from '@opuscapita/react-alerts';
import { t } from '../../translations/Translate';

function ScanOrder() {

    const [inputs, setInputs] = useState({ordernumber:""});
    const [orderid, setorderid] = useState("");
    const [ApiData, setApiData] = useState();
    const [type, settype] = useState();
    const [scannedRackNum, setscannedRackNum] = useState();
    const [rackNum, setRackNum] = useState();
    const [timer, setTimer] = useState(false);
    const [timerFororder, setTimerFororder] = useState(false);
    const [confirmOrderTimer, setConfirmOrderTimer] = useState(false);
    const [confirmorderid, setconfirmorderid] = useState("");
    const history = useHistory();

    /**
     * fetches the order details
     */
    useEffect(()=>{
        
        let userDetails = JSON.parse(localStorage.getItem("user"));
        let currentTab = localStorage.getItem("currentTab");
        let location = JSON.parse(localStorage.getItem("pickupLocation"))
        let data1 ={
              user_id :userDetails['id'],
              order_id:orderid,
              tab : currentTab,
              locationId: location.value,
            } 
        if(timerFororder === true){
            //api to get order details by scanned order id 
            Apiservice.service(window.PICKUPORDERDETAILS, "POST", data1).then(res => {
                if (res.status === 201) {
                    Object.keys(res.data.DATA).map(e=>{
                        setscannedRackNum(res.data.DATA[e].rack_no)
                        settype(currentTab)
                    })
                    setApiData(res.data.DATA);
                }
                if(res.status === 200){
                    OCAlert.closeAlerts();
                    OCAlert.alertError(res.data.message, {timeOut: window.ALERT_TIME_3000});
                }
            }).catch(e => {
                OCAlert.closeAlerts();
                OCAlert.alertError(e.response.data.message, {timeOut: window.ALERT_TIME_3000});
                if(document.getElementById('loading-icon')!== null){
                    document.getElementById('loading-icon').style.display = 'none';
                }
            })
        }
       
      },[orderid, timerFororder]);

      /**
       * 
       * @param {*} e hadles the scanned order details
       */
    const handleInputChange = e => {
        setInputs({
            ...inputs, [e.target.name]: e.target.value
        });
        setorderid(e.target.value);
        setTimerFororder(false);
        const timeoutId1 = setTimeout(() => setTimerFororder(true), 3000);
        return () => clearTimeout(timeoutId1);
    };


    useEffect(()=> {
        const delayDebounceFn = setTimeout(() => {
            if(orderid !== 'undefined' && orderid !== "" && orderid !== null && confirmorderid !== null && confirmorderid !== 'undefined' && confirmorderid !== ""){
                if(orderid == confirmorderid){
                    setTimer(true);
                    setConfirmOrderTimer(true);
                }
                else{
                    OCAlert.closeAlerts();
                    OCAlert.alertError('order not matched', {timeOut: window.ALERT_TIME_3000});
                    window.location.reload();
                }
            }
          }, 3000)
          return () => clearTimeout(delayDebounceFn)
        
    },[confirmorderid])

    /**
     * 
     * @param {*} e 
     * handles the submission of scanned order
     */
 
    const saveOrderDetails = e => {
        e.preventDefault();
        setTimer(true);
    }

    function historyBack() {
        history.push('/pickup');
    }

    const AutoSave = e => {
        e.preventDefault();
        setRackNum(e.target.value);
        if(type === 'inbound'){
            setTimer(false);
            const timeoutId2 = setTimeout(() => setTimer(true), 3000);
            return () => clearTimeout(timeoutId2);
        }
    }

    useEffect(() => {
     
        if (timer === true || confirmOrderTimer === true)
        {
            var currentdate = new Date();
            var start = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate(), currentdate.getHours(),currentdate.getMinutes()).toISOString();
            var scanTime = start.replace(start.split('.').pop(), '00Z');
            let userDetails = JSON.parse(localStorage.getItem("user"));
            let scanned_data = 
                {
                    user_id :userDetails['id'],
                    order_id:orderid,
                    tab:localStorage.getItem("currentTab"),
                    rack_number: (type === 'inbound') ? rackNum : scannedRackNum,
                    scan_time:scanTime   
                }
            Apiservice.service(window.SCANNEDORDER, "POST", scanned_data).then(res => {
                if (res.status === 201) {
                    OCAlert.closeAlerts();
                    OCAlert.alertSuccess(res.data.message, {timeOut: window.ALERT_TIME_3000});
                    history.push('/pickup');
                }
                else if(res.status === 200){
                    OCAlert.closeAlerts();
                    OCAlert.alertError(res.data.message, {timeOut: window.ALERT_TIME_3000});
                    history.push('/pickup');
                }
            }).catch( e => { //NOSONAR
                OCAlert.closeAlerts();
                OCAlert.alertError(e.response.data.message, {timeOut: window.ALERT_TIME_3000});
                if(document.getElementById('loading-icon')!== null){
                   document.getElementById('loading-icon').style.display = 'none';
                }
            })
        }
    },[rackNum, timer, confirmOrderTimer]);
       
    return (
        
        <div className="container customContainer pro_class1">
        <div className="row col-md-12 col-lg-12 col-12 m-0 p-0">
            <nav aria-label="breadcrumb " className="col-md-12 col-lg-12 col-12 px-0  pt-1">
                  <ol className="breadcrumb bg-transparent px-0">
                  <div className="row col-md-12 col-lg-12 col-12 pt-2 p-0">
                    <div className="row col-md-8 col-lg-8 col-8">
                        <div className="col-md-12 col-lg-12 col-12 row p-0">
                            <p class="backButton edit_back_btn" onClick={history.goBack}> </p>
                            <span> <li className="breadcrumb-item active text-align-right site-history" aria-current="page">{'Pickup locations / Scan order'}</li></span>
                        </div>
                        
                    </div>
                    <div className="col-md-4 col-lg-4 col-4 ml-4 px-0 overviewspace text-align-right">
                        <Link to={{pathname: '/'}} className="go-to-site">{t("Go back to site")}</Link>
                    </div>
                   </div> 
                  </ol>
            </nav>
            </div>
        <div className="col-md-10 topsection-wrap  m-auto mt-4 pt-4">
                  <div className="col-md-12 col-lg-12 col-12 px-0">
                    <div className="row m-auto">
                      <div className="col-md-6 col-lg-6 col-6 px-0 overviewspace">
                        <h6 className="mb-3 mt-10 page-title">{t('SCAN ORDER')}</h6>
                      </div>
                      
                    </div>
                  </div>
        <hr className="w-100 mt-2"></hr>
        <div className="col-md-6 col-lg-6 col-12 px-0 bg-white order-details-box1">
            <div className="col-md-12 col-lg-12 col-12 mb-1 p-1">
                <div className="row m-auto">
                    <div className="col-md-4 col-lg-4 col-5">
                        <label className="Scan-text">Scan order</label>
                    </div>
                    <div className="col-md-2 col-lg-2 col-1">
                        {":"}
                    </div>
                    <div className="col-md-6 col-lg-6 col-12">
                        <input type="text" name="ordernumber" className="form-control scan-field" placeholder="Scan here.." onChange={handleInputChange} value={inputs.ordernumber} autoFocus/>
                    </div>
                </div>
            </div>
                {ApiData === undefined  ? null : 
                <div>
                {ApiData && Object.keys(ApiData).map(e => (
                        <div className="form-group">
                            <div className="col-md-12 col-lg-12 col-12 mb-1 p-1" >
                            {ApiData[e].user &&
                                <div className="row m-auto">
                                    <div className="col-md-4 col-lg-4 col-5">
                                        <label>{'Client'}</label>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-1">
                                        {":"}
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-12">
                                        <input
                                        type="text"
                                        name="username"
                                        className="form-control scan-borderless"
                                        value={ApiData[e].user.firstName+" "+ApiData[e].user.lastName}
                                        readOnly
                                        id="username"
                                        required
                                    />
                                    </div>
                                </div>
                            }
                            </div>
                            <div className="col-md-12 col-lg-12 col-12 mb-1 p-1">
                            {ApiData[e].order_id &&
                                <div className="row m-auto">
                                    <div className="col-md-4 col-lg-4 col-5">
                                        <label>{'Order number'}</label>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-1">
                                        {":"}
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-12">
                                        <input
                                        type="text"
                                        name="orderid"
                                        className="form-control scan-borderless"
                                        value={ApiData[e].order_id}
                                        readOnly
                                        id="orderid"
                                        required
                                    />
                                    </div>
                                </div>
                                }
                            </div>
                            
                            {type === 'inbound' ? 
                            
                            <div className="col-md-12 col-lg-12 col-12 mb-1 p-1" >
                                <div className="row m-auto">
                                    <div className="col-md-4 col-lg-4 col-5">
                                        <label>{'Rack number'}</label>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-1">
                                        {":"}
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-12">
                                        <input
                                        type="text"
                                        name="racknum"
                                        className="form-control scan-field"
                                        placeholder="scan here"
                                        id="racknum"
                                        autoFocus
                                        required
                                        onChange={(e) => AutoSave(e)} //NOSONAR
                                    />
                                    </div>
                                </div>
                            </div>
                            
                            :
                            <div>
                                <div className="col-md-12 col-lg-12 col-12 mb-1 p-1" >
                                    {
                                        ApiData[e].in_res_user &&
                                        <div className="row m-auto">
                                            <div className="col-md-4 col-lg-4 col-5">
                                                <label>{'PL Manager'}</label>
                                            </div>
                                            <div className="col-md-2 col-lg-2 col-1">
                                                {":"}
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-12">
                                                <input
                                                type="text"
                                                name="pl-manager"
                                                className="form-control scan-borderless"
                                                id="pl-manager"
                                                readOnly
                                                value={ApiData[e].in_res_user.firstName + " " + ApiData[e].in_res_user.lastName}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>

                            <div className="col-md-12 col-lg-12 col-12 mb-1 p-1" >
                                <div className="row m-auto">
                                    <div className="col-md-4 col-lg-4 col-5">
                                        <label>{'Rack number'}</label>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-1">
                                        {":"}
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-12">
                                        <input
                                        type="text"
                                        name="racknum"
                                        className="form-control"
                                        value={scannedRackNum}
                                        placeholder="scan here"
                                        id="racknum"
                                        readOnly
                                    />
                                    </div>
                                </div>
                            </div>
                            </div>
                            }
                            <div className="col-md-12 col-12 edit-save-btn mt-3 mb-4">
                                <div className="col-md-2 float-left p-0 m-0 ml-3">
                                    <button onClick={historyBack}  className="cancel-btn scan-abort-btn">{t('Abort')}</button>
                                </div>

                                {(type === 'inbound') &&
                                    <div className="col-md-9 float-right p-0 m-0 mr-3">
                                        <button type="submit" className="save-btn" onClick={saveOrderDetails}>{t('Save')}</button>
                                    </div>
                                }
                                {(type === 'outbound') &&
                                    <div className="col-md-6 p-0 m-0 mr-2 ml-0 confirm-submit" style={{float: "right"}}>
                                        <input type="text" name="ordernumber" className="form-control mr-0 ml-4 scan-field-confirm scan-borderless scan-to-confirm-field" placeholder="Confirm order.." onChange={(event) => setconfirmorderid(event.target.value)} value={confirmorderid} autoFocus/>
                                    </div>

                                    
                                }
                            </div>
                        </div>
                    ))}
                </div>}
              </div>
            </div>
        </div>
    );
}
export default ScanOrder;