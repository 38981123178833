import React,{ Component } from 'react';
import './privacypolicyAndDisclaimer.css';
import {Apiservice} from 'services/Apiservices';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory} from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { t } from 'translations/Translate';
import '../commoncss/common.css';

class PrivacyPolicy extends Component {
    constructor(props){
        super(props)
        this.state  = {
            privacypolicy:"",

        }

    }
    componentDidMount(){
        if(!JSON.parse(localStorage.getItem("isLogged"))){
            // document.getElementById("img-fluid").style.display="none";
        }
        var data={
                "from":"privacypolicies"
            }
        Apiservice.service(window.FETCH_PRIVACY_POLICY_AND_DISCLAIMER, 'POST', data)
        .then(response => {
            if(response!==null){
                this.setState({
                    privacypolicy:response.data.data.privacypolicy
                })
            }

        }).catch(error => {
            console.error("privacypolicies api problem : ", error)
        })
    }

    render() {
        return(
            <div className="alignCenter">
                <Row className="privacypolicyMOvingBottom">
                    <Col className="col-sm-12 col-md-8 offset-md-2 ">
                        <p class="backButton privacypolicyBackButton " onClick={this.props.history.goBack}> </p>
                        <h4 className="privacyPolicyDisclaimerColor">{t('Privacy policy')}</h4>
                    </Col>
                </Row>
                <Row className="privacypolicyMOvingBottom">
                    <Col className="col-sm-12 col-md-8 offset-md-2">
                        <p dangerouslySetInnerHTML={{__html:this.state.privacypolicy}}/>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default PrivacyPolicy;
