window.API_WEBSHOP_BASE_URL = 'webshopapi/shopv1/'
window.API_BCREW_BASE_URL = 'api/v1/'
window.FETCH_FLASH_SALES = window.API_WEBSHOP_BASE_URL+'flashsales';
window.FETCH_TRANSLATIONS = window.API_BCREW_BASE_URL + 'translations';
window.FETCH_FILTERS = window.API_WEBSHOP_BASE_URL+'filters';
window.FETCH_FILTER_PRODUCTS = window.API_WEBSHOP_BASE_URL+'filters'
window.ADD_ADDRESS = window.API_WEBSHOP_BASE_URL + 'addaddres'
window.UPDATE_USER_LANG = window.API_BCREW_BASE_URL+'profiles';
window.FETCH_PRODUCT_DETAIL= window.API_WEBSHOP_BASE_URL + 'products';
window.ADD_TO_CART = window.API_WEBSHOP_BASE_URL + 'addcarts';
window.TERMS_AND_CONDITION = window.API_WEBSHOP_BASE_URL+'termsandconditions';
window.DEFAULT_LANGUAGE= window.API_WEBSHOP_BASE_URL+'defaultlanguages/';
window.UPDATE_TERMS_AND_CONDITION = window.API_WEBSHOP_BASE_URL+'updateterms/';
window.FILTERS_BASED_ON_SALEID = window.API_WEBSHOP_BASE_URL+'salefilters';
window.FETCH_PICKUP_LOCATIONS = window.API_WEBSHOP_BASE_URL+'pickuplocations';
window.PROCEED_TO_CHECKOUT = window.API_WEBSHOP_BASE_URL+'checkouts';
window.CHECK_USERS = window.API_WEBSHOP_BASE_URL+'checkusers';
window.NOTIFY_USER = window.API_WEBSHOP_BASE_URL+'notifysales';
window.USER_PROFILE = window.API_BCREW_BASE_URL+'profiles/';
window.FETCH_PRIVACY_POLICY_AND_DISCLAIMER =  window.API_WEBSHOP_BASE_URL+'privacypolicies';
window.MYORDERS = window.API_WEBSHOP_BASE_URL+'myorders';
window.INVOICE = window.API_WEBSHOP_BASE_URL+'invoiceprints';
window.NOTIFY_USER_SALE_START = window.API_WEBSHOP_BASE_URL+'sendnotifications';
window.UNSUBSCRIBE_USER = window.API_WEBSHOP_BASE_URL+'unsubscribes';
window.TRANSPORT_SERVICES = window.API_WEBSHOP_BASE_URL+'shippingmethods';
window.ADDCART = window.API_WEBSHOP_BASE_URL+'addcarts';
window.LOCATIONBYUSER = window.API_WEBSHOP_BASE_URL+'locationsbyusers';
window.PICKUPORDERDETAILS = window.API_WEBSHOP_BASE_URL+'orderdetails';
window.SCANNEDORDER = window.API_WEBSHOP_BASE_URL+'updateorders';
window.USERSLIST_BY_LOCATION = window.API_WEBSHOP_BASE_URL+'usersbylocations';
window.ORDERS_BY_LOCATION = window.API_WEBSHOP_BASE_URL+'ordersbylocations';