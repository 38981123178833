import React, {useState} from "react";
import './Login.css';
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom'
import {Apiservice} from 'services/Apiservices'
import { OCAlert } from '@opuscapita/react-alerts';
import { t } from 'translations/Translate'

const ChangePassword = (props) => {

    let params = useParams();
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const history = useHistory();

    const validatePassword = (value) => {
      var match = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
      var final = (value.match(match)) ? true:false;

      return final;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if(password !== confirmpassword){
            return OCAlert.alertError(t('Password and confirm password should be same'), { timeOut: window.ALERT_WARNING_TIME });
        }
        if(!validatePassword(password)){
            return OCAlert.alertError(t('Password must contain atleast 8 character,one special character, integer and uppercase letter'), { timeOut: window.ALERT_WARNING_TIME });
        }
        let data = {
          "actions" : 'reset-password',
          "password": password,
          "uid": params.userId,
        }
        Apiservice.service('api/v1/registerusers', 'POST', data).then(res =>{
    
            OCAlert.alertSuccess(t('Password reset successfull.'), { timeOut: window.ALERT_SUCCESS_TIME });
            history.push("/login");
        })
        .catch(function (error) {
          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
            OCAlert.alertError(error.response.data.message, { timeOut: window.ALERT_ERROR_TIME });
        });
    }

    return (
        <div className="changepassword">
             <div className="">
            <form onSubmit={onSubmit} className="loginForm col-md-12 pt-5 mt-5">
            <p className="textcolor mt-4 mb-5 changepassword_text_font_size chengepassword-text">{t('Change password')}</p>
                <div className="form-group mb-3">
                    <label className="textcolor password_text_fontsize">{t('Password')}</label>
                    <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                    <div className="passwordsect">
                        <input
                            type={(showPassword ? "text" : "password")}
                            name="password"
                            id="password"
                            required
                            className="form-control"
                            value={password}
                            placeholder={t('Enter password')}
                            onChange={(e) => setPassword( e.target.value)}
                        />
                        <span className={(showPassword ? "passwordvisiblee" : "passwordinvisiblee")} onClick={() => setShowPassword(!showPassword)}></span>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <label className="textcolor password_text_fontsize">{t('Confirm password')}</label>
                    <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                    <div className="passwordsect">
                        <input
                            type={(showConfirmPassword ? "text" : "password")}
                            name="confirmpassword"
                            id="confirmpassword"
                            required
                            className="form-control"
                            value={confirmpassword}
                            placeholder={t('Confirm password')}
                            onChange={(e) => setConfirmpassword( e.target.value)}
                        />
                        <span className={(showConfirmPassword ? "passwordvisiblee" : "passwordinvisiblee")} onClick={() => setShowConfirmPassword(!showConfirmPassword)}></span>
                    </div>
                </div>
                {<div className="text-center mt-5 pt-5 button-bottom">
                    <button className="btn login-btn col-12 text-center block mb-2">{t('Submit')}</button>
                </div>}
            </form>
            </div>
        </div>
    );
}

export default ChangePassword;
