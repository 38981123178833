import React from 'react'
import LeftSideNav from '../components/sidenav/LeftSideNav.js';
import LoginSelection from 'components/Login/LoginSelection.js';

/**
 * [GlobalLayout Add all Global layouts here ]
 * For example footer, headers, sidenavbars etc..
 */
const GlobalLayout =()=>{
  const isLogged = JSON.parse(localStorage.getItem("isLogged"));

  return<>
        {
          <>
          <LeftSideNav/>

          </>
        }
        
    </>
}


export default GlobalLayout;
