import React, { Component } from 'react';
import './footer.css';
import '../components/commoncss/common.css';
import { t } from 'translations/Translate'

export default class Footer extends Component{

    constructor(props){
      super(props)

    }
    render() {
        return (
            <footer className="footerwrap bg-color px-3">
             <div className="footerinner row col-md-12 m-0">
                 <div className="copyright-footer col-lg-7 col-md-6">
                    <p className=""><a className="copyrightColor">{t('© Copyright')} {(new Date().getFullYear())}</a></p>
                 </div>
                 <div className="col-lg-5 col-md-6 justify-content-end d-none d-md-flex">
                    <p className="disclaimer-border px-3"><a href="/disclaimer" >{t('Disclaimer')}</a></p>
                    <p className="pl-3"><a href="/privacypolicy">{t('Privacy policy')}</a></p>
                 </div>
                 <div className="col-lg-4 col-md-4 d-block d-md-none">
                    <p className="disclaimer-border"><a href="/disclaimer" >{t('Disclaimer')}</a></p>
                    <p className=""><a href="/privacypolicy">{t('Privacy policy')}</a></p>
                 </div>
             </div>
            </footer>
        )}
    }
