import React,{ useState } from 'react';
import MyOrders from './MyOrders';
import AddressBook from './AddressBook';

import MyAccount from './MyAccount';

import './myProfile.css';
import OrderDetails from './OrderDetails';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { t } from '../../translations/Translate';
import UpdateMyProfile from './UpdateMyProfile';
import AddAddress from '../Address/Addaddress';
import  home_img from '../commoncss/images/home.png';
import '../commoncss/common.css';
import { useHistory}  from "react-router-dom";
import Pickup from 'components/PickupOrders/Pickup';
import UserStatus from 'components/Login/UserStatus.js';

function MyProfileView({ordersdetailspage}) {
  let params = useParams();
  let history=useHistory();
  const [urltext,setUrltext]=useState('myaccount');
  const pickup_access = JSON.parse(localStorage.getItem('pickup_accress'))
  if (urltext === 'myorders'/* || urltext === 'myaccount' || urltext === 'addressbook' || urltext === 'logout'*/ ) {
    ordersdetailspage=undefined;
  }

  // check user status if suspended logouts 
  UserStatus();

  const logout =()=>{
    let translations = localStorage.getItem('translations');
    let loginType = localStorage.getItem('loginType');
    if(loginType === "sso"){
    let auth_token = localStorage.getItem('auth_token');
    window.location.href = process.env.REACT_APP_API_URL+"sso-logout-api?rid=2&token="+auth_token;
    }else{
      localStorage.clear();
      localStorage.setItem('translations',translations);
       window.location.href ='/login'
    }
  }
  // console.log(pickup_access);
  return (
    <section className="desktop-right-content">
       <div className="layout-space fixed-layout">
       <nav aria-label="breadcrumb " className="col-md-12 col-lg-12 col-12 px-0 mt-4 pt-1 marginAlignmentForMyProfile">
                  <ol className="breadcrumb bg-transparent px-0">
            <p class="backButton leftAdjustmentBreadscrumallPage leftAdjustmentBreadscrumallPageformobile" onClick={history.goBack}> </p>
            
                    <li className="breadcrumb-item home"><Link to="/"><img  src={home_img} alt=""/></Link></li>
                    {/* {<li className="breadcrumb-item"><Link to="/">{params.myorders==="myorders"?"My Orders":params.myorders==="addressbook"?"Address Book":params.myorders==="myaccount"?"My Account":null}</Link></li>} */}
                    {/* {/* {(window.location.pathname.includes('cart')) && <li className="breadcrumb-item"><Link to="/mycart">{t('My cart')}</Link></li>} */}
                    {(params.myorders === "myOrderDetails") && <li className="breadcrumb-item"><Link to="/myprofile/myorders">{t('My orders')}</Link></li>}
                    {(params.myorders === "editAddress") && <li className="breadcrumb-item"><Link to="/myprofile/addressbook">{t('Address book')}</Link></li>}
                    <li className="breadcrumb-item active" aria-current="page">{params.myorders==="myorders"?(t('My orders')):params.myorders==="addressbook"?(t('Address book')):params.myorders==="myaccount"?(t('My account')):params.myorders === 'editAddress'?(t('Edit Address')):(t('Order details'))}</li>
                    {
                      pickup_access  &&
                        <Link to="/pickup" className="go-to-pickup" onClick={()=>setUrltext("pickup")}>{t('Pickup Location Management')}</Link>
                     }
                  </ol>
        </nav>
       
        <div className="row profile-inner fixed-layout">
            <div className="col-md-3 col-lg-3 col-12 mt-4 pt-2 mob-profile-menu">
              <ul className="left-menu">

                <li className=""><Link to="/myprofile/myaccount" className={(params.myorders ==="myaccount"||params.myorders ==="updateMyProfile")?"onBtnActive":"bg-transparent"} onClick={()=>setUrltext("myaccount")}>{t('My profile')}</Link></li>
                  <li className=""><Link to="/myprofile/addressbook"  className={(params.myorders ==="addressbook" || params.myorders === 'editAddress')?"onBtnActive":"bg-transparent"} onClick={()=>setUrltext("addressbook")}>{t('Address book')}</Link></li>
                  <li className=""><Link to="/myprofile/myorders"  className={(params.myorders ==="myorders"||params.myorders ==="myOrderDetails")?" bg-transparent onBtnActive":"bg-transparent"} onClick={()=>setUrltext("myorders")}>{t('My orders')}</Link></li>
                 
                  <li className="logout-list"><a className="bg-transparent" onClick={logout}>{t('Logout')}</a></li>
              </ul>
            </div>
            <div className="col-md-9 col-lg-9 col-12 mobile-profilr-layout">
                <div className={ordersdetailspage === undefined?"col-lg-12 col-12 col-md-12 px-0":"hide-class"}>
                      {params.myorders === "myorders" && <MyOrders />}
                      {params.myorders === "addressbook" && <AddressBook/>}
                      {params.myorders === "myaccount" && <MyAccount/>}
                      {params.myorders === "pickup" && <Pickup/>}
                      {params.myorders === "myOrderDetails" &&   <OrderDetails/> }
                      {params.myorders === "updateMyProfile" &&   <UpdateMyProfile/> }
                      {params.myorders === "editAddress" && <AddAddress from={"profile"} />}
                </div>
            </div>
          </div>
       </div>
    </section>
  );
}

export default MyProfileView;
