import React, { Component } from 'react'
import { OCAlert } from '@opuscapita/react-alerts';
import { Link } from "react-router-dom";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { t } from 'translations/Translate'

export default class ProductsView extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount(props) {
      const {status} = this.props;
      let msg = status === 'duplicate' ? 'Payment already received for this order' : 'Oops, something went wrong';
        OCAlert.alertError(t(msg), { timeOut: window.ALERT_ERROR_TIME });
    }

    render() {
      const {status} = this.props;
        return (
            <>
                <div className="text-center" style={{ marginTop: '150px' }}>
                    <HighlightOffOutlinedIcon className="text-danger" style={{ width: "130px", height: "50px" }} />
                    <h4>{status !== 'duplicate' ? t('Oops, something went wrong') : t('Payment already received for this order.')}</h4>
                    <div className="pt-2">
                        <Link className="text-center text-danger btn" to= {status !== 'duplicate' ? "/":"/myprofile/myorders"}>{status !== 'duplicate' ? t('Back to home'): t('Back to my orders')}</Link>
                    </div>
                </div>
            </>
        )
    }
}
