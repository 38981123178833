import React from 'react';
import { useState, useEffect } from 'react';
import FlashSalesView from '../flashsales/FlashSalesView.js'
import { Apiservice } from '../../services/Apiservices';
import { Modal } from "react-bootstrap";
import { t } from 'translations/Translate'
import Button from 'react-bootstrap/Button';
import { OCAlert } from '@opuscapita/react-alerts';
import UserStatus from 'components/Login/UserStatus.js';
const Home = () => {
    const [webshopTermsAndConditions, setWebshopTermsAndConditions] = useState(localStorage.getItem('terms_and_conditions'));
    const [show, setShow] = useState(true);
    const [termsConditions,setTermsConditions]=useState("");
    
    // check user status if suspended logouts 
    UserStatus();

    useEffect(() => {
        (async () => {

            if(localStorage.getItem('terms_and_conditions')===false || localStorage.getItem('terms_and_conditions')==='false'){

                const data={
                    "user_id" : JSON.parse(localStorage.getItem('user')).id
                }
                await Apiservice.service(window.TERMS_AND_CONDITION, "POST", data)
                .then(response => {

                    if(response!==null){
                        setTermsConditions(response.data.data.terms_and_conditions);
                    }
                }).catch(error => {
                    console.error("first login modal error : ", error);
                })
            }
        })()
    },[]);

    useEffect(() => {

        const data={
            "user_id" : JSON.parse(localStorage.getItem('user')).id,
            "type":"count",
        }
       let locationAccess = JSON.parse(localStorage.getItem('pickup_accress'));
        if(locationAccess === null){
            Apiservice.service('webshopapi/shopv1/locationsbyusers', "POST", data)
            .then(response => {
                if(response.data.STATUS == 200){
                    localStorage.setItem('pickup_accress', (response.data.DATA.count != 0) ? true : false);
                }
            }).catch(error => {
                console.error("Getting error in api response : ", error);
            })
        }
        
    }, []);

    const handleClose = () => {
        localStorage.setItem('terms_and_conditions',true);
        setShow(false)
    };
    const handleRedirect = async() => {
        const data={
            "user_id" : JSON.parse(localStorage.getItem('user')).id,
            "webshop_terms_and_conditions" : "true"
        }
        setShow(false)
        await Apiservice.service(window.UPDATE_TERMS_AND_CONDITION+data.user_id, "PUT", data)
        .then(response => {
            if(response.status===200){
                setWebshopTermsAndConditions(true)
                localStorage.setItem('terms_and_conditions',true)
                window.location.reload();
            }

        }).catch(error => {
            OCAlert.alertError(t('Error while updating'), { timeOut: window.ALERT_ERROR_TIME });
        })

    }
    return<div className='desktop-right-content'>
        {(webshopTermsAndConditions==="false" || webshopTermsAndConditions===false) && termsConditions!=='null' && termsConditions!==null && termsConditions!=="" && termsConditions!=='undefined' &&
            <Modal size
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="conditions-popup modalWidthReduce"
            >
                <div className="terms-and-conditions-popup">
                <Modal.Header closeButton className="popup-title popupHeaderPaddingAdjustment">
                  <Modal.Title className="paddingAdjusting">{t('Terms and conditions')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="popup-content popupHeight" >
                    <p dangerouslySetInnerHTML={{__html:termsConditions}}/>
                </Modal.Body>
                <Modal.Footer className="popup-accept footerWidthAdjustment">
                   <Button className="buttonSizeReduce" variant="danger" onClick={handleRedirect}>{t('Accept')}</Button>
                </Modal.Footer>
                </div>
            </Modal>
        }
        <FlashSalesView/>
    </div>
}
export default Home
