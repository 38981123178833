import React, { useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";

const SsoLogout = () => {
   const history = useHistory();
   useEffect( () => {
      let translations = localStorage.getItem('translations');
      localStorage.clear();
      localStorage.setItem('translations',translations);
      history.push('/sso-login');
   });
return(<div></div>);
}

export default SsoLogout;
