import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
import './Login.css';
import { Modal } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {Apiservice} from 'services/Apiservices'
import firebase from 'firebase.js';
import { OCAlert } from '@opuscapita/react-alerts';
import Button from 'react-bootstrap/Button';
import LoginNav from './LoginNav.js';
import { t } from 'translations/Translate'
import { Link } from "react-router-dom";
const MobileForm = () => {


    const [phone, setPhone] = useState("");

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     let number = "+" + phone

    //     let otp = new firebase.auth.RecaptchaVerifier('recaptcha');
    //     firebase.auth().signInWithPhoneNumber(number, otp)
    //     .then(confirmationResult=>{
    //       let code = prompt('otp','')
    //       confirmationResult.confirm(code).then( res=>{

    //       })
    //     })
    // }

    const handleSubmit = (e) => {
      e.preventDefault();
      if (phone === '') {
          OCAlert.closeAlerts();
          return OCAlert.alertError(t('Please enter the phone number.'), { timeOut: window.ALERT_WARNING_TIME });
      }
      let number = "+" + phone
      let data = {
        "actions": 'login-otp-webshop',
        "phone": number,
        "from":window.ORIGIN_REQUEST,
      }
     Apiservice.service('api/v1/logins', 'POST', data)
        .then(res => {

          if (res.status === 400) {

            OCAlert.alertError('Please enter the phone number.', { timeOut: window.ALERT_WARNING_TIME });
          }
          else {

              let data = {
                  id : res.data.data[0].id,
                  firstName : res.data.data[0].firstName,
                  lastName : res.data.data[0].lastName,
                  active_language : res.data.data[0].webshop_active_language,
                  workPhone : res.data.data[0].webWorkPhone,
                  workMail : res.data.data[0].webWorkEmail,
                  personalMail : res.data.data[0].webPEmail,
                  lastLoginDate : res.data.data[0].lastLoginDate,
                  group_id : res.data.data[0].group_id,
                  user_role : res.data.data[0].user_role,
                  terms_and_conditions:res.data.data[0].webshop_terms_and_conditions,
              }

              signIn(number, data)
            }
          }).catch(error => {
            if(document.getElementById('loading-icon')!== null){
              document.getElementById('loading-icon').style.display = 'none';
            }
            if (error.response.data.message === 'Invalid phone number') {
                OCAlert.closeAlerts();
                OCAlert.alertError(t('Invalid phone number'), {timeOut: window.ALERT_ERROR_TIME});
            }
            else if (error.response.data.message === 'Phone number does not exist') {
                OCAlert.closeAlerts();
                OCAlert.alertError(t('Phone number does not exist'), {timeOut: window.ALERT_ERROR_TIME});
            }
            else if (error.response.data.message === 'User inactive') {
                OCAlert.closeAlerts();
                OCAlert.alertError(t('User inactive'), {timeOut: window.ALERT_ERROR_TIME});
            }
            else {
                OCAlert.alertError(error.response.data.message, { timeOut: window.ALERT_ERROR_TIME });
            }
        })
    };

    const signIn = (number, data) => {

      setUpRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      //Firebase function to send OTP to given number
      firebase.auth().signInWithPhoneNumber(number, appVerifier)
        .then(function (confirmationResult) {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          OCAlert.alertSuccess(t('Verification code has been sent to your mobile number.'));
          history.push({
            pathname: '/login/otpverify',
            state: {
                number: number,
                data: data
            }
          })
        }).catch(function (error) {

          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
          OCAlert.alertError(t('All otp request from this device have been temporarily blocked due to too many otp request. please try again later.'), { timeOut: 6000 });

          return;
        });
    }

    const setUpRecaptcha = () => {
      // e.preventDefault();
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': function (response) {

          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // handleSubmit();
        }
      });
    };
        const isLogged = JSON.parse(localStorage.getItem("isLogged"));
        const history = useHistory();
        const goBack = () => {
            history.push('/login')
        }

    return (
      <div className="" >
        {!isLogged &&
      <div className="" >
       <p class="backButton topAdjustment" onClick={history.goBack}> </p>
    <LoginNav id="hidingdiv"/>
    <form onSubmit={handleSubmit}>
                <div  className="mt-3">
                    <div className="mobileForm">
                        <div className="form-group">
                            <div className="form-group mb-3 number">
                                <label className="textcolor email_text_fontsize">{t('Phone number')}</label>
                                <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                                <PhoneInput
                                    country='be'
                                    onlyCountries={['fr', 'us','be','nl','de','ae','gb','tr','in']}
                                    countryCodeEditable={false}
                                    onChange={(phone) => setPhone(phone)}
                                    inputClass="padding-left-numbers phone_input_width"
                                    placeholder={t('Enter phone number')}
                                    value={phone}
                                    autoFocus
                                    inputProps={{
                                        autoFocus: true,
                                        required:true
                                      }}
                                />

                            </div>
                        </div>
                    </div>
                    <div id="recaptcha-container"></div>
                    <div className="mt-5 pt-5">
                    </div>
                    <div className="mt-3 pt-4">
                    </div>

                    <div className="col-md-12 text-center p-0 send-my-code">
                        <button type="submit" className="btn login-btn col-12 text-center block mb-2">{t('Send my code')}</button>
                    </div>
                    {/*<p className="text-center">{t("Don't have an account?")} <Link to="/login/new-user" className="textcolor password_text_fontsize">{t('Register')}</Link></p>*/}
                </div>


    </form>
    </div>
}
    </div>
    );
};
export default MobileForm
