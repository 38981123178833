import React, { Component } from 'react'
import { OCAlert } from '@opuscapita/react-alerts';
import { Link } from "react-router-dom";
import {Apiservice} from 'services/Apiservices'
import Address from 'components/Address/Addaddress.js'
import * as Reactbootstrap from 'react-bootstrap';
import '../commoncss/common.css';
import { t } from 'translations/Translate'

export default class AddressBook extends Component {

    constructor(props){
        super(props)
        this.state ={
            userAddressDetails :[],
            expand:false,
            update:'',
            primaryShippingAddressId: '',
            userId: JSON.parse(localStorage.getItem("user")).id
        }
    }

    componentDidMount(){
        this.getAddress()
    }

    componentDidUpdate(prevProps,prevState){
      if(prevState.update !== this.state.update){
                this.getAddress()
      }
    }

    getAddress = () =>{
        let data = {
            type:'userAddress',
            user_id : this.state.userId
        }
        Apiservice.service(window.ADD_ADDRESS, 'POST',data).then(res =>{
          // if(res.data.DATA.address.length === 1){
          //   // this.selectAddress(res.data.DATA.primaryShippingAddressId)
          // }
            this.setState({
                userAddressDetails: res.data.DATA.address,
                primaryShippingAddressId: res.data.DATA.primaryShippingAddressId
            })
        })
    }

    expandAddress =(e) =>{
      this.setState(
        {expand:true}
      )
      setTimeout(function () { document.getElementById('addaddress').scrollIntoView() }, 100)
    }

    deleteAddress =(id) =>{
        if (id === this.state.primaryShippingAddressId) {
            OCAlert.closeAlerts();
            OCAlert.alertError(t('Default address cannot be deleted'), {timeOut: window.ALERT_ERROR_TIME});
        }
        else {
            let data = {
                "user_id": this.state.userId,
                "type": "removeAddress",
                "addressId": id,
            }
            Apiservice.service(window.ADD_ADDRESS, 'POST',data).then(res =>{
                if(res.data.STATUS === 201){
                    OCAlert.alertSuccess(t('Address deleted successfully'), {timeOut: window.ALERT_ERROR_TIME});
                    this.getAddress()
                }
            })
            .catch(function (error) {
                OCAlert.alertError(error.response.data.DATA.message[0], { timeOut: window.ALERT_ERROR_TIME });
            });
        }
    }

    selectAddress = (id) => {
        let data = {
            "user_id": this.state.userId,
            "type": "updatePrimaryAddress",
            "primaryBillingAddressId": id,
            "primaryShippingAddressId": id
        }
        Apiservice.service(window.ADD_ADDRESS, 'POST',data).then(res =>{
            if(res.data.STATUS === 201){
                localStorage.removeItem('pickup')
                OCAlert.alertSuccess(t('Default address selected'), {timeOut: window.ALERT_ERROR_TIME});
                this.getAddress()
            }
        })
        .catch(function (error) {
            OCAlert.alertError(error.response.data.DATA.message, { timeOut: window.ALERT_ERROR_TIME });
        });
    }

    renderAddress =() =>{
        const {userAddressDetails} = this.state
        const {primaryShippingAddressId} = this.state
        let result  = userAddressDetails.map(ele =>{
            if(ele.firstName !== null && ele.firstName.length >0){
                return <section className="col-md-12 col-lg-12 col-12  addressWrap address-book">
                <div className=" mobileview">
                    <Link className="actionIcons marginAdjustmentRight mt-2 ml-1 editIcon" to = {('/myprofile/editAddress/'+ele.id)} title = {t('Edit')}>edit</Link>
                    <Link className = {ele.id === primaryShippingAddressId ? 'ribbanIcon active mt-2  marginAdjustmentRight actionIcons' : 'ribbanIcon mt-2   marginAdjustmentRight actionIcons'} onClick = {(e) => this.selectAddress(ele.id) } title = {t('Set address as primary')}>save</Link>

                </div>
                    <div className="row m-auto">
                    <div className="col-md-6 col-lg-6 col-12 pl-0">
                        <div className="row">
                                <label class="radioWrap mb-0">
                                    <h6 className="titleAdjustment">{ele.title}</h6>
                                    <input type="radio" onChange = {(e) => this.selectAddress(ele.id) } checked = {ele.id === primaryShippingAddressId ? true : false} name="radio" />
                                    <span class="checkmark"></span>
                                </label>

                               <div className="col-md-12 col-lg-12 col-10 pl-4 lightcolor mob-line-height px-0">
                                    <div> {ele.firstName + ' ' + ele.lastName}</div>
                                    <div> {ele.businessName}</div>
                                    <div><span>{ele.custom1}&nbsp;{ele.custom2} &nbsp;{(ele.custom3 !== '' || null) && (t('Box')+ ': '+ele.custom3)}<br></br></span></div>
                                    <div><span>{ele.zipCode}&nbsp;{ele.city}</span></div>
                                    <div>{ele.countryText}</div>
                                </div>


                        </div>
                    </div>
                    <div className="col-md-3 col-lg-3 col-12 phoneDivAdjust">
                            <div className="pl-2">
                                <h6 className="mb-1 leftAlignment">{t('Phone number')}</h6>
                                <div className="lightcolor numberSection leftAlignment">{ele.phone}</div>
                            </div>
                            {ele.businessTaxId && <div className="mt-4 pt-2 pl-2">
                                <h6 className="mb-1 leftAlignment">{t('VAT number')}</h6>
                                <div className="lightcolor numberSection leftAlignment">{ele.businessTaxId}</div>
                            </div>}

                    </div>
                    <div className="col-md-3 col-lg-3 col-12  pr-0 mob-calltoaction laptopview">
                        <Link className = {ele.id === primaryShippingAddressId ? 'ribbanIcon active actionIcons' : 'ribbanIcon actionIcons'} onClick = {(e) => this.selectAddress(ele.id) } title = {t('Set address as primary')}>save</Link>
                        <Link className="actionIcons editIcon" to = {('/myprofile/editAddress/'+ele.id)} title = {t('Edit')}>edit</Link>
                        <Link className="actionIcons deleteIcon desk-dele-icon" onClick = {(e) => this.deleteAddress(ele.id)} title = {t('Delete')}>dele</Link>
                        <div className="deleteAlign">
                            <Link className="actionIcons deleteIcon" onClick = {(e) => this.deleteAddress(ele.id)} title = {t('Delete')}>dele</Link>
                        </div>
                    </div>
                    </div>
                    <div className="row  marginAdjustmentRight mobileview">
                        <Link className="actionIcons deleteIcon desk-dele-icon delIconAdjust" onClick = {(e) => this.deleteAddress(ele.id)} title = {t('Delete')}>dele</Link>
                    </div>


                </section>
            }
        })
        return result;
    }

    updateComponent(e){
      this.setState({
        update:e,
        expand:false
    },()=> setTimeout(function () { document.getElementById('manage').scrollIntoView() }, 100))
    }

    render() {
        return(
           <section className="col-md-12 col-lg-12 col-12 px-0">
                <h6 className="mb-0 page-title">{t('ADDRESS BOOK')}</h6>
                <hr className="w-100 mt-3"></hr>
                <div className="col-md-12 col-lg-12 col-12 px-0">
                    {this.renderAddress()}
                </div>
                <div className = "col-md-12 col-lg-12 col-12 px-0 add_address" id ="manage">
                        <div className="col-md-12 col-lg-12 col-12 px-0 add-shipments">
                            <a id = "addaddress" className="col-md-12 col-lg-12 col-12  textcolor" onClick = {(e) => this.expandAddress(e)}><span className="plusAddress">+</span>{t('ADD SHIPPING ADDRESS')}</a>
                        </div>
                </div>
                {this.state.expand &&<div className ="col-md-12 col-lg-12 col-12 add-new_address px-0">
                             <Address updateComponent = {this.updateComponent.bind(this)} length = {this.state.userAddressDetails.length}/>
                        </div> }
           </section>
        )
    }
}
