import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
// import * as serviceWorker from './serviceWorker';
import { OCAlertsProvider } from '@opuscapita/react-alerts';
import {Bootstrap} from './bootstrapApp/Bootstrap.js';
const containerStyle= ({
        top: '-5px',
        maxWidth: '500px',
        bottom: '0px',
        height: '0px',
        position: 'fixed'
    });

  Bootstrap.loadTranslations();


ReactDOM.render(
  <React.StrictMode>
    <App />
    <OCAlertsProvider containerStyle={containerStyle}/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
