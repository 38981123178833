import React, {useState} from "react";
import { useHistory } from 'react-router-dom'
import {Apiservice} from 'services/Apiservices'
import { t } from 'translations/Translate'
import './Login.css';
import { OCAlert } from '@opuscapita/react-alerts';

const ForgotPassword = () => {

    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        
        let data = {
          "actions": "forgot-password",
          "email": email
        }
        Apiservice.service('api/v1/registerusers', 'POST', data)
          .then(result => {
              OCAlert.alertSuccess(t('Mail sent successfully.'));
          })
          .catch(error => {
            if(document.getElementById('loading-icon')!== null){
              document.getElementById('loading-icon').style.display = 'none';
            }
              OCAlert.alertError(error.response.data.message, { timeOut: window.ALERT_ERROR_TIME });
          })
    }

    const history = useHistory();

    const goBack = () => {
        history.push('/emailform')
    }

    return (
        <section className="">
            <div className="">
            <p class="backButton topAdjustment" onClick={history.goBack}> </p>
            <div className="text-left col-md-12 mt-5 pt-2 pl-0">
                <p className="textcolor mt-4 mb-2 forgotpassword_text_font_size">{t('Forgot password')}</p>
            </div>
            <form  onSubmit={handleSubmit} className="loginForm col-md-12 pt-3 pl-0">
                <div className="form-group">
                    <label className="textcolor">{t('Email')}</label>
                    <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                    <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        required
                        aria-describedby="emailHelp"
                        placeholder={t('Enter email')}
                        autoFocus
                    />
                </div>
                <p className="fontsizeofagreement ">{t('Please enter your registered email address.')}</p>
                {<div className="text-center mt-5 pt-5 button-bottom send-my-code">
                    <button className="btn login-btn col-12 text-center block mb-2">{t('Reset password')}</button>
                </div>}
            </form>
            </div>
        </section>
    );
}

export default ForgotPassword;
