export const detect ={
  iosSystemInfo,
}
function iosSystemInfo(){
  let iosVersion = 0;
  try {
    if(navigator.userAgent.match( /iP(hone|od|ad)/)){

     iosVersion = parseFloat(String(navigator.userAgent.match(/[0-9]_[0-9]/)).split('_')[0]+'.'+String(window.navigator.userAgent.match(/[0-9]_[0-9]/)).split('_')[1]);
      // iPhone CPU iPhone OS 8_4 like Mac OS X
  }
  } catch (e) {

  }
  return iosVersion;
}
