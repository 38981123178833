import React, { useState, useEffect } from 'react';
import {Apiservice} from 'services/Apiservices';
import { t } from '../../translations/Translate';
import Inbound from './Inbound';
import Outbound from './Outbound';
import Search from './pickupSearch';
import { Link } from "react-router-dom";
import Select from 'react-select';
import './pickuporder.css';
import OrdersInTab from './OrdersInTab';
import { useHistory } from 'react-router-dom'
import { OCAlert } from '@opuscapita/react-alerts';

export default function Pickup() { //NOSONAR

const [currentTab, setCurrentTab]=useState('inbound');
const [Locations, setLocations] = useState([]);
const [selectedLocations, setselectedLocations] = useState([]);
const [orders, setOrders] = useState([]);


const [searchSubmit, setSearcSubmit] = useState(false);

const [search_rack, setSearchRack] = useState(null);
const [search_user, setSearchUser] = useState(null);
const [search_order, setSearchOrder] = useState(null);

const [offset, setoffset] = useState(0);
const [hasMore, sethasMore] = useState(false);
const [statusCode, setstatusCode] = useState();
const [inboundCount, setinboundCount] = useState();
const [outboundCount, setoutboundCount] = useState();
const [pickedUpCount, setpickedUpCount] = useState();
const [CountsData, setCountsData] = useState();

const pickup_access = JSON.parse(localStorage.getItem('pickup_accress'));

useEffect(()=>{
            let tab = localStorage.getItem("currentTab");
            if(tab !== null){
              setCurrentTab(tab);
            }else{
              localStorage.setItem('currentTab', currentTab);
            }
        let userDetails = JSON.parse(localStorage.getItem("user"))
        let data = {
          user_id : userDetails['id']
        }
         Apiservice.service(window.LOCATIONBYUSER, "POST", data).then(res => {
            if (res.data.STATUS === 200) {
              const orderlocation = [];
              // sort(res.data.DATA);
              Object.keys(res.data.DATA).map((value,i) =>
              orderlocation.push({ value: value, label:res.data.DATA[value]})
              )
              orderlocation.sort(function (a,b){
                    var nameA = a.label.toUpperCase();
                    var nameB = b.label.toUpperCase();
                    if(nameA < nameB){
                      return -1;
                    }
                    if(nameA > nameB){
                      return 1;
                    }
                    return 0;
              });
              const all_loc = {value: 'All_locations', label:'All my pickup locations'}
              orderlocation.splice(0, 0, all_loc);
              // orderlocation.push({value: 'All_locations', label:'All my pickup locations'})
              // console.log(orderlocation);
              setLocations(orderlocation);
              let pickupLocationDetails = JSON.parse(localStorage.getItem("pickupLocation"));
              if(pickupLocationDetails !== null && pickupLocationDetails !== undefined){
                // {Object.keys(orderlocation).map((key, value) => {
                //   if(Number(orderlocation[value].value) === Number(pickupLocationDetails.value)){
                    setselectedLocations(pickupLocationDetails)
                //   }
                // })}
              }else{
                localStorage.setItem('pickupLocation', JSON.stringify(orderlocation[0]));
                setselectedLocations(orderlocation[0]);
              }
             
            }
            
        }).catch(e=>{
          setstatusCode();
          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
        })
  },[]);

  useEffect(()=>{
        let userDetails = JSON.parse(localStorage.getItem("user"));
        // console.log(selectedLocations);
        if(selectedLocations.value){
          let data = {
            "location_id": selectedLocations.value,
            "user_id" : userDetails['id'],
            "type" : currentTab,
            "offset":0,
            "search_user":search_user,
            "search_rack_no":search_rack,
            "search_order_id":search_order,
         
          }
           Apiservice.service("webshopapi/shopv1/ordersbylocations", "POST", data).then(res => {
            //  console.log(res);
              if (res.data.STATUS === 200) {
              setOrders(Object.values(res.data.DATA));
              setstatusCode(res.data.STATUS);
              sethasMore(true);
              }
          }).catch(e=>{
            setstatusCode(e.response.data.STATUS);
            if(e.response.data.STATUS == 404){
              sethasMore(false);
            }
            setOrders([]);
            if(document.getElementById('loading-icon')!== null){
              document.getElementById('loading-icon').style.display = 'none';
            }
          })
        }
  },[selectedLocations, currentTab, searchSubmit]);

  useEffect(()=>{
    let userDetails = JSON.parse(localStorage.getItem("user"))
      let data = {
        "user_id" : userDetails['id'],
        "type":"count",
        "location_id": selectedLocations.value
      }
      if(selectedLocations.value){
        Apiservice.service(window.ORDERS_BY_LOCATION, "POST", data).then(res => {
            if (res.data.STATUS === 201) {
              setCountsData(res.data.DATA);
              Object.keys(res.data.DATA).map(e=>{
              setinboundCount(res.data.DATA.inbound);
              setoutboundCount(res.data.DATA.outbound);
              setpickedUpCount(res.data.DATA.pickedup);
            })
              // console.log(res.data.DATA.inbound);
              // setinboundCount(res.data.DATA.inbound);
              // setoutboundCount(res.data.DATA.outbound);
              // setpickedUpCount(res.data.DATA.pickedup);
            }
        }).catch(e => {
          setstatusCode(e.response.data.STATUS);
          if(e.response.data.STATUS == 404){
            sethasMore(false);
          }
          setOrders([]);
          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
        })
      }
},[selectedLocations]);


  function fetchMoreData() {
    let count = offset + 1;
    setoffset(count);
    let userDetails = JSON.parse(localStorage.getItem("user"));
    let data = {
      "location_id": selectedLocations.value,
      "user_id" : userDetails['id'],
      "type" : currentTab,
      "offset":count,
      "search_user":search_user,
      "search_rack_no":search_rack,
      "search_order_id":search_order,
   
    }
     Apiservice.service("webshopapi/shopv1/ordersbylocations", "POST", data).then(res => {
       
      if(res.data.STATUS === 200) {
        setOrders(orders.concat(Object.values(res.data.DATA)));
        // setdataLength(Number(dataLength) +res.data.DATA.length);
        setstatusCode(res.data.STATUS);

        }
    }).catch(e =>{
      setstatusCode(e.response.data.STATUS);
      if(e.response.data.STATUS == 404){
        sethasMore(false);
      }
      if(document.getElementById('loading-icon')!== null){
        document.getElementById('loading-icon').style.display = 'none';
      }
    })
  }

  function handleSearchInput(e){
    switch(e.target.name){
      case 'rack':
        setSearchRack(e.target.value);
        break;
      case 'user':
        setSearchUser(e.target.value);
        break;
      case 'order':
        setSearchOrder(e.target.value);
        break;
    }
  }

  function clearSearch(){
    setoffset(0);
    setSearchRack("");
    setSearchUser("");
    setSearchOrder("");
  }

  function handleSearchSubmit(e){
    e.preventDefault();
    setSearcSubmit(!searchSubmit)
  }

  function setSelectedLocation(e){
    setselectedLocations(e);
    localStorage.setItem('pickupLocation', JSON.stringify(e));
    clearSearch();
  }

  function tabSwitch(value){
    if(currentTab !== value){
      setCurrentTab(value);
      localStorage.setItem('currentTab', value);
      setOrders([]);
      sethasMore(false);
      setstatusCode();
      clearSearch();
    }
  }
  const colourStyles = {
    control: styles => ({
      ...styles,
      border: `1px solid #ddd`,
      boxShadow: "none",
      "&:hover": {
        borderColor: '#d6d3d3'
      },
     
      "&:active": {
        borderColor: "#d20c14"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? '#d20c14'
          : isFocused
          ? '#ddd'
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? '#fff'
           
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        "&:hover": {
          backgroundColor: '#d6d3d3'
        },
      };
    },
    
  };

  const history = useHistory();
  function historyBack() {
    history.push('/');
  
  }


return(
   
    <div className="container customContainer">
      { pickup_access === false ? 
    <div> 
      <section className="col-md-12 col-lg-12 col-12 px-0 mt-10 pt-5">
      <div className="col-md-12 col-lg-12 col-12 px-0">
       <h3 className="ml-5">You don't have access rights to view this page</h3>
      <h4 className="ml-5">Please contact your administrator.</h4>
      </div>
      </section>
    </div>:
    <section className="col-md-12 col-lg-12 col-12 pro_class">
    <div className="row col-md-12 col-lg-12 col-12 m-0 p-0">
            <nav aria-label="breadcrumb " className="col-md-12 col-lg-12 col-12 px-0  pt-1">
                  <ol className="breadcrumb bg-transparent px-0">
                  <div className="row col-md-12 col-lg-12 col-12 pt-2 p-0">
                    <div className="row col-md-8 col-lg-8 col-8">
                        <div className="col-md-12 col-lg-12 col-12 row p-0">
                            <p class="backButton edit_back_btn" onClick={history.goBack}> </p>
                            <span> <li className="breadcrumb-item active text-align-right site-history" aria-current="page">{'Pickup locations'}</li></span>
                        </div>
                        
                    </div>
                    <div className="col-md-4 col-lg-4 col-4  px-0 ml-4 overviewspace text-align-right">
                    <Link to={{pathname: '/'}} className="go-to-site">Go back to site</Link>
                    </div>
                   </div> 
                  </ol>
            </nav>
            </div>
            <div className="col-md-12 col-lg-12 col-12 px-0">
                    <div className="row m-auto">
                      <div className="col-md-2 col-lg-2 col-2 mt-2 px-0 overviewspace">
                        <h6 className="mb-3 mt-10 page-title">{'PICKUP LOCATIONS'}</h6>
                      </div>
                        <div className="col-md-3 col-lg-3 ml-1 col-3 px-0 overviewspace">
                          <Select
                            className="lan-select"
                            multi={false}
                            options={Locations}
                            showNewOptionAtTop={false}
                            onChange={(e) => setSelectedLocation(e)}
                            value={selectedLocations}
                            styles={colourStyles}
                          />
                        </div>
                        <div className="col-md-6 col-lg-6 ml-1 col-6 px-0 overviewspace">
                          { (currentTab === 'inbound' || currentTab === 'outbound') &&
                          <div className="col-md-6 col-lg-6 ml-1 col-6 px-0 overviewspace text-center">
                            <Link to={{pathname: '/scanorder', state: { type:currentTab,location:selectedLocations.value }}} className="text-dark"><button className ="scan-btn-scan">{currentTab === 'inbound' ? 'Receive new parcels' : 'Retreive a parcel'}</button></Link>
                          </div> 
                          }
                        </div>
                    </div>
                  </div>
        <hr className="w-100 mt-2"></hr>
        <div className="col-md-12 col-lg-12 col-12 px-0">
              <form onSubmit={handleSearchSubmit}>
            <div>
                {
                  
                    (currentTab === 'inbound'|| currentTab === 'pickedup') &&
                    <div className="row m-auto mt-2">
                      <div className="col-md-4 col-lg-4 col-4 ml-1 mt-3 px-0 overviewspace">
                        <input type="text"  className="form-control search-fields" id="order" name="order" placeholder="Order number" value={search_order} onChange={handleSearchInput}/>
                      </div>
                      <div className="col-md-4 col-lg-4 ml-1 mt-3 col-4 px-0 overviewspace">
                        <input type="text"  className="form-control search-fields" id="user" name="user" placeholder="User name" value={search_user} onChange={handleSearchInput}/>
                      </div>
                      <div className="col-md-1 col-lg-1 col-1 mt-3  ml-2 px-0 pd-3 overviewspace">
                        <button type="submit"   className ="scan-btn">Search</button>
                      </div>
                      <div className="col-md-1 col-lg-1 col-1 mt-3  ml-2 px-0 pd-3 overviewspace">
                        <button type="submit"  onClick={clearSearch} className ="clear-btn">Clear</button>
                      </div>
                    </div>
                  }
                  
                  {
                    (currentTab === 'outbound') &&
                    <div className="row m-auto mt-2"> 
                    <div className="col-md-3 col-lg-3 col-3 ml-1 mt-3 px-0 overviewspace">
                        <input type="text"  className="form-control search-fields" id="order" name="order" placeholder="Order number" value={search_order} onChange={handleSearchInput}/>
                      </div>
                      <div className="col-md-3 col-lg-3 ml-1 mt-3 col-3 px-0 overviewspace">
                        <input type="text"  className="form-control search-fields" id="user" name="user" placeholder="User name" value={search_user} onChange={handleSearchInput}/>
                      </div>
                      <div className="col-md-3 col-lg-3 ml-1 mt-3 col-3 px-0 overviewspace">
                        <input type="text"  className="form-control search-fields" id="rack" name="rack" placeholder="Rack number" value={search_rack} onChange={handleSearchInput}/>
                      </div>
                      <div className="col-md-1 col-lg-1 col-1 mt-3 px-0 pd-3 overviewspace">
                        <button type="submit"   className ="scan-btn">Search</button>
                      </div>
                      <div className="col-md-1 col-lg-1 col-1 mt-3  ml-2 px-0 pd-3 overviewspace">
                        <button type="submit"  onClick={clearSearch} className ="clear-btn">Clear</button>
                      </div>
                    </div>
                  }
             
              </div>
           
       </form>

          </div>

        <div className="col-md-12 collg-12 col-12 px-0">
          {inboundCount !== undefined && outboundCount !== undefined && pickedUpCount !== undefined ? 
            <ul className="orders-menu1">
                    <li className="orders-menu1">
                        <a className={currentTab === 'inbound' ?"btn-active":"btn-tab"} onClick={()=>tabSwitch('inbound')}>{t('INBOUND')+ " "+'('+inboundCount+')' }</a>
                    </li>
                    <li className="orders-menu1">
                        <a className={currentTab === 'outbound' ?"btn-active":"btn-tab"} onClick={()=>tabSwitch('outbound')}>{t('OUTBOUND')+" "+ '('+outboundCount+')'}</a>
                    </li>
                    <li className="orders-menu1">
                        <a className={currentTab === 'pickedup' ?"btn-active":"btn-tab"} onClick={()=>tabSwitch('pickedup')}>{t('PICKED UP')+" "+ '('+pickedUpCount+')'}</a>
                    </li>
                   
            </ul>:
            <ul className="orders-menu1">
            <li className="orders-menu1">
                <a className={currentTab === 'inbound' ?"btn-active":"btn-tab"} onClick={()=>tabSwitch('inbound')}>{t('INBOUND')}</a>
            </li>
            <li className="orders-menu1">
                <a className={currentTab === 'outbound' ?"btn-active":"btn-tab"} onClick={()=>tabSwitch('outbound')}>{t('OUTBOUND')}</a>
            </li>
            <li className="orders-menu1">
                <a className={currentTab === 'pickedup' ?"btn-active":"btn-tab"} onClick={()=>tabSwitch('pickedup')}>{t('PICKED UP')}</a>
            </li>
           
    </ul>}
                <hr className="w-100 mt-2"></hr>
        </div>

        <div>
            {
              currentTab === 'inbound' && 
              <OrdersInTab 
                tab={currentTab}
                orders={orders} 
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                dataLength={orders.length}
                statusCode={statusCode}
                locationID={selectedLocations.value}
              />
            }
            {
              currentTab === 'outbound' &&
              <OrdersInTab 
                tab={currentTab}
                orders={orders} 
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                dataLength={orders.length}
                statusCode={statusCode} 
                locationID={selectedLocations.value}
              />
            }
            {
              currentTab === 'pickedup' &&
              <OrdersInTab 
                tab={currentTab}
                orders={orders} 
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                dataLength={orders.length}
                statusCode={statusCode}
                locationID={selectedLocations.value} 
              />
            }
        </div>
    </section>}
    </div>
);





}



