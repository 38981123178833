import React, { useState, useEffect } from 'react'
import { Apiservice } from 'services/Apiservices'
import { useAuth } from '../../context/context.js';
import * as Reactbootstrap from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { t } from 'translations/Translate';
import './picklocation.css';
import { OCAlert } from '@opuscapita/react-alerts';

const Pickuplocation = (props) => {
  const { userDetails } = useAuth();
  const user_id = userDetails['id'];
  const [locations, setLocations] = useState([])
  const [inputValue, setSearchValue] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [nodata, setNodata] = useState();
  const params = useParams();
  const paramsOrderId = params['orderId']
  const handleChange = event => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    var data = {
      user_id: user_id,
      order_id: paramsOrderId
    }
    Apiservice.service(window.FETCH_PICKUP_LOCATIONS, 'POST', data).then(result => {
      if (result.status === 200) {
        setNodata(result.data.data.length)
        setLocations(result.data.data)
        setSearchResults(result.data.data)
      }
    })
      .catch(error => {
        OCAlert.alertError(t('error while updating'), { timeOut: window.ALERT_ERROR_TIME });
      })
  }, [])

  const setLocation = (data) => {
    let address = {
      title: data.title,
      firstName: '',
      lastName: '',
      address1: data.address_1,
      address2: data.address_2,
      attention: 'pickupLocation',
      city: data.location_city,
      zipCode: data.pincode,
      phone: data.phone,
      label: data.country,
      isStoreLocation: true
    }
    let requestdata = {
      user_id: user_id,
      address: address,
      type: 'pickupAddress',
      orderId: paramsOrderId,
      location_id: data.location_id,

    }
    Apiservice.service(window.ADD_ADDRESS, 'POST', requestdata).then(res => {
      if (res.status === 201) {
        props.selectLocation(res.data.DATA.address)
        localStorage.setItem('pickup', 'pickuplocation')
        fetchCartDetails()
      }
    })
  }
  const fetchCartDetails = () => {
    let userDetails = JSON.parse(localStorage.getItem("user"))
    let data = {
      type: 'myCart',
      user_id: userDetails['id']
    }
    try {
      Apiservice.service("webshopapi/shopv1/addcarts", "POST", data).then(res => {
        if (res.status === 200) {
          props.cartDetails(res.data.DATA.cartDetail)
        }
      })
    } catch (e) {
      if (document.getElementById('loading-icon') !== null) {
        document.getElementById('loading-icon').style.display = 'none';
      }
      console.error("error", e);
    }
  }
  const getFilterOptions = () => {
    if (locations !== undefined && locations !== null) {
      const results = Object.values(locations).filter(ele => {
        let string = ele.title + '' + ele.pincode + '' + ele.address_2 + '' + ele.country
        return string.toLowerCase().includes(inputValue.toLowerCase())
      });
      setSearchResults(results);
    }
  }

  const renderPickupLocations = () => {
    let result = [];
    if (searchResults !== undefined && searchResults !== null) {
      result = Object.values(searchResults).map(ele => {
        return <div className="col-md-12 col-lg-12 col-12 px-0" key={ele.id}>
          <div className="col-md-12 col-lg-12 col-12  addressWrap">
            <div className="row m-auto">
              <div className="col-md-6 col-lg-6 col-12 pl-0">
                <label class="radioWrap mb-0">
                  <input type="radio" name="locations" onChange={event => setLocation(ele)} />
                  <h6>{ele.title}</h6>
                  <span class="checkmark"></span>
                </label>

                <div className="col-md-12 col-lg-12 col-10 pl-4 lightcolor">
                  <div>{ele.address_1}</div>
                  <div>{ele.address_2}</div>
                  <div><span>{ele.pincode}&nbsp;{ele.location_city}</span></div>
                  <div>{ele.country}</div>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-12"></div>
              <div className="col-md-3 col-lg-3 col-12  pr-0 mob-number pl-4">
                <h6>{t('Phone number')}</h6>
                <div className="lightcolor" style={{ fontSize: '14px' }}>
                  {ele.phone}
                </div>
              </div>
            </div>
          </div>
        </div>

      })
      return result;
    }
  }
  return (
    /*<div className ="container float-left mr-0 mt-10">*/
    <section>
      {nodata === 0 && <div className="row mb-5 justify-content-md-center">{t('No pickuplocations found')}</div>}
      {nodata !== 0 && <div className="row mb-5 ml-auto mr-auto">
        <input className="col-md-6 search_location mr-3 form-control" value={inputValue} onChange={handleChange} type="text" placeholder={t('Search location')} />
        <Reactbootstrap.Button className="search-button" onClick={getFilterOptions}>{t('SEARCH')}</Reactbootstrap.Button>
      </div>}

      {renderPickupLocations()}
    </section>
    /*</div>*/
  )
}
export default Pickuplocation;
