import React, { Component } from 'react';
// import React,{useEffect, useState} from 'react';
// import { useHistory } from 'react-router-dom'
// import EmailForm from './EmailForm.js'
import './Login.css';
import { Link, NavLink } from "react-router-dom";
import { t } from 'translations/Translate'

export default class LoginNav extends Component{

    constructor(props){
      super(props)

    }

    render() {

    return (
<div className="mt-5" >

    <p className="textcolor mb-4 Login_text_fontsize">{t('Login')}</p>

    <nav className="navbar p-0">

        <NavLink
            id="email_nav"
            activeClassName="navbar_link--active "
            className="navbar_link btn col-6 text-left block mb-2"
            to="/login/email"
            >{t('Email')}
        </NavLink><br></br>
        <NavLink
            id="number_nav"
            activeClassName="navbar_link--active btn-danger"
            className="navbar_link btn col-6 text-left block mb-2"
            to="/login/mobile"
            >{t('Phone number')}
        </NavLink><br></br>

    </nav>

    </div>
)}
}
