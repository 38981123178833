import React, {useState, useEffect } from 'react'
import { Apiservice } from '../../services/Apiservices';
import {useAuth} from '../../context/context.js';
import {useParams}  from "react-router-dom"
import OrderSummary from 'components/mycart/OrderSummary.js';
import { Link } from "react-router-dom";
import  home_img from '../commoncss/images/home.png';
import { t } from '../../translations/Translate'
import { useHistory } from 'react-router-dom'
import { OCAlert } from '@opuscapita/react-alerts';

const Transport = () =>{
  const {userDetails} = useAuth();
  const user_id = userDetails['id'];
  const params = useParams();
  const orderId = params['orderId']
  const [lineItems, setLineItems] = useState();
  const [orderDetails, setorderDetails] = useState();
  const [shippingOptions,setShippingOptions] = useState();
  const [service,setService] = useState('standard');
  const [option,setOption] = useState([]);
  const [count ,setCount] = useState();
  const history = useHistory();
  const [loading,setLoad] = useState(false);
  const [unauthorised,setUnauthorised] = useState(false);
  useEffect(()=>{
    let data = {
      type:'myCart',
      user_id : user_id
    }
    try{
      Apiservice.service("webshopapi/shopv1/addcarts" , "POST", data ).then(res =>{
        if(res.status === 200){
            setLineItems(res.data.DATA.cartDetail['lineItems'])
            setorderDetails(res.data.DATA.cartDetail)
        }
         })
      }catch(e){
        if(document.getElementById('loading-icon')!== null){
          document.getElementById('loading-icon').style.display = 'none';
        }
        console.error("error",e);
      }
    const request = {
      "user_id": user_id,
      "order_id": orderId,
    }
    Apiservice.service(window.TRANSPORT_SERVICES,"POST",request).then(res=>{
      if(res.status === 201){
        if(res.data.DATA.STATUS === 404){
          setUnauthorised(true)
          OCAlert.alertError(res.data.DATA.MESSAGE, { timeOut: window.ALERT_ERROR_TIME });
          // setTimeout(function () { history.push('/myprofile/myorders') }, 2000);
        }else {
        setLoad(true)
        if(res.data.DATA['standard'].length !== 0 || res.data.DATA['express'].length !== 0){
       setShippingOptions(res.data.DATA)
      if(res.data.DATA['standard'].length === 0 && res.data.DATA['express'].length === 1){
         setService('express')
         updateShippingMethod(res.data.DATA['express'][0]['shippingMethodId'],res.data.DATA['express'][0]['carrierId'],res.data.DATA['express'][0]['serviceId'])
         setLoad(false)
         // setOption(res.data.DATA['express'][0]['shippingMethodId'])
         // setCount(res.data.DATA['express'].length)
      }
      if(res.data.DATA['express'].length === 0 && res.data.DATA['standard'].length === 1){
         setService('standard')
         updateShippingMethod(res.data.DATA['standard'][0]['shippingMethodId'],res.data.DATA['standard'][0]['carrierId'],res.data.DATA['standard'][0]['serviceId'])
         setLoad(false)
         // setOption(res.data.DATA['standard'][0]['shippingMethodId'])
         // setCount(res.data.DATA['standard'].length)
      }
    }else {
      setCount(0)
      if(window.location.href.includes('transportoptions')){
          return history.push('/billingaddress/'+orderId)
      }else{
        return history.push('/order-overview/'+orderId)
      }

    }
  }
  }
    })
    },[])
    let currency ='' ,length = 0;
    if (orderDetails) {
      currency = orderDetails.totalAsCurrency[orderDetails.totalAsCurrency.length - 1]
    }
    if(lineItems){
      lineItems.map(ele =>{
         return length = length +ele.qty;
      })
    }
    const selectService = (e,type) =>{
      setService(type)
    }
    const selectOption = (e) =>{
            setOption(e)
    }
    const goBack =() =>{
      return history.push('/billingaddress/'+orderId)
    }

    const getVatPercentage = (e) => {
        let vatPercentage = ''
        orderDetails.lineItems[0].adjustments.filter(ele =>{
            if (ele.type === 'tax') {
                vatPercentage = (ele.description)
            }
        })
        return vatPercentage
    }

    const updateShippingMethod = (shippingMethodId,carrierId,serviceId) =>{
      let data = {
        user_id: user_id,
        order_id:orderId,
        shippingMethodId: shippingMethodId,
        carrierId :carrierId,
        serviceId:serviceId,
        type:'updateShippingMethod',

      }
      Apiservice.service(window.ADDCART, 'POST', data).then(res => {
        if (res.data.STATUS === 201) {
          if(window.location.href.includes('transportoptions')){
            return history.push('/billingaddress/'+orderId)
        }else{
          return history.push('/order-overview/'+orderId)
        }
          // return history.push('/order-overview/'+orderId)
        }
      })
        .catch(function (error) {
          if (document.getElementById('loading-icon') !== null) {
            document.getElementById('loading-icon').style.display = 'none';
          }
          OCAlert.alertError(error.message, { timeOut: window.ALERT_ERROR_TIME });
        });
    }
    const displayShippingOptions =(data) =>{
        if(data.length === 0){
           return <div className ="row mb-5 justify-content-md-center">No data found</div>
        }
      return <div className="col-md-12 col-lg-12 col-12 px-0">
              <section className="col-md-12 col-lg-12 col-12  addressWrap">
              <div className ="row ml-auto mr-auto mb-3 bg-white p-3">
              <div class="col-md-4 col-lg-4 col-4 pl-0">
              <h6 className="mb-3 textcolor">{t('Carriers')}</h6>
              </div>
              <div class="col-md-4 col-lg-4 col-4 pl-0">
              <h6 className="mb-2  textcolor">{t('Cost')}</h6>
              </div>
              <div class="col-md-4 col-lg-4 col-4 pl-0">
              <h6 className="mb-2  textcolor">{t('Transist time')}</h6>
              </div>
              </div>
                  {data !== undefined  && data.map(ele =>{
                    return <div className="row m-auto">
                        <div class="col-md-4 col-lg-4 col-4 pl-0">
                         <label className = "radioWrap mb-0"><input type ="radio" name ="options" onChange = {(e) => selectOption(ele)}/>{ele.carrier}
                          <span class="checkmark" ></span>
                         </label>
                        </div>
                        <div class="col-md-4 col-lg-4 col-4 pl-0">
                        <label  style={{fontSize: '14px'}} className = "mb-0 cost-euro">{ele.cost.slice(-1)}{ele.cost.slice(0,-1)} &nbsp;</label>
                        </div>
                        <div class="col-md-4 col-lg-4 col-4 pl-0">
                        {ele.TransistDays !== ""|| ele.TransistDays !== null ?<label style={{fontSize: '14px'}} className = "mb-0">{ele.TransistDays}</label>:
                        <label style={{fontSize: '14px'}} className = "mb-0">N/A</label> }
                        {ele.TransistDays === null && <label style={{fontSize: '14px'}} className = "mb-0">N/A</label>}
                        </div>
                    </div>
                  })}
              </section>
              </div>
    }
    return (
      //console.log(orderDetails.totalTaxAsCurrency),
      <div className='desktop-right-content'>
      <div className="layout-space fixed-layout">
      {unauthorised &&<h5 className = "text-center mt-3 textcolor">{t('Unauthorized to access this page')}</h5>}

      {!unauthorised && !loading &&<h5 className = "text-center mt-3 textcolor">{t('Please wait fetching data...')}</h5>}
            {loading &&<nav aria-label="breadcrumb" className="col-12 col-md-12 col-lg-12 px-0 mt-4 pt-1">
                   <ol className="breadcrumb bg-transparent pl-0">
                     <li className=""><p class="backButton leftAdjustmentBreadscrumallPage" onClick={goBack}> </p></li>
                     <li className="breadcrumb-item home"><Link to="/"><img  src={home_img} alt=""/></Link></li>
                     {(window.location.pathname.includes('transport')) && <li className="breadcrumb-item active" aria-current="page">{t('Transport')}</li>}
                   </ol>
             </nav>}
             {!unauthorised&& loading &&<div className="col-md-12 col-lg-12 col-12 mobile-shipping-space">
                <div className="row m-auto">
                    <div className="col-md-12 col-lg-9 mb-3 col-12 pl-0 mob-right-space tab-right-space-shipping">
                          <div className="col-md-12 col-lg-12 col-12 px-0 ">
                                <div className="row m-auto">
                                     <div className="col-md-6 col-lg-6 col-6 pl-0 tab-border">
                                         <div className="tab-inner">
                                         <label className = "radioWrap mb-0">
                                         <input type ="radio" name ="address" value = "standard" onChange = {(e) => selectService(e,'standard')} checked = {service === 'standard'}/>
                                         <h6 className="mb-2 text-uppercase textcolor">{t('STANDARD')}</h6>
                                         <span class="checkmark" ></span>
                                         </label>
                                         </div>
                                     </div>
                                     <div className="col-md-6 col-lg-6 col-6 pl-0 tab-border">
                                         <div className="tab-inner">
                                         <label className = "radioWrap mb-0">
                                         <input type ="radio" name ="address" value = "express" onChange = {(e) => selectService(e,'express')} checked = {service === 'express'}/>
                                         <h6 className="mb-2 text-uppercase textcolor">{t('EXPRESS')}</h6>
                                         <span class="checkmark" ></span>
                                         </label>
                                         </div>
                                     </div>
                                </div>
                          </div>

                          {service === 'standard' && shippingOptions !== undefined && displayShippingOptions(shippingOptions['standard'])}
                          {service === 'express' && shippingOptions !== undefined && displayShippingOptions(shippingOptions['express'])}
                          {count === 0 && <div className ="row mb-5 justify-content-md-center">{t('No data found')}</div>}

                    </div>
                    <div className="col-md-12 col-lg-3 mb-3 col-12 px-0 mob-order">
                        {orderDetails !== undefined &&

                              <OrderSummary
                               Length={length}
                               totalAsCurrency={orderDetails.totalAsCurrency}
			       totalTaxAsCurrency = {orderDetails.totalTaxAsCurrency}
                               storedItemTotalAsCurrency={orderDetails.storedItemTotalAsCurrency}
                               currency={currency}
                               totalDiscountAsCurrency={orderDetails.totalDiscountAsCurrency}
                               totalDiscount={orderDetails.totalDiscount}
                               Total={orderDetails.itemSubtotalAsCurrency}
                               totalShippingCostAsCurrency={orderDetails.totalShippingCostAsCurrency}
                               shipments = {option}
                               optionsCount ={count}
                               // VAT ={true}
                               totalTax = {orderDetails.totalTax}
                               vatPercentage = {getVatPercentage()}
                               orderid={orderId} />
                             }
                    </div>
                </div>
             </div>}

      </div>
      </div>
    )
}
export default Transport;
