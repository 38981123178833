import React, { Component } from 'react'
import * as Reactbootstrap from 'react-bootstrap'
import Address from 'components/Address/Addaddress.js'
import './address.css'
import { Apiservice } from 'services/Apiservices'
import { Link } from "react-router-dom";
import './address.css'
import OrderSummary from 'components/mycart/OrderSummary.js';
import Pickuplocation from 'components/Address/Pickuplocations.js';
import { t } from '../../translations/Translate'
import home_img from '../commoncss/images/home.png';
import '../commoncss/common.css';
import { OCAlert } from '@opuscapita/react-alerts';
import { letterSpacing } from '@material-ui/system';


export default class ManageAddress extends Component {

  constructor(props) {
    super(props)
    this.state = {
      userAddressDetails: [],
      selectAddress: window.location.search.includes('pickuplocation') || localStorage.getItem('pickup') ? 'pickup' : 'shipping',
      primaryShippingAddressId: '',
      locations: [],
      loading: true,
      expand: false,
      checked: false,
      primaryBillingAddressId: '',
      primaryShippingAddress: [],
      primaryBillingAddress: [],
      update: '',
      orderDetails: [],
      lineItems: [],
      propsChecked: '',
      propsBillingId: '',
      pickupLocation: [],
      setValue: false,
      addressInfo: [],
      lengthOfPickup: '',
      unauthorised: false,
      pickupLocationslist: [],
    }
    this.switchTabs = this.switchTabs.bind(this);
    this.updateShippingAddress = this.updateShippingAddress.bind(this);
    this.updateBillingAddress = this.updateBillingAddress.bind(this);
    this.expandAddress = this.expandAddress.bind(this);
  }
  componentDidMount() {
    this.getPickupLocationDetails()
    this.getAddress()
    this.getOrderSummary()
    if (window.location.href.includes('orderoverview')) {
      this.getAddressData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.update !== this.state.update || prevState.selectAddress !== this.state.selectAddress) {
      // this.getAddress()
      // this.getOrderSummary()
      // this.getAddressData()

    }
  }

  getPickupLocationDetails = () => {
    let userDetails = JSON.parse(localStorage.getItem("user"))
    var data = {
      user_id: userDetails['id'],
      order_id: this.props.match.params.orderId,
    }
    Apiservice.service(window.FETCH_PICKUP_LOCATIONS, 'POST', data).then(result => {

      this.setState({
        lengthOfPickup: result.data.data.length
      })
      if (result.data.data.length === 0) {
        this.setState({
          selectAddress: 'shipping'
        })
      }
    })

  }

  getAddress = async () => {
    let userDetails = JSON.parse(localStorage.getItem("user"))
    let addressInfo = [];
    let data = {
      type: 'userAddress',
      user_id: userDetails['id']
    }
    try {
      await Apiservice.service(window.ADD_ADDRESS, 'POST', data).then(res => {
        if (res.status === 201) {
          addressInfo = res.data.DATA.address.filter(ele => {
            if (ele.id === res.data.DATA.primaryShippingAddressId && !window.location.pathname.includes('billingaddress') && !window.location.search.includes('orderoverview')) {

              this.updateShippingAddress(ele, 'shipping')
              localStorage.removeItem('pickup');
            }
            if (ele.id === res.data.DATA.primaryBillingAddressId) {
              this.updateBillingAddress(ele, 'billing')
            }
          })
          if (!window.location.search.includes('orderoverview') && res.data.DATA.address.length > 0) {
            this.setState({
              primaryShippingAddressId: res.data.DATA.primaryShippingAddressId,
              primaryBillingAddressId: !localStorage.getItem('pickup') ? res.data.DATA.primaryBillingAddressId : '',
              userAddressDetails: res.data.DATA.address,
              addressInfo: addressInfo,
              loading: false
            })
          } else {
            this.setState({
              userAddressDetails: res.data.DATA.address,
              loading: false
            })
          }
        }
      })
    } catch (e) {
      if (document.getElementById('loading-icon') !== null) {
        document.getElementById('loading-icon').style.display = 'none';
      }
      console.error("errorrrrr", e);
    }
  }

  getOrderSummary = () => {
    let userDetails = JSON.parse(localStorage.getItem("user"))
    let data = {
      type: 'myCart',
      user_id: userDetails['id']
    }
    try {
      Apiservice.service("webshopapi/shopv1/addcarts", "POST", data).then(res => {
        if (res.status === 200) {
          this.setState({
            orderDetails: res.data.DATA.cartDetail,
            lineItems: res.data.DATA.cartDetail['lineItems'],
          })
        }
      })
    } catch (e) {
      if (document.getElementById('loading-icon') !== null) {
        document.getElementById('loading-icon').style.display = 'none';
      }
      console.error("error", e);
    }
  }

  getAddressData = async () => {
    let userDetails = JSON.parse(localStorage.getItem("user"))
    let data = {
      type: 'orderOverview',
      user_id: userDetails['id']
    }
    try {
      await Apiservice.service("webshopapi/shopv1/addcarts", "POST", data).then(res => {
        if (res.status === 200) {

          if (res.data.DATA.shippingAddress !== null || res.data.DATA.billingAddress !== null) {
            this.setState({
              addressInfo: res.data.DATA.shippingAddress,
            })
            if (window.location.href.includes('orderoverview')) {
              this.setState({
                primaryShippingAddressId: res.data.DATA.shippingAddress['id'],
                primaryBillingAddressId: res.data.DATA.billingAddress['id']
              })
            }
          }
        }

      })
    } catch (e) {
      if (document.getElementById('loading-icon') !== null) {
        document.getElementById('loading-icon').style.display = 'none';
      }
    }
  }

  switchTabs(e, type) {
    this.setState(
      {
        selectAddress: e.target.value,
        expand: false,
      }
    )
    let data = {
      id: this.state.primaryShippingAddressId
    };
    if (type === 'shipping') { 
      this.updateShippingAddress(data)
    }
  }


  async updateShippingAddress(e) {
    let userDetails = JSON.parse(localStorage.getItem("user"))
    let data = {
      user_id: userDetails['id'],
      type: 'updateAddress',
      orderId: this.props.match.params.orderId,
      shippingAddressId: e.id
    }
    await Apiservice.service('/webshopapi/shopv1/addcarts', 'POST', data).then(res => {

      if (res.status === 201) {
        if (res.data.DATA.STATUS === 404) {
          this.setState({
            unauthorised: true
          })
          OCAlert.alertError(res.data.DATA.MESSAGE, { timeOut: window.ALERT_ERROR_TIME });
        }
        else {
          this.setState({
            addressInfo: res.data.DATA.data
          })
          // this.getAddressData()
          localStorage.removeItem('pickup')
          this.getOrderSummary()
        }
      }
      if (res.data.STATUS === 203) {
        OCAlert.closeAlerts();
        OCAlert.alertError(t('Order is freezed'), { timeOut: window.ALERT_ERROR_TIME });
      }
    })
      .catch(function (error) {
        if (document.getElementById('loading-icon') !== null) {
          document.getElementById('loading-icon').style.display = 'none';
        }
        OCAlert.alertError(error.message, { timeOut: window.ALERT_ERROR_TIME });
      });

    this.setState(
      { primaryShippingAddressId: e.id })
  }


  async updateBillingAddress(e) {

    let userDetails = JSON.parse(localStorage.getItem("user"))

    let data = {
      user_id: userDetails['id'],
      type: 'updateAddress',
      orderId: this.props.match.params.orderId,
      billingAddressId: e.id
    }
    Apiservice.service('/webshopapi/shopv1/addcarts', 'POST', data).then(res => {
      if (res.status === 201) {
        if (res.data.DATA.STATUS === 404) {
          this.setState({
            unauthorised: true
          })
          OCAlert.alertError(res.data.DATA.MESSAGE, { timeOut: window.ALERT_ERROR_TIME });
        } else {
          this.getAddressData()
          this.setState(
            {
              primaryBillingAddressId: e.id,
            })
        }
      }
    })
      .catch(function (error) {
        if (document.getElementById('loading-icon') !== null) {
          document.getElementById('loading-icon').style.display = 'none';
        }
      });

  }
  expandAddress(e) {
    this.setState(
      {
        expand: true
      }, () => setTimeout(function () { document.getElementById('addaddress').scrollIntoView() }, 100)
    )
  }
  renderAddress = (billing) => {
    const { userAddressDetails } = this.state
    const orderId = this.props.match.params.orderId;
    let result = userAddressDetails.map(ele => {
      if (ele.firstName !== null && ele.firstName.length > 0) {
        return <div key={ele.id}>
          <div className="col-md-12 col-lg-12 col-12 px-0 ">
            <section className="col-md-12 col-lg-12 col-12  addressWrap">
              <div className="row m-auto">
                <div className="col-md-3 col-lg-3 col-2  pr-0 mob-calltoaction mob-total mobileview">
                  {billing && <Link className="actionIcons editIcon mob-edit desktop-shipping-edit " title={t('Edit')} to={('/editAddress/' + ele.id + '/' + orderId + '?from=' + 'billing')}>edit</Link>}
                  {!billing && <Link className="actionIcons editIcon mob-edit desktop-shipping-edit" title={t('Edit')} to={('/editAddress/' + ele.id + '/' + orderId + '?from=' + 'shipping')}>edit</Link>}
                  {window.location.href.includes('profile') && <a class="actionIcons deleteIcon desk-dele-icon" title={t('Delete')} href="">dele</a>}
                  {window.location.href.includes('profile') && <div class="deleteAlign"><a class="actionIcons deleteIcon" title={t('Delete')} href="">dele</a></div>}
                </div>
                <div className="col-md-3 col-lg-3 col-12  pr-0 mob-calltoaction mob-shipping mobileview ">
                  {!billing && <Link className="actionIcons editIcon mob-edit marginAlignment" title={t('Edit')} to={('/editAddress/' + ele.id + '/' + orderId + '?from=' + 'shipping')}>edit</Link>}
                  {billing && <Link className="actionIcons editIcon mob-edit marginAlignment" title={t('Edit')} to={('/editAddress/' + ele.id + '/' + orderId + '?from=' + 'billing')}>edit</Link>}
                </div>
                <div class="col-md-6 col-lg-6 col-12 pl-0">
                  {billing && <label className="radioWrap mb-0"><input type="radio" name="billingAddress" checked={ele.id === this.state.primaryBillingAddressId} onChange={(e) => this.updateBillingAddress(ele)} /> <h6>{ele.title}</h6>
                    <div class="col-md-12 col-lg-12 col-10 px-0 lightcolor mob-line-height">
                      <div> {ele.firstName + ' ' + ele.lastName}</div>
                      <div> {ele.businessName}</div>
                      <div><span>{ele.custom1}</span>&nbsp;<span>{ele.custom2}</span> &nbsp;{(ele.custom3 !== '' || null) && <span>{t('Box')}: {ele.custom3}</span>}</div>
                      <div><span>{ele.zipCode}&nbsp;{ele.city} </span></div>
                      <div>{ele.countryText}</div>
                    </div>

                    <span class="checkmark"></span> </label>}
                  {!billing && <label className="radioWrap mb-0"><input type="radio" name="shippingAddress" checked={ele.id === this.state.primaryShippingAddressId} onChange={(e) => this.updateShippingAddress(ele)} />  <h6>{ele.title}</h6>
                    <div class="col-md-12 col-lg-12 col-10 px-0 lightcolor mob-line-height">
                      <div> {ele.firstName + ' ' + ele.lastName}</div>
                      <div> {ele.businessName}</div>
                      <div><span>{ele.custom1}</span>&nbsp;<span>{ele.custom2}</span> &nbsp;{(ele.custom3 !== '' || null) && <span>{t('Box')}: {ele.custom3}</span>}</div>
                      <div><span>{ele.zipCode}&nbsp;{ele.city} </span></div>
                      <div>{ele.countryText}</div>
                    </div>
                    <span class="checkmark"></span> </label>}
                </div>
                <div className="col-md-3 col-lg-3 col-10">
                  <div className="pl-2">
                    <h6 className="mb-1">{t('Phone number')}</h6>
                    <div>{ele.phone}</div>
                  </div>
                  {ele.businessTaxId &&
                    <div className="mt-4 pt-2 pl-2">
                      <h6 className="mb-1">{t('VAT number')}</h6>
                      <div>{ele.businessTaxId}</div>
                    </div>}
                </div>
                <div className="col-md-3 col-lg-3 col-2  pr-0 mob-calltoaction mob-total laptopview">
                  {billing && <Link className="actionIcons editIcon mob-edit desktop-shipping-edit " title={t('Edit')} to={('/editAddress/' + ele.id + '/' + orderId + '?from=' + 'billing')}>edit</Link>}
                  {!billing && <Link className="actionIcons editIcon mob-edit desktop-shipping-edit" title={t('Edit')} to={('/editAddress/' + ele.id + '/' + orderId + '?from=' + 'shipping')}>edit</Link>}

                  {window.location.href.includes('profile') && <a class="actionIcons deleteIcon desk-dele-icon" title={t('Delete')} href="">dele</a>}
                  {window.location.href.includes('profile') && <div class="deleteAlign"><a class="actionIcons deleteIcon" title={t('Delete')} href="">dele</a></div>}
                </div>
                <div className="col-md-3 col-lg-3 col-12  pr-0 mob-calltoaction mob-shipping laptopview">
                  {!billing && <Link className="actionIcons editIcon mob-edit " title={t('Edit')} to={('/editAddress/' + ele.id + '/' + orderId + '?from=' + 'shipping')}>edit</Link>}
                  {billing && <Link className="actionIcons editIcon mob-edit " title={t('Edit')} to={('/editAddress/' + ele.id + '/' + orderId + '?from=' + 'billing')}>edit</Link>}
                </div>
              </div>
            </section>
          </div>

        </div>
      }
    })
    return result;
  }

  selectLocation(data) {
    this.setState({
      addressInfo: data,
      setValue: true
    })
  }
  cartDetails(cart) {
    this.setState({
      orderDetails: cart
    })
  }
  goBack = () => {
    if (window.location.href.includes('billingaddress')) {
      return this.props.history.push('/shippingaddress/' + this.props.match.params.orderId)
    }
    if (window.location.href.includes('shippingaddress')) {
      return this.props.history.push('/mycart')
    }
  }
  updateComponent(e, address = '', check = '') {
    this.setState({
      update: e,
      expand: false,
    }, () => setTimeout(function () { document.getElementById('manage').scrollIntoView() }, 100))
  }

  getVatPercentage() {
    let vatPercentage = ''
    this.state.orderDetails.lineItems[0].adjustments.filter(ele => {
      if (ele.type === 'tax') {
        vatPercentage = (ele.description)
      }
    })
    return vatPercentage
  }

  render() {

    let length = 0;
    this.state.lineItems.map(ele => {
      return length = length + ele.qty;
    })
    const Length = length
    const orderId = this.props.match.params.orderId;
    const billing = window.location.href.includes('billingaddress') ? true : false
    const { orderDetails } = this.state
    let currency;
    if (orderDetails.length !== 0) {
      currency = orderDetails.totalAsCurrency[orderDetails.totalAsCurrency.length - 1]
    }

    return (
      <div className='desktop-right-content'>
        <div className="layout-space fixed-layout">
          {this.state.unauthorised && <h5 className="text-center mt-3 textcolor">{t('Unauthorized to access this page')}</h5>}
          {!this.state.unauthorised && <nav aria-label="breadcrumb" className="col-12 col-md-12 col-lg-12 px-0 mt-4 pt-1">
            <ol className="breadcrumb bg-transparent pl-0">
              <li className=""><p class="backButton leftAdjustmentBreadscrumallPage " onClick={this.goBack}> </p></li>
              <li className="breadcrumb-item home"><Link to="/"><img src={home_img} alt="" /></Link></li>
              {(window.location.pathname.includes('shippingaddress')) && <li className="breadcrumb-item active" aria-current="page">{t('Shipping address')}</li>}
              {(window.location.pathname.includes('billingaddress')) && <li className="breadcrumb-item active" aria-current="page">{t('Billing address')}</li>}
            </ol>
          </nav>}
          {!this.state.unauthorised && <div className="col-md-12 col-lg-12 col-12 mobile-shipping-space" id="manage">
            <div className="row m-auto">
              <div className="col-md-12 col-lg-9 mb-3 col-12 pl-0 mob-right-space tab-right-space-shipping">
                <div className="col-md-12 col-lg-12 col-12 px-0">
                  <div className="row m-auto">
                    {this.state.lengthOfPickup === 0 && !billing && <label className=" row justify-content-md-center text-color pl-3">{t('SHIPPING ADDRESS')} </label>}
                    {this.state.lengthOfPickup !== 0 && !billing && <div className="col-md-6 col-lg-6 col-6 pl-0 tab-border"><div className="tab-inner"><label className="radioWrap mb-0"><input type="radio" name="address" value="shipping" onChange={(e) => this.switchTabs(e, 'shipping')} checked={this.state.selectAddress === 'shipping'} /> <h6 className="mb-2 text-uppercase textcolor">{t('SHIPPING INFORMATION')}</h6><span class="checkmark" ></span></label> </div></div>}
                    {this.state.lengthOfPickup !== 0 && !billing && <div className="col-md-6 col-lg-6 col-6 tab-border"><div className="tab-inner"> <label className="radioWrap mb-0"><input type="radio" name="address" value="pickup" onChange={(e) => this.switchTabs(e, 'pickup')} checked={this.state.selectAddress === 'pickup'} /> <h6 className="mb-2 text-uppercase textcolor">{t('PICKUP LOCATION')}</h6><span class="checkmark" ></span> </label></div> </div>}
                    {billing && <label className=" row justify-content-md-center text-color pl-3">{t('BILLING ADDRESS')} </label>}
                  </div>
                </div>
                {this.state.loading && <h5 className="textcolor">{t('Loading....')}</h5>}
                {!this.state.loading && this.state.selectAddress === 'shipping' && this.renderAddress(billing)}
                {this.state.selectAddress === 'pickup' && !billing && <Pickuplocation selectLocation={this.selectLocation.bind(this)} cartDetails={this.cartDetails.bind(this)} />}
                {!this.state.loading && this.state.selectAddress === 'pickup' && billing && this.renderAddress(billing)}
                {(this.state.primaryShippingAddressId || this.state.pickupLocation) &&
                  <div className="col-md-12 col-lg-12 col-12 px-0 ">
                    {!billing && this.state.selectAddress === 'shipping' && <Reactbootstrap.Button id="addaddress" className="col-md-12 col-lg-12 col-12 textcolor inneraddress" onClick={(e) => this.expandAddress(e)}>+ {t('ADD SHIPPING ADDRESS')}</Reactbootstrap.Button>}
                    {billing === true && <Reactbootstrap.Button id="addaddress" className="col-md-12 col-lg-12 col-12 textcolor inneraddress" onClick={(e) => this.expandAddress(e)}><span class="plusAddress">+</span> {t('ADD BILLING ADDRESS')}</Reactbootstrap.Button>}
                    {!billing && this.state.userAddressDetails.length !== 0 && <Link to={("/billingaddress/" + orderId)} className="btn btn-light col-12 text-center inneraddress mb-2" >{t('SELECT OR ADD BILLING ADDRESS')}</Link>}
                  </div>}
                {this.state.expand &&
                  <div className="col-md-12 col-lg-12 col-12 px-0 " style={{ clear: 'both' }}>
                    <Address updateComponent={this.updateComponent.bind(this)} length={this.state.userAddressDetails.length} />
                  </div>
                }
              </div>
              <div className="col-md-12 col-lg-3 mb-3 col-12 px-0 mob-right-space tab-landscape-space">
                {orderDetails.length !== 0 && <OrderSummary
                  Length={Length}
                  totalAsCurrency={orderDetails.totalAsCurrency}
                  Total={orderDetails.itemSubtotalAsCurrency}
                  currency={currency}
                  totalShippingCostAsCurrency={orderDetails.totalShippingCostAsCurrency}
                  storedItemTotalAsCurrency={orderDetails.storedItemTotalAsCurrency}
                  totalDiscountAsCurrency={orderDetails.totalDiscountAsCurrency}
                  totalDiscount={orderDetails.totalDiscount}
                  storedTotalTaxIncludedAsCurrency={9}
                  totalPriceAsCurrency={20}
                  isPickupLocation={this.state.addressInfo['attention']}
                  length={this.state.userAddressDetails.length}
                  shipppingId={this.state.primaryShippingAddressId}
                  billingId={this.state.primaryBillingAddressId}
                  pickup={this.state.pickupLocation}
                  setValue={this.state.setValue}
                  totalTax={orderDetails.totalTax}
                  totalTaxAsCurrency={orderDetails.totalTaxAsCurrency}
                  vatPercentage={this.getVatPercentage()}
                />}

                {billing && this.state.addressInfo !== undefined && <div>{(this.state.addressInfo['attention'] === 'pickupLocation') ? <h6 className="textcolor mb-2">{t('PICKUP LOCATION')}</h6> :
                  <h6 className="textcolor mb-2">{t('SHIPPING INFORMATION')}</h6>}
                  {this.state.addressInfo !== undefined && <div className="col-md-12 col-lg-12 col-12 px-0 bg-white p-3 mb-5">
                    {this.state.addressInfo['title'] !== undefined && <h5>{this.state.addressInfo['title']}</h5>}
                    {this.state.addressInfo['firstName'] !== undefined && this.state.addressInfo['lastName'] !== undefined && <div className="mb-2" style={{ fontSize: '15px', color: '#000' }}>{this.state.addressInfo['firstName'] + " " + this.state.addressInfo['lastName']}</div>}
                    {this.state.addressInfo['attention'] !== undefined && !this.state.loading && <div className="col-md-12 col-12 col-lg-12 px-0 mob-line-height" style={{ color: '#707070' }}>
                      {this.state.addressInfo['attention'] === 'pickupLocation' ? <span>{this.state.addressInfo['address1']} <br></br> {this.state.addressInfo['address2']}</span> :
                        <span><span>{this.state.addressInfo['custom1'] + ' ' + this.state.addressInfo['custom2'] + ' '}</span><span>{(this.state.addressInfo['custom3'] !== "" || null) ? t('Box') + ': ' + this.state.addressInfo['custom3'] : null}</span></span>}
                      <br></br>
                      <span>{this.state.addressInfo['zipCode']}</span>&nbsp;
                      <span>{this.state.addressInfo['city']}</span><br></br>
                      {this.state.addressInfo['attention'] === 'pickupLocation' ?
                        <span>{this.state.addressInfo['label']}</span> : <span>{this.state.addressInfo['countryText']}</span>
                      }
                    </div>}
                  </div>}
                </div>}

              </div>
            </div>
          </div>}
        </div>
      </div>
    )
  }

}
