import React, { } from "react";
import './SaleLayout.css';
import { OCAlert } from '@opuscapita/react-alerts';
import Countdown, {zeroPad} from 'react-countdown';
import { useHistory } from 'react-router-dom'
import {Apiservice} from 'services/Apiservices';
import '../commoncss/common.css';
import {  isBrowser, isMobile, isTablet, isIPhone13,  isIPod13} from 'react-device-detect';
import { t } from 'translations/Translate';
import moment from 'moment';

/**
 * [SaleLayout function that return jsx with layout]
 *
 *
 */



const SaleLayout =({sale,saleIds,onSaleStart,saleType })=>{


  let ribbonStyle = '40px solid ' + sale.ribbon_background_color;
  let bannerStyleBefore = sale.banner_background_color;

  const history = useHistory();
  const handleBannerClick =(sale)=>{
      if (!sale.salePeriod.includes('upcoming-sale')) {
          history.push({
              pathname: '/products/sale/' + sale.title + '/' + sale.id,
          })
      }
  }
  /**
   * [ Up coming sale layout]
   *
   */
if(saleType === 'upComing'){
  return  getUpcomingSaleLayout(sale,saleIds,onSaleStart);
}else{
        return<>
        <div key = {sale.id} className = "col-lg-4 col-md-6 col-12 col-sm-6 sales-wrap"
        style ={{cursor:sale.salePeriod.includes('upcoming-sale') ? 'default':'pointer'}}
        onClick ={()=>handleBannerClick(sale)}
        >
            <div className = "col-12  mobile-ribbon" style = {{backgroundColor:sale.banner_background_color}} >
                <h5 className = "m-0">{sale.ribbon_text}</h5>
            </div>
                <div className = "col-12 mobile-salesBanner p-0">
                  <div className="img-wrap" style={{ backgroundImage: `url(${sale.image})` }}></div>
                    {/* <img  className = "sale_banner_image" alt="" data-src ={sale.image} /> */}
                </div>
                <div className = "col-12  sale-description-mobile" style = {{backgroundColor:sale.ribbon_background_color}}>
                    {getCountdown(sale,saleIds,onSaleStart)}
                </div>
        </div>
        </>
  }
}

const getSaleTimeFromUTC =(UTC,format = null)=>{

var local = moment.utc(UTC).local().format();
if(format === null){
    return moment(local).format('MM/DD/YYYY h:mm:ss a');
}
return moment(local).format('DD MMMM YYYY h:mm A');
              }
const handleNotifyClick =(saleid,saletitle)=>{
  var today = new Date();
  let data = {
    user_id : JSON.parse(localStorage.getItem('user')).id,
    sale_id : saleid,
    title : saletitle,
    subscribed_date : today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear(),
  }
  Apiservice.service(window.NOTIFY_USER, 'POST', data).then(res =>{

    if(res.status === 200){
        OCAlert.alertSuccess(t('You have been subscribed successfully'), {timeOut:window.ALERT_SUCCESS_TIME});
        setTimeout(function(){window.location.reload() }, 3000);

      if(res.data === 'user already subscribed'){
      }
      else{
      }
    }
  })
}
const handleUnsubscribeClick =(saleid,saletitle)=>{
  let data = {
    user_id : JSON.parse(localStorage.getItem('user')).id,
    sale_id : saleid,
    title : saletitle,
  }
  Apiservice.service(window.UNSUBSCRIBE_USER, 'POST', data).then(res =>{
      OCAlert.alertSuccess(t('You have been unsubscribed successfully'), {timeOut: window.ALERT_ERROR_TIME});
     setTimeout(function(){window.location.reload() }, 3000);

  })
}



const renderer = ({ days,hours, minutes, seconds }) => (
  (!isNaN(days)&&!isNaN(hours)&&!isNaN(minutes))?
  <span className = "end-of-sale-font" >
    {t('End of sale')}: {days} {days>1?t('Days'):t('Day')} {zeroPad(hours)} {hours>1?t('Hours'):t('Hour')} {zeroPad(minutes)} {minutes>1?t('Minutes'):t('Minute')}
  </span>:<span>{t('Sale until out-of-stock')}</span>
);
const getCountdown =(sale,saleIds,onSaleStart)=>{
    return <>
              <div className ="sale-start sale-description">
              {/* <span>Day Hour Min Sec</span><br></br> */}
              <Countdown
                date={getSaleTimeFromUTC(sale.saleTo)}
                onComplete ={(e)=>window.location.reload()}
                renderer = {renderer}
                />
                </div>
            </>
}

const saleStartCountdown =(sale,saleIds,onSaleStart)=>{
    return <>
             <div className ="d-none">
              {/* <span>Day Hour Min Sec</span><br></br> */}
              <Countdown
                date={getSaleTimeFromUTC(sale.saleFrom)}
                onComplete ={(e)=>onSaleStart(sale.id)}
                />
             </div>
            </>
}

const getSubcribe=(sale, saleIds)=>{
    var check = ''
 if(saleIds !== undefined){
   Object.entries(saleIds).map(([key, value]) => {
      if(key == sale.id){
       check = value
      }
 })
}
   return <>
               <div className="subs-button-wrap">

               {check===true
                   ? <button className="buttonstyle subs-button" style ={{cursor:'pointer'}}><a onClick ={()=>handleUnsubscribeClick(sale.id,sale.title)}>{t('Unsubscribe')}</a></button>
                   : <button className="buttonstyle subs-button" style ={{cursor:'pointer'}}><a onClick ={()=>handleNotifyClick(sale.id,sale.title)}>{t('Subscribe')} {check}</a></button>
               }
               </div>
           </>
}

const getContent =(sale, saleIds)=>{
  let  content  = sale.banner_description;
  let from = sale.saleFrom != null ? getSaleTimeFromUTC(sale.saleFrom,'en-GB'):" ";
  let to = sale.saleTo != null ? getSaleTimeFromUTC(sale.saleTo,'en-GB') :"";
       // content = from
       // content = content !== null ? content.replace("!to", to):"";
       return t('From')+ ' ' + from
}

const loadRibbonStyle = ( css) => {
    // document.querySelector('.homeribbon').style.setProperty("--c", "40px solid #d20c14");
    // let css = "#12 .ribbon::after {color: red;}; #11 .ribbon::after {color: green;};"
    // css = css.replace(/\[id\]/g,1235)
    // css = css.replace(/\[color\]/g,'red')
    var style = document.querySelector('style[id="lastinbody"]') || document.createElement('style');
     style.id = 'lastinbody';
     style.type = 'text/css';
     if (style.styleSheet){
       style.styleSheet.cssText = css;
     } else {
       style.appendChild(document.createTextNode(css));
     }
     document.querySelector('head').appendChild(style);
     // let styleelement = '<style>'+ css + '</style>';
     // document.getElementsByTagName('head')[0].append(styleelement);
}
const getUpcomingSaleLayout = (sale,saleIds,onSaleStart)=>{


  //const singlePerRow = 'row col-md-12 col-sm-12 mb-3 p-0';
  //const twoPerRow = 'row col-md-5 col-sm-5 mb-2 p-0 mr-3'
        return(
            sale.map(sale=>{
                return(
                    <div key = {sale.id} className = "col-lg-4 col-sm-6 col-md-6 col-12 sales-wrap " style ={{cursor:'default'}}>
                            <div className = "col-12  mobile-ribbon upcoming-ribbon-color">
                                <h5 className = "m-0">{sale.ribbon_text}</h5>
                            </div>
                            <div className = "col-12 mobile-salesBanner p-0">
                                {getSubcribe(sale, saleIds)}
                                <div className="overlay"></div>
                                <div className="img-wrap" style={{ backgroundImage: `url(${sale.image})` }}></div>
                                {/* <img  className = "sale_banner_image"  alt ="" data-src ={sale.image} /> */}
                            </div>
                            <div className = "col-12  sale-description-mobile upcoming-ribbon-color">
                                <div className="upcomin-announce" dangerouslySetInnerHTML={{ __html: getContent(sale, saleIds)}}>
                                </div>
                            </div>
                            {saleStartCountdown(sale,saleIds,onSaleStart)}
                    </div>

                )
            })
        )
    //else {
    //     return<>
    //     {sale.map(sale=>{
    //         return(
    //             <div key = {sale.id} className = "col-md-12 col-lg-12 col-12 px-0 upcoming-sale-height tab-upcoming sales-upcoming" style ={{cursor:'default'}}>
    //             <div className="row m-auto">
    //             <div className="blur-overlay  row m-auto col-md-12 col-lg-12 col-12 pl-0">
    //             <div class="col-md-9 col-10">
    //             <div className="blur-overlay salesBanner">
    //             {/* <div className="sales-bg-img" style={{backgroundImage: `url(${sale.image})`}}></div> */}
    //             <div className="sales-img-object">
    //             <img className = "sale_banner_image"  data-src ={sale.image}/>
    //             </div>
    //             </div>
    //             </div>
    //             <div class="col-md-3 col-2" id={'_' + sale.id}>
    //             <div className = "SalesOffer trapezoid-upcoming">
    //             <div className="saleswrap">
    //             <div className="upcomin-announce text-blur">
    //             <h6>{sale.ribbon_text}</h6>
    //             <h6>{getCountdown(sale,saleIds,onSaleStart)}</h6>
    //             </div>
    //             </div>
    //             </div>
    //             </div>
    //             {loadRibbonStyle('@media screen and (min-width: 1024px) { #_' + sale.id + ' .trapezoid-upcoming::before { background: ' + sale.banner_background_color + ';} ' + '#_' + sale.id + ' .trapezoid-upcoming::after { border-bottom: ' + ' 139px solid ' + sale.banner_background_color + ';}}')}
    //             {loadRibbonStyle('@media screen and (min-width: 0px) and (max-width: 767px) { #_' + sale.id + ' .trapezoid-upcoming::after { background: ' + sale.banner_background_color + ';} ' + '#_' + sale.id + ' .trapezoid-upcoming::before { border-bottom: ' + ' 120px solid ' + sale.banner_background_color + ';}}')}
    //             {loadRibbonStyle('@media screen and (min-width: 768px) and (max-width: 1023px) { #_' + sale.id + ' .trapezoid-upcoming::before { background: ' + sale.banner_background_color + ';} ' + '#_' + sale.id + ' .trapezoid-upcoming::after { border-bottom: ' + ' 149px solid ' + sale.banner_background_color + ';}}')}
    //             </div>
    //             </div>
    //             </div>
    //         )
    //     })}</>
    // }


}
export default SaleLayout;
