import React, {useState,useEffect } from 'react'
import * as Reactbootstrap from 'react-bootstrap'
import { OCAlert } from '@opuscapita/react-alerts';
import './address.css'
import {useParams}  from "react-router-dom"
import {Apiservice} from 'services/Apiservices'
import {useAuth} from '../../context/context.js';
import { useHistory } from 'react-router-dom'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { t } from '../../translations/Translate'

 const Address = (props) =>{
   // alert(props.length)
     const [address , setData] = useState({title: '',firstName: '',lastName:'',custom1 : '',custom2:'',custom3:'',zipCode:'',city:'',countryId:'',phone:'',address1:'',businessName:'',address3:'',businessTaxId:''})
     const handleChange = (event) => {
           if(event.target !== undefined){
           setData({...address, [event.target.name]: event.target.value})
         }
         else {
           setData({...address, ['phone']: event})
         }
           setDisabled(false)
         }
     // const billingId = window.location.href.includes('billingaddress') ? true :false
     // const shipppingId = window.location.href.includes('shippingaddress') ? true :false
     const [countryList ,setCountry] = useState([])
     const {userDetails} = useAuth();
     const user_id = userDetails['id'];
     const params = useParams();
     const paramsId = params['id']
     const orderId = params['orderId']
     const history = useHistory();
     const [disabled,setDisabled] = useState('')
     /**
      * [saveAddress description]
      * storing address into table
      * @return {[type]} [description]
      */
      function saveAddress (){
         setDisabled(true)
          var data = {
             user_id  :user_id,
             address : address,
             type    :(paramsId) ? 'updateAddress' :'newAddress',
             addressId : (paramsId) ? paramsId :'',
             orderId:(orderId) ? orderId :'',
             primaryBillingAddress:  (props.length === 0&& !localStorage.getItem('pickup')) ? true:false,
             primaryShippingAddress: (props.length === 0 && !localStorage.getItem('pickup')) ? true:false
          }
          if(validation()){
                Apiservice.service (window.ADD_ADDRESS ,'POST',data).then(res =>{
                    if(res.status === 201){
                        if(window.location.search.includes('orderoverview') && window.location.pathname.includes('shippingaddress')){
                            window.location = '/shippingaddress/'+orderId+'?from='+'orderoverview'
                        }
                        if(window.location.search.includes('orderoverview') && window.location.pathname.includes('billingaddress')){
                            window.location = '/billingaddress/'+orderId+'?from='+'orderoverview'
                        }
                        if(paramsId && !orderId){
                            return history.push('/myprofile/addressbook')
                        }
                        if(!paramsId){
                            props.updateComponent(Math.random())
                        }
                        if(window.location.href.includes('addressbook')){
                          return history.push('/myprofile/addressbook')
                        }
                        if(window.location.search.includes('billing') || window.location.href.includes('billingaddress')){
                          history.push('/billingaddress/'+orderId)
                        }
                        else{
                           history.push('/shippingaddress/'+orderId)
                        }
                      }
                })
            }else {
               return OCAlert.alertError(t('Please fill all mandatory fields'), { timeOut: window.ALERT_WARNING_TIME });
            }
     }
     /**
      * [cancelAddress description]
      *
      * @return {[type]} [description]
      */

      const cancelAddress = () =>{
          if(window.location.search.includes('orderoverview')) {
              return history.push('/order-overview/'+orderId)
          }
          if(paramsId && !orderId){
              return history.push('/myprofile/addressbook')
            }
          if(!paramsId){
             props.updateComponent(Math.random(),JSON.parse(localStorage.getItem('shippingAddress')))
           }
           if(window.location.search.includes('billing') || window.location.pathname.includes('billing')){
             return history.push('/billingaddress/'+orderId)
           }
           if(window.location.pathname.includes('addressbook')){
             return history.push('/myprofile/addressbook')
           }
           else {
              history.push('/shippingaddress/'+orderId)
           }
      }
      /**
       * [useEffect description]
       *
       * @param  {[type]} Apiservice [description]
       *
       * @return {[type]}            [description]
       */

      useEffect (() =>{
         Apiservice.service(window.ADD_ADDRESS ,'GET').then(res =>{
             setCountry(res.data.data)
        })
        var user = {
           user_id:user_id
        }
    //     if(!paramsId){
    //     Apiservice.service('webshopapi/shopv1/getphones','POST',user).then(res =>{
    //       setData({...address, ['phone']: res.data.data})
    //     })
    //   }
        if(paramsId){
            var data ={
                user_id:user_id,
                type:'getAddressById',
                addressId:paramsId
            }
           Apiservice.service(window.ADD_ADDRESS,'POST',data).then(res=>{
              setData(res.data.DATA.address)
           })
           }
      },[])
      /**
       * [validation description]
       *
       * @return {[type]} [description]
       */

     const validation =() =>{
         if(address.phone === ''||address.firstName === '' || address.lastName === ''||address.custom2 === ''||address.zipCode === ''||address.custom1 === ''|| address.countryId === "" ||address.city === ''){
            return false;
         }
         return true
     }

 return(
     <div className={window.location.href.includes('myprofile') ? '' : (window.location.href.includes('editAddress')&& (window.location.href.includes('shipping') || window.location.href.includes('billing'))) ? "desktop-right-content" :""}>
    <div className={props.from === 'profile'?"" : (window.location.href.includes('editAddress')&& (window.location.href.includes('shipping') || window.location.href.includes('billing'))? "layout-space fixed-layout" :"")}>

    <section className={props.from === 'profile'? "col-md-12 col-lg-12 col-12 px-0 edit-addresses mb-5" : "col-md-12 col-lg-12 col-12  mt-4 pt-4 edit-addresses mb-5"} style={{clear: 'both'}}>
        <h6 className="mb-0">{t('Add a new address')}</h6>
        <hr className="w-100 mt-2"/>
        <div className="col-md-12 col-xl-4 col-lg-4 col-12 pl-0  mob-titles tab-space tab-bottom-space">
            <label className = "">{t('Title')}</label>
            <input
                type  = "text"
                name = "title"
                className="form-control input-class"
                onChange = {handleChange}
                value = {address.title}
            />
        </div>
        <div className="col-md-12 col-lg-12 col-12 px-0 mt-3 ">
            <div className="row m-auto">
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 pl-0 mobs-space tab-space tab-bottom-space">
                    <label className = "">{t('Company name')}</label>
                    <input
                            type  = "text"
                            name = "businessName"
                            className="form-control input-class"
                            onChange = {handleChange}
                            value = {address.businessName}
                    />
                </div>
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 mob-top-space mobs-space tab-space tab-bottom-space" >
                    <label className = "alignmentofVat">{t('VAT number')}</label>
                    <input
                            type  = "text"
                            name = "businessTaxId"
                            className="form-control input-class"
                            onChange = {handleChange}
                            value = {address.businessTaxId}
                    />
                </div>
            </div>
        </div>
        <div className="col-md-12 col-lg-12 col-12 px-0 mt-3">
            <div className="row m-auto">
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 pl-0 mobs-space tab-space tab-bottom-space" >
                    <label className = "">{t('First name')}</label><span style = {{'color' :'#d20c14'}}>*</span>
                    <input
                               type  = "text"
                               name = "firstName"
                               className="form-control input-class"
                               onChange = {handleChange}
                               value = {address.firstName}/>
                </div>
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 mob-top-space mobs-space tab-space tab-bottom-space">
                    <label className = "alignmentofLastname">{t('Last name')}</label><span style = {{'color' :'#d20c14'}}>*</span>
                    <input
                                type  = "text"
                                name = "lastName"
                                className="form-control input-class"
                                onChange = {handleChange}
                                value = {address.lastName}/>
                </div>
            </div>
        </div>
        <div className="col-md-12 col-lg-12 col-12 px-0 mt-3">
            <div className="row m-auto">
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 pl-0 mobs-space tab-space tab-bottom-space">
                    <label className = "">{t('Street')}</label><span style = {{'color' :'#d20c14'}}>*</span>
                    <input
                                type  = "text"
                                name = "custom1"
                                className="form-control input-class"
                                onChange = {handleChange}
                                value = {address.custom1}/>
                </div>
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 mob-top-space mobs-space tab-space tab-bottom-space">
                    <label className = "alignmentofNumber">{t('Number')}</label><span style = {{'color' :'#d20c14'}}>*</span>
                    <input
                                type  = "text"
                                name = "custom2"
                                className="form-control input-class"
                                onChange = {handleChange}
                                value = {address.custom2}/>
                </div>
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 pr-0 mob-top-space mobs-space tab-space tab-bottom-space">
                    <label className = "alignmentofBox">{t('Box')}</label>
                    <input
                                type  = "text"
                                name = "custom3"
                                className="form-control input-class"
                                onChange = {handleChange}
                                value = {address.custom3}/>
                </div>
            </div>
        </div>
        <div className="col-md-12 col-lg-12 col-12 px-0 mt-3">
            <div className="col-md-12 col-xl-8 col-lg-8 col-12 pl-0 tab-space tab-bottom-space">
                <label className = "">{t('Additional address')}</label>
                <input
                    type  = "text"
                    name="address1"
                    maxlength="255"
                    className="form-control input-class forMobileWidthAdjustment"
                    onChange = {handleChange}
                    value = {address.address1}/>
            </div>
        </div>
        <div className="col-md-12 col-lg-12 col-12 px-0 mt-3">
            <div className="row m-auto">
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 pl-0 mobs-space tab-space tab-bottom-space">
                    <label className = "">{t('Postal code')}</label><span style = {{'color' :'#d20c14'}}>*</span>
                    <input
                                type  = "text"
                                name = "zipCode"
                                className="form-control input-class"
                                onChange = {handleChange}
                                value = {address.zipCode}/>
                </div>
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 mob-top-space mobs-space tab-space tab-bottom-space">
                    <label className = "alignmentofCity">{t('City')}</label><span style = {{'color' :'#d20c14'}}>*</span>
                    <input
                                type  = "text"
                                name = "city"
                                className="form-control input-class"
                                onChange = {handleChange}
                                value = {address.city}/>
                </div>
            </div>
        </div>
        <div className="col-md-12 col-lg-12 col-12 px-0 mt-3">
            <div className="row m-auto">
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 mb-2 pl-0 mobs-space tab-space tab-bottom-space">
                    <label className = "">{t('Country')}</label><span style = {{'color' :'#d20c14'}}>*</span>
                    <Reactbootstrap.FormControl className="" as="select"
                        name="countryId"
                        value={address.countryId}
                        onChange={handleChange}>
                            <option style={{ display: "none" }}>{t('Select')}</option>
                        {Object.values(countryList).map(ele => <option key={ele.value} value={ele.value}>{ele.label}</option>)}
                        </Reactbootstrap.FormControl>
                </div>
                <div className="col-md-12 col-xl-4 col-lg-4 col-12 mb-2 mobs-space tab-space phone-Number tab-bottom-space">
                    <label className = "alignmentofPhone">{t('Phone number')}<span style = {{'color' :'#d20c14'}}>*</span></label>
                    {/*<div className = "row">
                         <input
                            type  = "text"
                            name = "phone"
                            className="form-control input-class"
                            onChange ={handleChange}
                            value = {address.phone}/>
                     </div>*/}
                     <PhoneInput
                        inputProps={{
                         name: 'phone',
                         required: true,
                       }}
                         // onlyCountries={['in', 'fr', 'us','be','nl','de','ae','gb','tr']}
                         country='be'
                         onlyCountries={['fr', 'us','be','nl','de','ae','gb','tr','in']}
                         countryCodeEditable={false}
                         value={address.phone}
                         onChange={handleChange}
                         // inputClass="padding-left-numbers phone_input_width"
                         placeholder="Enter phone number"
                     />
                </div>
            </div>
        </div>
        {/* {!checkedValue&&!billingId && !window.location.search.includes('billing') && !window.location.search.includes('profile')&& <div className = "col-md-12 col-lg-12 col-12 px-0 mt-3">
            <label class="checkWrap">
                <span>{t('Use the shipping address as the billing address')}</span>
                <input type ="checkbox" onClick = {e => setChecked(!checked)}/>
                <span class="checkmark"></span>
            </label>
              </div> }
              {checkedValue &&!billingId && !window.location.search.includes('billing') && !window.location.search.includes('profile')&& <div className = "col-md-12 col-lg-12 col-12 px-0 mt-3">
                    <input type ="checkbox" checked = {paramsId === checkedValue} onClick = {e => setChecked(!checked)}/> <span>{t('Use the shipping address as the billing address')}</span>
                </div> } */}
        <div className="col-md-12 col-lg-12 col-12  mt-4 mb-5 px-0 mobs-space tab-space tab-bottom-space">
            <div className="row m-auto justify-content-end">
                <Reactbootstrap.Button className ="ml-2 action-btn btn-left btns" onClick = {cancelAddress}>{t('CANCEL')}</Reactbootstrap.Button>
                <Reactbootstrap.Button className ="ml-2 action-btn btn-right btns" disabled ={disabled} onClick = {saveAddress}>{t('SAVE')}</Reactbootstrap.Button>
            </div>
        </div>
            {/*{!checkedValue&&!billingId && !window.location.search.includes('billing') && !window.location.search.includes('profile')&& <div className = "row">
                  <input type ="checkbox" onClick = {e => setChecked(!checked)}/> <span>{t('Use the shipping address as the billing address')}</span>
              </div> }
              {checkedValue &&!billingId && !window.location.search.includes('billing') && !window.location.search.includes('profile')&& <div className = "row">
                    <input type ="checkbox" disabled = {true} checked = {paramsId === checkedValue} onClick = {e => setChecked(!checked)}/> <span>{t('Use the shipping address as the billing address')}</span>
                </div> } */}
              {/*<div className ="row button-div md-6">
                   <Reactbootstrap.Button className ="button-class-cancel" onClick = {cancelAddress}>{t('CANCEL')}</Reactbootstrap.Button>
                   <Reactbootstrap.Button className ="button-class" disabled ={disabled} onClick = {saveAddress}>{t('SAVE')}</Reactbootstrap.Button>
              </div>*/}
    {/*</div>*/}

    </section>
    </div>
    </div>
 )
}
export default Address;
