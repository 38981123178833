import React, {}  from 'react'
import './MyCart.css';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom"
import { useAuth } from '../../context/context.js';
import { Apiservice } from 'services/Apiservices';
import { t } from '../../translations/Translate';
import { OCAlert } from '@opuscapita/react-alerts';
import { useHistory } from 'react-router-dom'

function OrderSummary({ Length,
  totalAsCurrency,
  storedItemTotalAsCurrency,
  totalDiscountAsCurrency,
  totalShippingCostAsCurrency,
  itemTotalAsCurrency,
  stock,
  totalDiscount,
  Total,
  VAT,
  vatPercentage,
  shippingCost,
  shippingAddress,
  billingAddress,
  shipppingId,
  billingId,
  orderid,
  currency,
  pickup,
  setValue,
  shipments,
  titleChange,
  isPickupLocation,
  getData,
  optionsCount,
  freeShipping,
  amountToAddFreeShipping,
  saleStatus,
  saleStatusCheck,
  qtyCheck,
  qtyGreaterOrLesser,
  qtyofVariant,
  hasUnlimitedStock,
  length,
  totalTax,
  totalTaxAsCurrency,
  Promo
 })
 {
  const history = useHistory();
  const params = useParams();
  const paramsOrderId = params['orderId']
  const { userDetails } = useAuth();
  const user_id = userDetails['id'];
  const freeShippingReminderString = t("Add $amount$ more to avail free shipping");
  let amount = 0;
  const proceed = (e) => {
    if(window.location.href.includes('orderoverview')){
      return history.push('/order-overview/'+paramsOrderId)
    }
    if(length >0 || setValue){
   if((window.location.href.includes('shippingaddress') && validateChecked('shippingAddress')) || (window.location.href.includes('shippingaddress') && setValue && validateChecked('locations'))){
      history.push('/billingaddress/'+paramsOrderId)
  }else if (window.location.href.includes('billingaddress') && !validateChecked('billingAddress')) {
      OCAlert.closeAlerts();
      return OCAlert.alertError(t('Please select billing address'), { timeOut: window.ALERT_ERROR_TIME });
    }else if (isPickupLocation === 'pickupLocation' && length >0) {
      history.push('/order-overview/'+paramsOrderId)
  } else if(window.location.href.includes('shippingaddress') && !validateChecked('shippingAddress')){
        OCAlert.closeAlerts();
        return OCAlert.alertError(t('Please select shipping address'), { timeOut: window.ALERT_ERROR_TIME });
    }else if (window.location.pathname.includes('billingaddress')&& setValue &&length ===0 && !validateChecked('billingAddress')) {
        OCAlert.closeAlerts();
        return OCAlert.alertError(t('Please select billing address'), { timeOut: window.ALERT_ERROR_TIME });
      } else {
      history.push('/transport/'+paramsOrderId)
    }
  }else {
    OCAlert.closeAlerts();
    return OCAlert.alertError(t('Please add address to continue...'), { timeOut: window.ALERT_ERROR_TIME });
  }

}

 const validateChecked = (value) => {
     const radioNodes = document.querySelectorAll('input[name='+value+']')
     let checkedValue = false
       for (const node of radioNodes) {
           if (node.checked) {
               checkedValue = true;
               break;
           }
       }
       return checkedValue
 }

 const proceedToOverview = () =>{
   if(optionsCount === 0){
     return history.push('/order-overview/'+paramsOrderId)
   }
   else if (window.location.pathname.includes('transport') && shipments.shippingMethodId !== undefined) {
     updateShippingMethod()
   }else {
     OCAlert.closeAlerts();
     return OCAlert.alertError(t('Please select shipping method'), { timeOut: window.ALERT_ERROR_TIME });

   }
 }
  const proceedToPay = () => {
    if(stock === true){
      return
    }
    let data = {
      "user_id": user_id,
      "orderId": paramsOrderId,
    }
    Apiservice.service(window.PROCEED_TO_CHECKOUT, 'POST', data).then(res => {
      if (res.data.STATUS === 201) {

        let checkout_url = res.data.DATA.checkoutUrl
        window.location = checkout_url;
      }
      if(res.status===204){
          OCAlert.closeAlerts();
          OCAlert.alertError(t('Payment already done for this order'), {timeOut: window.ALERT_ERROR_TIME});
          setTimeout(function () { history.push('/myprofile/myorders') }, 2000);
      }
      if(res.status===206){
          OCAlert.closeAlerts();
          OCAlert.alertError(t('Cart has out of stock products'), {timeOut: window.ALERT_ERROR_TIME});
              getData();
      }
      if(res.status === 207){
        OCAlert.closeAlerts();
        OCAlert.alertError(res.data.DATA.message, {timeOut: window.ALERT_ERROR_TIME});
        setTimeout(function () { history.push('/myprofile/myorders') }, 2000);
      }
    })
      .catch(function (error) {
        if (document.getElementById('loading-icon') !== null) {
          document.getElementById('loading-icon').style.display = 'none';
        }
        if (error.response.data.STATUS === 400 && error.response.data.DATA.message === 'Order is paid already') {
            OCAlert.alertError(t('Payment already done for this order'), { timeOut: window.ALERT_ERROR_TIME });
            setTimeout(function () { history.push('/myprofile/myorders') }, 2000);
        }
        else {
            OCAlert.alertError(error.response.data.DATA.message[0], { timeOut: window.ALERT_ERROR_TIME });
        }
      });
  }
  const updateShippingMethod = () =>{
    let data = {
      user_id: user_id,
      order_id:paramsOrderId,
      shippingMethodId: shipments.shippingMethodId,
      carrierId :shipments.carrierId,
      serviceId:shipments.serviceId,
      type:'updateShippingMethod',

    }
    Apiservice.service(window.ADDCART, 'POST', data).then(res => {
      if (res.data.STATUS === 201) {
        return history.push('/order-overview/'+paramsOrderId)
      }
      if(res.status === 204){
        OCAlert.alertError('Unable to update shipment method', { timeOut: window.ALERT_ERROR_TIME });

      }
    })
      .catch(function (error) {
        if (document.getElementById('loading-icon') !== null) {
          document.getElementById('loading-icon').style.display = 'none';
        }
        OCAlert.alertError(error.message, { timeOut: window.ALERT_ERROR_TIME });
      });
  }


  return (
    <div className="card mb-4 border-0 " style={{borderRadius: '0', marginTop: '-10px'}}>
      <div className="card-body p-3">
        <h6 className="mb-0 order-title font-weight-bold">{t('ORDER SUMMARY')}</h6>
        <hr className="mt-2 mb-2" />
        <ul className="list-group list-group-flush orderSummary">
          <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                {t('Price')} ({Length} { Length > 1 ? t('items') : t('item')})
                        <span className="currencY">{currency + "" + Total.toString().replace(currency, "")}</span>
          </li>
          {Promo !== null &&<li className={totalDiscount === 0? "hide-class" : "list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0 textcolor"}>
            {t('Promo')}
            {totalDiscountAsCurrency=totalDiscountAsCurrency.toString().replace("-", ""),<span className="currencY">{"-"+currency + "" + totalDiscountAsCurrency.toString().replace(currency, "")}</span>}
          </li>}
          {totalTax > 0 && <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
            <div>
              {t('VAT')} ({vatPercentage})
            </div>
             <span className="currencY">{currency + "" + totalTaxAsCurrency.toString().replace(currency, "")}</span>
          </li>}
          <li className={totalShippingCostAsCurrency === undefined ? "hide-class" : titleChange === 1 ?"list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0 textcolor":"hide-class"}>
            {t('Shipping charge')}
            {totalShippingCostAsCurrency &&<span className="currencY">{currency + "" + totalShippingCostAsCurrency.toString().replace(currency, "")}</span>}
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 ">
            <div>
              <strong>{t('Total')}</strong>
            </div>
            {storedItemTotalAsCurrency=(titleChange === 1?undefined:storedItemTotalAsCurrency),<span className={storedItemTotalAsCurrency !== undefined ?"hide-class":""}><strong>{currency + "" +totalAsCurrency.toString().replace(currency, "")}</strong></span>}
            {storedItemTotalAsCurrency && <span className={titleChange === 1?"hide-class":"currencY"}><strong>{currency + "" +(itemTotalAsCurrency === undefined ? storedItemTotalAsCurrency : itemTotalAsCurrency).toString().replace(currency, "")}</strong></span>}
          </li>
        </ul>
        <div className="text-center mb-3">
          <Link to="/" className="btn btn-secondary summary-action btn-block">{t('CONTINUE SHOPPING')}</Link>
        </div>
                  {(VAT === true && window.location.href.includes('order-overview') === false) ? <div className="text-center">{stock === true || saleStatus==="expired" || (qtyGreaterOrLesser==="wrong" && hasUnlimitedStock===false) ?<Link className="btn btn-danger summary-action shipping-btn btn-block text-center disabled" to="#">{t('PROCEED TO CHECKOUT')}</Link>:<Link className="btn btn-danger summary-action shipping-btn btn-block text-center" to={("shippingaddress/" + orderid)}>{t('PROCEED TO CHECKOUT')}</Link>}</div> :
          <button type="button" className="btn btn-danger summary-action shipping-btn btn-block waves-effect waves-light"  disabled = {stock && window.location.href.includes('order-overview')}  onClick={window.location.href.includes('order-overview') ? proceedToPay : (window.location.pathname.includes('transport') ?proceedToOverview :proceed)}>{window.location.href.includes('order-overview') ? t('PROCEED TO PAY') : t('PROCEED TO CHECKOUT')}</button>
        }
        {freeShipping && <div className = "textcolor mt-3 font-weight-bold"><h6>{freeShippingReminderString.replace("$amount$",currency + "" + amountToAddFreeShipping.toString().replace(currency, ""))}</h6></div>}
      </div>
    </div>
  );
}

export default OrderSummary;
