import {Apiservice} from '../services/Apiservices'

export const Bootstrap = {
  loadTranslations
};
/**
 * [loadTranslations description]
 *
 * @return {[type]} [description]
 */
function loadTranslations(){
  Apiservice.service(window.FETCH_TRANSLATIONS,'GET') // if string not exists, then store that in table
    .then(result => {
      localStorage.setItem("translations", JSON.stringify(result.data));
    }).catch(error=>{
      if(document.getElementById('loading-icon')!== null){
        document.getElementById('loading-icon').style.display = 'none';
      }
      console.log(error);
    })
}
