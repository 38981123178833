
import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Apiservice} from 'services/Apiservices';
import PhoneInput from 'react-phone-input-2';
import UpdateMyProfile from './UpdateMyProfile';
import { t } from '../../translations/Translate';
import { useHistory } from 'react-router-dom';

export default function MyAccount() {

  let data = {
  user_id : JSON.parse(localStorage.getItem('user')).id,
  user_grp : JSON.parse(localStorage.getItem('user')).group_id,
  user_role : JSON.parse(localStorage.getItem('user')).user_role,
  };
    const [ApiData, setApiData] = useState({});
    const [ShowEdit, setShowEdit] = useState(false);

    useEffect(() => {
        Apiservice.service(window.USER_PROFILE+data.user_id, "GET").then(res => {
            if (res.status === 200) {
              setApiData(res.data);
            }

        }).catch(e=>{
          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
        })
      }, []);
   const history = useHistory();
   const UpdateMyProfile = () =>{
    history.push({
        pathname: '/myprofile/updateMyProfile'
    })
   };

  return (
    <div className="col-md-12 col-lg-12 col-12 px-0 ">
     <div className="row col-md-12 col-lg-12 col-12 titlewrap align-items-center">
        <h6 className="mb-0 page-title">{t('MY PROFILE')}</h6>

        <svg width="1em" title = "Edit" height="1em" onClick = {()=>UpdateMyProfile()} viewBox="0 0 16 16" class="bi bi-pencil ml-auto" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path title = "Edit" fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                </svg>
     </div>
     <hr className="w-100 mt-3"></hr>
        <form  className="customInputstyle col-md-12 col-lg-9 col-12 px-0 profile-form">
            <div className="row m-auto">
            <div class="form-group col-md-6 col-lg-6 col-12 pl-0 mobs-space">
                <label>{t('First name')}</label>
                <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                <input
                        type="text"
                        className="form-control"
                        id="fname"
                        defaultValue = {ApiData.firstName}
                        required
                        readOnly
                        aria-describedby="emailHelp"
                        placeholder={t("First name")}
                        autoFocus
                    />
            </div>
            <div class="form-group col-md-6 col-lg-6 col-12 mobs-space">
                <label>{t('Last name')}</label>
                <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                <input
                        type="text"
                        name="password"
                        id="lname"
                        readOnly
                        defaultValue = {ApiData.lastName}
                        className="form-control"
                        placeholder={t("Last name")}
                    />
            </div>
            <div class="form-group col-md-6 col-lg-6 col-12 pl-0 mobs-space">
                <label>{t('Email')}</label>
                <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                <input
                        type="text"
                        className="form-control"
                        id="fname"
                        readOnly
                        placeholder="Example@gmail.com"
                        defaultValue = {ApiData.email}
                    />
            </div>
            <div class="form-group col-md-6 col-lg-6 col-12 mobs-space">
                <label>{t('Phone number')} <span style={{color: 'rgb(210, 12, 20)'}}>*</span></label>

                <PhoneInput
                    inputProps={{
                        name: 'phone',
                        required: true,
                    }}
                    onlyCountries={['fr', 'us','be','nl','de','ae','gb','tr','in']}
                    country='be'
                    countryCodeEditable={false}
                    value= {(ApiData.workPhoneNumber === null || ApiData.workPhoneNumber === "") ? ((ApiData.personalPhoneNumber === null || ApiData.personalPhoneNumber === "") ? "" : ApiData.personalPhoneNumber) : ApiData.workPhoneNumber}
                    disabled={true}
                    inputClass="padding-left-numbers phone_input_width disabled-color"
                    placeholder="Enter phone number"
                />
            </div>
                <div class="form-group col-md-6 col-lg-6 col-12 pl-0 mobs-space">
                    <label>{t('Language')}</label>
                    <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                    <select className="form-control " disabled value = {ApiData.webLang}>
                            <option value = 'English'>{t('English')}</option>
                            <option value = 'Dutch'>{t('Dutch')}</option>
                            <option value = 'French'>{t('French')}</option>
                        </select>
                </div>
                <div className="form-group col-md-6 col-lg-6 col-12"></div>
            </div>
        </form>
    </div>

  );


}
