import React, { Component } from 'react'
import * as Reactbootstrap from 'react-bootstrap'
import { Apiservice } from 'services/Apiservices'
import './Popup.css';
// import './up-arrow.svg';
// import './down-arrow.svg';
// import uparrow from './up-arrow.svg';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { t } from '../../translations/Translate';
import { Modal } from "react-bootstrap";
import { OCAlert } from '@opuscapita/react-alerts';
import downarrow from './down-arrow.svg';
import uparrow from './up-arrow.svg';
import {withRouter} from 'react-router-dom'
class Popup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showData: [],
      showPopup: true,
      key: '',
      checkedBrands: [],
      checkedCategories: [],
      checkedSizes: [],
      checkedPrice: [],
      checkedGender: [],
      selectedGender: '',
      filtersize: false,
      shoesize: false,
      filterData: [],
      loading: true,
      showBrands:false,
      showCategories:false,
      showSizes:false,
      showPrices:false,
      showGenders:false,
      expandFilter: false,
      selectedSizeAndCategories: []
    }
    this.handleCheck = this.handleCheck.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.expandSize = this.expandSize.bind(this);
    this.expandShoe = this.expandShoe.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  componentDidMount() {
    if(window.location.href.includes('products/sale')||window.location.href.includes('/products/overview/sale')){
      var href = window.location.href.split('/');
      if (href[6].includes('?')) {
        href = href[6].split('?')[0]
      } else {
        href = href[6]
      }

    if((localStorage.getItem('Filters') === undefined || localStorage.getItem('Filters') === null)){
      this.getFilters(href);
    }else {
      if(localStorage.getItem('Filters') !== undefined &&localStorage.getItem('saleId') !== undefined && (localStorage.getItem('saleId') !== href)){
        this.getFilters(href);
       }else{
         this.setState({
             filterData:JSON.parse(localStorage.getItem('Filters')),
             loading:false
         })
      }
    }
  }else {
    if(localStorage.getItem('GlobalFilters')=== undefined || localStorage.getItem('GlobalFilters') === null){
       this.getGlobalFilters();
    }else {
      if(localStorage.getItem('GlobalFilters') !== undefined){
         this.setState({
             filterData:JSON.parse(localStorage.getItem('GlobalFilters')),
             loading:false
         })
      }
    }

  }
  this.getDataToPrefill();

}

  getDataToPrefill = () => {
    if (window.location.search !== '') {
      let query = new URLSearchParams(window.location.search)
      this.setState({
        checkedBrands: query.get("brands") !== null ? query.get("brands").split(",") : [],
        checkedCategories: query.get("categories") !== null ? query.get("categories").split(",") : [],
        checkedGender: query.get("gender") !== null ? query.get("gender").split(",") : [],
        checkedSizes: query.get("size") !== null ? query.get("size").split(",") : [],
        checkedPrice: query.get("price") !== null ? query.get("price").split(",") : [],
      })
    }
  }

  getFilters = (id) => {
    // if (window.location.href.includes('products/sale')||window.location.href.includes('/products/overview/sale')) {
    //   var href = window.location.href.split('/');
    //   if (href[6].includes('?')) {
    //     href = href[6].split('?')[0]
    //   } else {
    //     href = href[6]
    //   }
      var data = {
        id: [id]
      }
      Apiservice.service(window.FILTERS_BASED_ON_SALEID, 'POST', data).then(res => {
        if (res.data.status === 200) {
          this.setState({
            filterData: res.data.data,
            showPopup: true,
            loading: false,
          })
          localStorage.setItem('Filters',JSON.stringify(res.data.data))
          localStorage.setItem('saleId',id)
        }
      })
    }
  // }

  getGlobalFilters = ()=>{
    let id = JSON.parse(localStorage.getItem('user')).id;
    let saleIds = localStorage.getItem('user_sales');
    var data1 = {
      id: id,
      userSaleIds : (saleIds !== null) ? saleIds.split(","):[],
    }
    Apiservice.service('webshopapi/shopv1/globalfilters', 'POST',data1).then(res => {
      if (res.data.status === 200) {
        this.setState({
          filterData: res.data.data,
          showPopup: true,
          loading: false,
        })
        localStorage.setItem('GlobalFilters',JSON.stringify(res.data.data))
      }
    })
  }

  handleHide = () => {
    this.setState(
      { showPopup: false }
    )
    if(this.props.scrollPosition !== undefined){
    window.scrollTo(0,this.props.scrollPosition)
    this.props.handleCloseFilter();
  }
}
  changeBrandStatus=()=>{
          this.setState({
            showBrands:false,
         })
     }

  changeBrandStatustrue=()=>{
          this.setState({
            showBrands:true,
         })
     }
     changeSizeStatus=()=>{
             this.setState({
               showSizes:false,
            })
        }

     changeSizeStatustrue=()=>{
             this.setState({
               showSizes:true,
            })
        }

        changeCategoryStatus=()=>{
                this.setState({
                  showCategories:false,
               })
           }

        changeCategoryStatustrue=()=>{
                this.setState({
                  showCategories:true,
               })
           }

           changePriceStatus=()=>{
                   this.setState({
                     showPrices:false,
                  })
              }

           changePriceStatustrue=()=>{
                   this.setState({
                     showPrices:true,
                  })
              }

              changeGenderStatus=()=>{
                      this.setState({
                        showGenders:false,
                     })
                 }

              changeGenderStatustrue=()=>{
                      this.setState({
                        showGenders:true,
                     })
                 }


  expandSize() {
    this.setState(
      { filtersize: true }
    )
  }
  expandShoe() {
    this.setState(
      { shoesize: true }
    )
  }
  onChangeValue(e, type) {
    this.setState({
      selectedGender: e.target.value
    })
  }
  applyFilter() {
    var href = window.location.href.split('/');
    let paramsArray = {
      'brands': this.state.checkedBrands,
      'categories': this.state.checkedCategories,
      'size': this.state.checkedSizes,
      'price': this.state.checkedPrice,
      'gender': this.state.checkedGender,
    }

    var url = ''
    if (window.location.href.includes('/products/sale')) {
      if(href[6].includes('?')){
        href = href[6].split('?')[0]
      }else {
        href = href[6]
      }
      url = '/middleware/sale/filters/' + href + '?'
      this.handleHide();
    } else {
      url = '/middleware/filters?'
      this.handleHide();
    }
    var final = ''
    for (var key in paramsArray) {
      if (paramsArray[key].length > 0) {
        var path = '&' + key + '=' + paramsArray[key]
        final = final.concat(path);
      }
    }
    let finale_url = final.slice(1)
    if (finale_url.length === 0) {
      return OCAlert.alertError(t('Please select atleast one filter'), { timeOut: window.ALERT_WARNING_TIME });
    } else {
      this.props.history.push(url + finale_url)
      this.handleHide();
      // window.location = url + finale_url;

    }

  }
  handleCheck(event, type) {
    let checkedBrands = this.state.checkedBrands;
    let checkedCategories = this.state.checkedCategories;
    let checkedSizes = this.state.checkedSizes;
    let checkedPrice = this.state.checkedPrice;
    let checkedGender = this.state.checkedGender;
    let check = event.target.checked;
    let checked_value = event.target.value;
    if (check) {
      if (type === 'brands') {
        this.setState({
          checkedBrands: [...this.state.checkedBrands, checked_value],
        })
      }
      if (type === 'category') {
        this.setState({
          checkedCategories: [...this.state.checkedCategories, checked_value],
          expandFilter: checked_value
        })
      }
      if (type === 'size') {
        this.setState({
          checkedSizes: [...this.state.checkedSizes, checked_value],
        })
      }
      if (type === 'price') {
        this.setState({
          checkedPrice: [...this.state.checkedPrice, checked_value],
        })
      }
      if (type === 'gender') {
        this.setState({
          checkedGender: [...this.state.checkedGender, checked_value],
        })
      }
    } else {
      if (type === 'brands') {
        var index = checkedBrands.indexOf(checked_value);
        if (index > -1) {
          checkedBrands.splice(index, 1);
          this.setState({
            checkedBrands: checkedBrands
          })
        }

      }
      if (type === 'category') {
        var index1 = checkedCategories.indexOf(checked_value);
        if (index1 > -1) {
          checkedCategories.splice(index1, 1);
          this.setState({
            checkedCategories: checkedCategories,
          })
        }
      }
      if (type === 'size') {
        var index2 = checkedSizes.indexOf(checked_value);
        if (index2 > -1) {
          checkedSizes.splice(index2, 1);
          this.setState({
            checkedSizes: checkedSizes
          })
        }
      }
      if (type === 'price') {
        var index3 = checkedPrice.indexOf(checked_value);
        if (index3 > -1) {
          checkedPrice.splice(index3, 1);
          this.setState({
            checkedPrice: checkedPrice
          })

        }
      }
      if (type === 'gender') {
        var index4 = checkedGender.indexOf(checked_value);
        if (index4 > -1) {
          checkedGender.splice(index4, 1);
          this.setState({
            checkedGender: checkedGender
          })

        }
      }
    }

  }
  renderFilters(data, type, checkedValues) {
    let result;
    if (data !== undefined) {
      result = Object.values(data).map((ele, key) => {
        if (ele !== null && ele !== '') {
          return <div className="col-md-3 col-lg-3 pl-0 float-left">
          <div  key={ele}>
          <label class="checkWrap">
              <span>{ele}</span>
              <input
                  name={ele}
                  type="checkbox"
                  className="mr-2"
                  value={ele}
                  checked={(checkedValues !== undefined) ? checkedValues.includes(ele) : false}
                  onClick={(e) => this.handleCheck(e, type)}
            />
              <span class="checkmark"></span>
            </label>

      </div>
  </div>
        }
      })
    }
    return result;
  }
  renderGenderButtons(data, type) {
    let result;
    if (data !== undefined) {
      result = data.map((ele, key) => {
        return<div className="col-md-3 col-lg-3 pl-0 float-left">
           <div  key={ele}>
           <label class="checkWrap">
            <input
              name={ele.value}
              type="checkbox"
              value={ele.value}
              onChange={(e) => this.handleCheck(e, type)}
            /> <span class="checkmark"></span>
          </label>
           </div>
        </div>
      })
    }
    return result;
  }
  renderPriceFilters(data, type, checkedValues) {
    console.log(type);;
    let result;
    if (data !== undefined) {
      result = Object.values(data).map(ele => {
        if (ele.minimum !== null && ele.maximum !== null) {
          return <div className="col-md-3 col-lg-3 pl-0 float-left">
             <div  key={ele}>
             <label class="checkWrap">
             <div class="filter-price"><span>€</span> &nbsp;{ele.minimum}-{ele.maximum}</div>
              <input
                name={ele}
                type="checkbox"
                className="mr-2 popup-filter-price"
                value={ele.minimum + '-' + ele.maximum}
                checked={(checkedValues !== undefined) ? checkedValues.includes(ele.minimum + '-' + ele.maximum) : false}
                onClick={(e) => this.handleCheck(e, type)}
              />
              <span class="checkmark "></span>

            </label>
             </div>
          </div>
        }
      })
    }
    return result;
  }
  renderSizeFilters(data, type, checkedValues, value) {
    let result = [];
    var i = 0;
    if (this.state.checkedCategories.length === 0 && data !== undefined) {
      result[i] = Object.keys(data).map(ele => {
        return<div className="col-md-3 col-lg-3 pl-0 float-left">
           <div  key={ele}>
           <label class="checkWrap">
           <div class="filter-price">{ele}</div>
              <input
                name={ele}
                className="mr-2 popup-filter-price"
                type="checkbox"
                checked={(checkedValues !== undefined) ? checkedValues.includes(ele) : false}
                value={ele}
                onClick={(e) => this.handleCheck(e, type)}
              />
              <span class="checkmark"></span>

            </label>
           </div>
        </div>
      })
      i++;
      return result;
    }
    if (this.state.checkedCategories.length > 0) {
      if (data !== undefined) {
        let result1 = [];
        Object.values(this.state.checkedCategories).map(e => {
          Object.keys(data).map((item) => {
            if(data[item].includes(e)===true){
              result1.push(item);
            }
          })
        })
        if(result1.length>0){
        let uniqueFilterValues = [...new Set(result1)].sort();
        Object.values(uniqueFilterValues).map(ele => {
          result.push(
            <div key={ele} className="col-md-3 col-lg-3 float-left pl-0 checkboxs filter_list">
                <div className="list_class">
              <label class="checkWrap">
              <span>{ele}</span>
                <input name={ele}
                 type="checkbox"
                 className="mr-2"
                  checked={(checkedValues !== undefined) ? checkedValues.includes(ele) : false}
                  value={ele}
                  onClick={(e) => this.handleCheck(e, type)}
                />
              <span class="checkmark"></span>
              </label>
            </div>
            </div>
          )
        })
      }
        return result;
      }
    }
  }


  render() {

    const brands = (this.state.filterData['Brands'] !== undefined) ?Object.values(this.state.filterData['Brands']).sort(function (a, b) {
        return a.localeCompare(b);
      }) :[];
      const categories = (this.state.filterData['Category'] !== undefined) ?Object.values(this.state.filterData['Category']).sort(function (a, b) {
          return a.localeCompare(b);
        }) :[];
        const gender = (this.state.filterData['gender'] !== undefined) ?Object.values(this.state.filterData['gender']).sort(function (a, b) {
            return a.localeCompare(b);
          }) :[];

          const price = (typeof(this.state.filterData['price']) === 'object') ?
          Object.values(this.state.filterData['price']).sort(function(a, b) {
          return a.minimum - b.minimum
        }) :
          (this.state.filterData['price'] !== undefined) ?
           this.state.filterData['price'].sort(function(a, b) {
          return a.minimum - b.minimum
        }) :[]
    const sizes = this.state.filterData['Size'];
    // const price = this.state.filterData['price'];
    const length = this.state.filterData.length
    // const disabled = !this.state.loading && brands === undefined && categories === undefined && sizes === undefined  && price === undefined&& gender.length ===0 && price.length ===0;
    return (
        <Row>

      <Reactbootstrap.Modal
        show={this.state.showPopup}
        className="mt-10 mr-10 ml-10 mb-10 popup popupblur"
        onHide={this.handleHide}
        dialogClassName="modal-90w filter-close-popup "
        aria-labelledby="example-custom-modal-styling-title"
        className="filter-modal desktop"
        scrollable={true}
      >
         <div class="filterhead">
             <h3 >{t('Filter')}</h3>
          </div>
        <Reactbootstrap.Modal.Header closeButton  className="filter-close">
          <Reactbootstrap.Modal.Title id="contained-modal-title-vcenter">
          </Reactbootstrap.Modal.Title>
          </Reactbootstrap.Modal.Header>
          <Reactbootstrap.Modal.Body className="popup-body filter-popup-body">
          {/* <h3 class="filterhead">Filter</h3> */}

          <div class="scrolfixed">
                {this.state.loading && <p className="col-md-12 col-12 col-lg-12">{t('Please wait fetching data')}.... </p>}
                {!this.state.loading && length === 0 && <p>{t('No filters')}</p>}
                <div className='col-md-12 col-12 px-0'>
                  {brands !== undefined && <p className="text-colors w-100 float-left">{t('Brands')}</p>}
                  {brands !== undefined && this.renderFilters(brands, 'brands', this.state.checkedBrands)}
                </div>
                <div className='col-md-12 col-12 px-0'>
                  {categories !== undefined && <p className="text-color w-100 float-left">{t('Categories')}</p>}
                  {categories !== undefined && this.renderFilters(categories, 'category', this.state.checkedCategories)}
                </div>
                <div className='col-md-12 col-12 px-0'>
                  {price !== undefined &&<p className="text-color w-100 float-left">{t('Price range')}</p>}
                  {price !== undefined && this.renderPriceFilters(price, 'price', this.state.checkedPrice)}
                </div>
                <div className='col-md-12 col-12 px-0'>
                  {sizes !== undefined && <p className="text-color w-100 float-left">{t('Sizes')}</p>}
                  {this.state.checkedCategories.length === 0 && this.renderSizeFilters(sizes, 'size', this.state.checkedSizes)}
                  {/* {this.state.checkedCategories.length > 0 && sizes !== undefined && this.state.checkedCategories.map(ele => {
                    return this.renderSizeFilters(sizes, 'size', this.state.checkedSizes, ele)
                  })} */}
                  {this.state.checkedCategories.length > 0 && sizes !== undefined && this.renderSizeFilters(sizes, 'size', this.state.checkedSizes, this.state.checkedCategories)}
                </div>
                <div className='col-md-12 col-12 px-0'>
                  {gender !== undefined && <p className="text-color w-100 float-left">{t('Gender')}</p>}
                  {gender !== undefined && this.renderFilters(gender, 'gender', this.state.checkedGender)}
                </div>
          </div>
          </Reactbootstrap.Modal.Body>

          <div class="filter-footer-popup">
           <Reactbootstrap.Button className="alignmentForButtons" onClick={this.applyFilter} >{t('Apply')}</Reactbootstrap.Button>
          {/* <Reactbootstrap.Button className="alignmentForButtons" onClick={this.handleHide} >Cancel</Reactbootstrap.Button> */}

              </div>


            {/* <Reactbootstrap.Modal.Footer className="filter-footer" > */}
              {/*<Reactbootstrap.Button >Clear</Reactbootstrap.Button>*/}


        {/* </Reactbootstrap.Modal.Footer> */}



      </Reactbootstrap.Modal>

               <Modal
                   size="sm"
                   show={this.state.showPopup}
                   backdrop="static"
                   onHide={this.handleHide}
                   aria-labelledby="example-modal-sizes-title-sm"
                   keyboard={false}
                   scrollable={true}
                   dialogClassName="mobile-view-css"
                   className="mobile"
               >
                   <Modal.Header closeButton className="mobile-header" >
                       <Modal.Title>{t('Filter')}</Modal.Title>
                   </Modal.Header>
                   <Modal.Body className="body-filter" >
                   {this.state.loading && <p>{t('Please wait fetching data.... ')}</p>}
                   {!this.state.loading && length === 0 && <p>{t('No filters')}</p>}
                   {brands !== undefined &&
                   <Row className="RowAlter m-auto">
                       <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter">
                           {t("Brands")} <span className="arrowalter"> {this.state.showBrands===true?<img src={uparrow} alt ="" onClick={this.changeBrandStatus}/>:<img src={downarrow} alt="" onClick={this.changeBrandStatustrue}/>}</span>
                       </Col>
                   </Row>
                   }
                   {this.state.showBrands &&
                   <Row className="RowAlter m-auto">
                       <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter">
                           {brands !== undefined && this.renderFilters(brands, 'brands', this.state.checkedBrands)}
                       </Col>
                   </Row>
                   }
                   {categories !== undefined &&
                       <Row className="RowAlter m-auto">
                           <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter">
                               {t("Categories")} <span className="arrowalter">{this.state.showCategories===true?<img src={uparrow} alt="" onClick={this.changeCategoryStatus}/>:<img src={downarrow} alt="" onClick={this.changeCategoryStatustrue}/>}</span>
                           </Col>
                       </Row>
                   }
                   {this.state.showCategories &&
                   <Row className="RowAlter m-auto">
                       <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter">
                          {categories !== undefined && this.renderFilters(categories, 'category', this.state.checkedCategories)}
                       </Col>
                   </Row>
                   }
                   {price !== undefined &&
                   <Row className="RowAlter m-auto">
                       <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter ">
                           {t("Price range")} <span className="arrowalter">{this.state.showPrices===true?<img src={uparrow} alt="" onClick={this.changePriceStatus}/>:<img src={downarrow} alt="" onClick={this.changePriceStatustrue}/>}</span>
                       </Col>
                   </Row>
                   }
                   {this.state.showPrices &&
                   <Row className="RowAlter m-auto">
                       <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter">
                           {price !== undefined && this.renderPriceFilters(price, 'price', this.state.checkedPrice)}
                       </Col>
                   </Row>
               }
                   {sizes !== undefined &&
                   <Row className="RowAlter m-auto">
                       <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter">
                           {t("Sizes")} <span className="arrowalter">{this.state.showSizes===true?<img src={uparrow} alt="" onClick={this.changeSizeStatus}/>:<img src={downarrow} alt="" onClick={this.changeSizeStatustrue}/>}</span>
                       </Col>
                   </Row>
                   }
                   {this.state.showSizes &&
                   <Row className="RowAlter m-auto">
                       <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter">
                           {this.state.checkedCategories.length === 0 && this.renderSizeFilters(sizes, 'size', this.state.checkedSizes)}
                           {this.state.checkedCategories.length > 0 && sizes !== undefined && this.renderSizeFilters(sizes, 'size', this.state.checkedSizes, this.state.checkedCategories)}
                       </Col>
                   </Row>
               }

                   {gender !== undefined &&
                   <Row className="RowAlter m-auto">
                       <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter">
                           {t("Gender")}<span className="arrowalter">{this.state.showGenders===true?<img src={uparrow} alt="" onClick={this.changeGenderStatus}/>:<img src={downarrow} alt="" onClick={this.changeGenderStatustrue}/>}</span>
                       </Col>
                   </Row>
                   }
                   {this.state.showGenders &&
                   <Row className="RowAlter m-auto">
                       <Col className="col-sm-12 pl-3 pr-3 checkboxdivalter">
                             {gender !== undefined && this.renderFilters(gender, 'gender', this.state.checkedGender)}
                       </Col>
                   </Row>
                   }
                   {/* <Row className="RowAlter">
                       <Col>
                        <Button variant="danger" className="alignmentForButtons"  onClick={()=>this.applyFilter()}>{t("Apply")}</Button>{' '}
                         <Button variant="danger" className="alignmentForButtons" onClick={()=>this.handleHide()}>{t("Cancel")}</Button>{' '}

                       </Col>
                   </Row> */}
                   </Modal.Body>
                   <Reactbootstrap.Modal.Footer className="mobile-footer">
                    <Button variant="danger" className="alignmentForButtons col-3 cancelbutton" onClick={()=>this.handleHide()}>{t("Cancel")}</Button>{' '}
                    <Button variant="danger" className="alignmentForButtons col-3 applybutton"  onClick={()=>this.applyFilter()}>{t("Apply")}</Button>{' '}
                   </Reactbootstrap.Modal.Footer>
               </Modal>

           </Row>
    )
  }

}
export default withRouter(Popup);
