import { BrowserRouter as Router } from "react-router-dom";
import BaseRouter from '../../routes/routes.js';
import React, { useState, useEffect } from 'react';
import { AuthContext } from '../../context/context.js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../constants/apiConstants.js';
import Footer from '../../globallayouts/Footer.js';
import '../../constants/constants.js';
import GlobalLayout from '../../globallayouts/GlobalLayout.js';
import Loader from 'react-loader-spinner';
import UserStatus from '../Login/UserStatus';

window.onbeforeunload = function (e) {
  localStorage.removeItem('Filters');
  localStorage.removeItem('GlobalFilters');
  localStorage.removeItem('saleId');
};
function App() {
  const [userDetails] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [isLogged] = useState(
    JSON.parse(localStorage.getItem("isLogged"))
  );
  if (!isLogged) {
    document.body.classList.add('user-login');
  }
  if (isLogged) {
    document.body.classList.remove('user-login');
  }
  // check user status if suspended logouts 
  UserStatus();

  if (window.self !== window.top) {
    return (
      <div className="unauthorisedAccess">
        <h1>ACCESS DENIED</h1>
        <hr></hr>
        <h3>You are not authorised to access this url within the iframe</h3>
      </div>
    );
  }
  else {

    return (
      // storing the user login details in app context so we can use these details anywhere in the application
      <AuthContext.Provider value={{ userDetails, isLogged }}>
        <div className="contentwrap">

          <Router>
            <GlobalLayout />
            <BaseRouter />
          </Router>
          {Loading()}
        </div>
        {<Footer />}
      </AuthContext.Provider>
    );
  }
}
const Loading = () => {
  const style = {
    position: "fixed",
    left: 0,
    top: 0,
    'z-index': '9999',
    height: '100%',
    width: '100%',
    'background-color': '#4b4b4b',
    opacity: ".5",
    "padding-top": '15%',
    "text-align": "center",
    display: "none",
  }
  return (
    <div className="loading" id="loading-icon" style={style} >
      <Loader
        type="Bars"
        color="#cd1719"
        height="100"
        width="100"
      />
    </div>
  );
}

export default App;
