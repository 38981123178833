import React, { Component } from 'react'
import * as Reactbootstrap from 'react-bootstrap'
import SaleLayout  from './SaleLayout.js'
import {Apiservice} from 'services/Apiservices'
import { OCAlert } from '@opuscapita/react-alerts';
import { t } from '../../translations/Translate'
import './FlashSaleView.css'
import {  isBrowser, isMobile, isTablet, isIPhone13,  isIPod13} from 'react-device-detect';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';

import '../commoncss/common.css';
import {detect} from 'services/NotSupportOs'


export default class FlashSalesView extends Component {

   constructor(props){
     super(props)
     this.state ={
       loading:true,
       selectedLanguage:null,
       show:true,
       defaultlanguage:false,
       welcomeNotes:[],
       saleIds:[],
       checkAcess:[],
       flashSaleItems:{
         salesLayout:[],
         sales:[]
       },
       isOsSupport:undefined,
       iosVersion:0
     }
   }
   componentDidMount() {
    // document.querySelector('.shipping-text-msg').parentElement.parentElement.firstElementChild.children[1].children[1].style.paddingTop= '30px';
    document.querySelector('.shipping-text-msg').parentElement.parentElement.parentElement.parentElement.firstElementChild.children[1].children[1].style.paddingTop = '30px'
   }
   componentWillUnmount(){
    // document.querySelector('.shipping-text-msg').parentElement.parentElement.firstElementChild.children[1].children[1].style.paddingTop= '0px';
    document.querySelector('.shipping-text-msg').parentElement.parentElement.parentElement.parentElement.firstElementChild.children[1].children[1].style.paddingTop = '0px'
  }
   UNSAFE_componentWillMount(){

     this.getFlashSales();

   }

   async updateLanguage(){
        if(this.state.selectedLanguage!==null && this.state.selectedLanguage!=='null' && this.state.selectedLanguage!=="" && this.state.selectedLanguage!==undefined  ){
            this.setState({
                show:false
            })
            let data = {
                user_id : JSON.parse(localStorage.getItem('user')).id,
                webshopDefaultLanguage:this.state.selectedLanguage
            }
            await Apiservice.service(window.DEFAULT_LANGUAGE+data.user_id, 'PUT', data).then(res =>{

                if(res.status === 200){
                    OCAlert.alertSuccess(t("Default language updated"), { timeOut: window.ALERT_ERROR_TIME });
                        localStorage.setItem('activeLanguage',this.state.selectedLanguage);
                        window.location.reload()
                }
            })
            .catch(error => {
                OCAlert.alertError(t('error while updating'), { timeOut: window.ALERT_ERROR_TIME });
            })

        }
   }
   setSelectedValueEnglish(){
       this.setState({
           selectedLanguage:document.getElementById('english').value
        })
   }
   setSelectedValueDutch(){
       this.setState({
           selectedLanguage:document.getElementById('dutch').value
        })
   }
   setSelectedValueFrench(){
       this.setState({
           selectedLanguage:document.getElementById('french').value
        })
   }

   componentDidUpdate(prevProps, prevState){
     if(!this.state.loading){
     try {
        const images = document.querySelectorAll('[data-src]');
        const imgOptions = {
           threshold:0.2,
           rootMargin:"0px 0px 300px 0px"
        };
        const imgObserver = new IntersectionObserver((entries,imgObserver)=>{
        entries.forEach(entry=>{
            if(!entry.isIntersecting){
                return;
            }else{
               this.preloadImage(entry.target);
               imgObserver.unobserve(entry.target)
            }
        })
    },imgOptions)
    images.forEach(image=>{
       imgObserver.observe(image)
    })
  }catch (e) {
        if(prevState.isOsSupport !== this.state.isOsSupport){
       this.setState({
         isOsSupport:false,iosVersion:detect.iosSystemInfo()
       })
    }
  }
}
   }
   render(){
     const {loading,isOsSupport}= this.state;
     const notSupportMsg =  "Your ios version"+window.UN_SUPPORT_MSG
     return(
        <div>
        {(localStorage.getItem("activeLanguage")===null || localStorage.getItem("activeLanguage")==='null') && (localStorage.getItem("terms_and_conditions")==='true' || localStorage.getItem("terms_and_conditions")===true) &&
            this.getPopupModal()
        }
        { this.getSalesViewRender()}
         <Reactbootstrap.Container className = {isOsSupport === false? 'ml-2':'l'} style = {{display:loading || isOsSupport === false  ? 'block':'none'}}>
         <h5 className = "text-center mt-5 pt-4 textcolor ml-5">{ loading ? t('Please wait while we fetch sales for you...'): t(notSupportMsg)}</h5>
         </Reactbootstrap.Container>
       </div>
     )
   }
   getPopupModal=()=>{

        return (
            <Modal
            show={this.state.show}
            backdrop="static"
            keyboard={false}
            dialogClassName="language-popup"
        >
            <Modal.Header  className="language-header">
                <Modal.Title>{t('Select default language')} </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                 <div className="design-language">
                    <h6>{t('English')}</h6>
                    <label class="radioWrap mb-0">
                    <input type="radio" name="language" value="en" id = "english" onChange={()=>this.setSelectedValueEnglish()} />
                    <span class="checkmark"></span>
                    </label>
                </div>

                 <div className="design-language">
                    <h6>{t('Dutch')}</h6>
                    <label class="radioWrap mb-0">
                    <input type="radio" name="language" value="nl" id = "dutch" onChange={()=>this.setSelectedValueDutch()}/>
                    <span class="checkmark"></span>
                    </label>
                </div>
                <div className="design-language">
                    <h6>{t('French')}</h6>
                    <label class="radioWrap mb-0">
                    <input type="radio" name="language" value="fr" id = "french" onChange={()=>this.setSelectedValueFrench()} />
                    <span class="checkmark"></span>
                    </label>
                </div>
                <Modal.Footer className="popup-accept">
                    <Button className="buttonSizeReduce" onClick={()=>this.updateLanguage()} >{t('Accept')}</Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
        )

}


    getSalesViewRender =()=>{

      const {loading,isOsSupport} = this.state;

      const {welcomeNotes} = this.state
        const liveSales = this.getSaleByType();
        const upComing = this.getUpcomingSales();
            return<div>
                  <p className="text-center shipping-text-msg">{welcomeNotes.free_shipping}</p>
                  <div className="fixed-layout layout-space">
                      <div style ={{display:loading || isOsSupport === false ? 'none':'block'}}>
                       <div className="row ml-auto mr-auto col-md-12  sales-new-layout pb-4 pt-4 mb-2">
                         {/* <div className = "col-md-6 p-0" dangerouslySetInnerHTML={{ __html: welcomeNotes.left_side}}></div> */}
                           <div className = "col-md-6 col-lg-6 col-12 p-0 mt-5 mob-top-space-intro bottom-new-space">
                             <h2 className="textcolor font-weight-bold">{t('Welcome to')}</h2>
                             <h2 className="textcolor font-weight-bold text-big" dangerouslySetInnerHTML={{ __html: welcomeNotes.left_side}}></h2>
                           </div>
                           <div className="col-md-6 col-lg-6 col-12 p-0 intro-text-top-space text-align">
                               <div className ="col-md-12 col-12 pl-0 pr-1 intro-text" dangerouslySetInnerHTML={{ __html: this.getWelcomeNotes(welcomeNotes.right_side)}}></div>
                           </div>
                       </div>
                       <div className = "sale-section sales-new-layout mb-5">
                           <div className="col-md-12 col-lg-12 px-0 col-12 row m-auto justify-content-center">
                                {liveSales}
                                {upComing}
                           </div>
                       {liveSales.length === 0 && upComing.length === 0 && <h5 className = "text-center textcolor"><span>{t("No sales avaliable come back later")}</span></h5>}
                      </div>
                    </div>
                </div>
            </div>
   }
    checkUserAcess = (saleIds) =>{

      if(saleIds !== undefined){
      // const [sta,setSta]=useState();
      let data = {
        user_id : JSON.parse(localStorage.getItem('user')).id,
        sale_id : saleIds
      }
      Apiservice.service(window.CHECK_USERS, 'POST', data).then(res =>{
        if(res.status === 200){
         this.setState({
           checkAcess:res.data.data
         })
        }
      })
    }
  }

  getWelcomeNotes(notes){
      if (notes !== undefined && notes !== null) {
          let userName = JSON.parse(localStorage.getItem("user")).firstname
          return notes.replace('!user', userName)
      }
  }

    getFlashSales =async ()=>{
      await  Apiservice.service(window.FETCH_FLASH_SALES, 'GET').then(res =>{
        if(res.data.status === 200){
            var user = JSON.parse(localStorage.getItem("user"));
          const group = user.group_id !== undefined ? Object.values(user.group_id) : [];
          let saleIds = [];
          var upComingSales = Object.values(res.data.data.salesLayout).filter(sale =>{
            saleIds =[...saleIds,sale.id]
          return sale.salePeriod !== undefined && sale.salePeriod.includes('upcoming-sale') && (group.length === 0 || res.data.data.sales[sale.id].userGroup.length === 0 || res.data.data.sales[sale.id].userGroup.some(item=>group.includes(item)) )
          })
          this.checkUserAcess(saleIds)
          var temp = [];
        for (var i = 0; i < upComingSales.length; i++) {
          if(upComingSales[i+1] !== undefined){
              temp.push(
                [upComingSales[i],upComingSales[i+1]]
              )
            }else{
              temp.push(
                [upComingSales[i]]
              )
            }
          i++;
        }
          this.setState({
            fetchedSales:true,
            loading:false,
            flashSaleItems:res.data.data,
            welcomeNotes:res.data.data.welcome_notes[0] === undefined ? "" : res.data.data.welcome_notes[0],
            upComingSales:temp,
            isOsSupport:true
          })
        }else{
          OCAlert.alertError(t('Internal server error occured'), {timeOut: window.ALERT_ERROR_TIME});
        }
      }).catch(function (error) {
        if(document.getElementById('loading-icon')!== null){
          document.getElementById('loading-icon').style.display = 'none';
        }
        OCAlert.alertError(error.message, { timeOut: window.ALERT_ERROR_TIME });
      });
   }

   getSaleByType(){
     const {flashSaleItems} = this.state
     var userSaleIds = [];
      var user = JSON.parse(localStorage.getItem("user"));
      const group = user.group_id !== undefined ? Object.values(user.group_id) : [];
       let sales = [];
     Object.values(flashSaleItems.salesLayout).map((layout,index)=>{
     if((layout.salePeriod !== undefined && layout.salePeriod.includes('current-sale')) && (group.length === 0 ||
          flashSaleItems.sales[layout.id].userGroup.length === 0
          || flashSaleItems.sales[layout.id].userGroup.some(item=>group.includes(item)) )
      ){
        userSaleIds.push(layout.id)
     return(
           sales.push(
             <SaleLayout
             sale = {layout}
             onSaleStart ={this.onSaleCompletesDuration}
             saleType = 'liveSales'
             />
           )
        )
       }
   })
   localStorage.setItem('user_sales',userSaleIds);
   return sales;
  }
   getUpcomingSales = ()=>{
    const {upComingSales} = this.state
    let sales = [];
    let items = upComingSales !== undefined ? upComingSales :[];
    items.map(sale=>{
      return(
            sales.push(
              <SaleLayout
              sale = {sale}
              saleIds = {this.state.checkAcess}
              onSaleStart ={this.onSaleCompletesDuration}
              saleType = 'upComing'
              />
            )
         )
    })
     return sales;
  }
  preloadImage = (img)=>{
    const src = img.getAttribute("data-src");
    if(!src){
      return
    }
    img.src = src;
  }
  onSaleCompletesDuration =(saleId)=>{
    const postData = {
      "sale_id": saleId
    }
    Apiservice.service(window.NOTIFY_USER_SALE_START, 'POST',postData).then(res =>{
      if(res.data.status === 200){
        window.location.reload()
      }else{
        OCAlert.alertError(t('Internal server error occured'), {timeOut: window.ALERT_ERROR_TIME});
      }
    }).catch(function (error) {
      if(document.getElementById('loading-icon')!== null){
        document.getElementById('loading-icon').style.display = 'none';
      }
      OCAlert.alertError(error.message, { timeOut: window.ALERT_ERROR_TIME });
    });
  }

}
