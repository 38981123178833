import React, {Component} from 'react'
import * as Reactbootstrap from 'react-bootstrap'
import Popup from 'components/Popup/Popup.js';
import {Apiservice} from 'services/Apiservices'
import { t } from 'translations/Translate';
import {withRouter} from 'react-router-dom'

class Filterview extends Component {
  constructor(props) {
   super(props)
   this.state ={
     filterData:[],
     showPopup:false,
     random_num:'',
     loading:true
   }
  }
    componentDidMount(){
      let search = new URLSearchParams(window.location.search)
      if(window.location.href.includes('middleware/sale') && !window.location.href.includes('/search')){
        this.props.history.push('/products/sale/filters/'+this.props.match.params.id+'?'+search)
      }
      if(window.location.href.includes('/middleware/filters')){
        this.props.history.push('/products/filter/'+'?'+search)
      }
      if(window.location.href.includes('/middleware/sale/search')){
        this.props.history.push('/products/sale/search/'+this.props.match.params.saleId+'?'+search)
      }
      if(window.location.href.includes('/middleware/search')){
        this.props.history.push('/products/search/'+'?'+search)

      }

  }
  componentDidUpdate(){
  }
  getFilters =()=>{
   Apiservice.service(window.FETCH_FILTERS ,'GET').then(res =>{
     if(res.data.status === 200){
           this.setState({
             filterData:res.data.data,
             showPopup:true,
             loading:false,
           })
    }  })
    }


  render(){
    const {loading} = this.state
    return (
    <Reactbootstrap.Container style = {{display:loading ? 'block':'none'}}>
    // {loading && <p>{t('Please wait')}... </p>}
    // <Popup filterData ={this.state.filterData} showPopup = {this.state.showPopup} random_num = {Math.random()}/>
    </Reactbootstrap.Container>
  )
}
}
export default withRouter(Filterview);
