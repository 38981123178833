import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import {Apiservice} from 'services/Apiservices';
import './Login.css';
import { Modal } from "react-bootstrap";
import { t } from 'translations/Translate'
import Button from 'react-bootstrap/Button';
import { OCAlert } from '@opuscapita/react-alerts';
export default function TermsConditions() {
    const [show, setShow] = useState(true);
    let history = useHistory();
    const handleClose = () => {
        setShow(false)
        localStorage.setItem('isLogged',false);
        localStorage.setItem('u-token',"");
        history.push("/");
    };
    const [termsConditions,setTermsConditions]=useState(".");
    const handleRedirect = () => {
        const data={
            "user_id" : JSON.parse(localStorage.getItem('user')).id,
            "webshop_terms_and_conditions" : "true"
        }
        Apiservice.service(window.UPDATE_TERMS_AND_CONDITION+data.user_id, "PUT", data)
        .then(response => {
            if(response.status===200){
                localStorage.setItem("isLogged", true);
                history.push("/flashsale");
            }
        }).catch(error => {
            OCAlert.alertError("Error while updating", { timeOut: window.ALERT_ERROR_TIME });
        })

    }
    useEffect(() => {
        //  document.getElementById("img-fluid").style.display="none";
    const data={
        "user_id" : JSON.parse(localStorage.getItem('user')).id
    }
    Apiservice.service(window.TERMS_AND_CONDITION, "POST", data)
    .then(response => {
        if(response!==null){
            setTermsConditions(response.data.data.terms_and_conditions);
        }
    }).catch(error => {
        console.error("first login modal error : ", error);
    })
    },[]);

  return (
    <>
        <Modal size
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="conditions-popup"
        >
            <div className="terms-and-conditions-popup">
            <Modal.Header closeButton className="popup-title">
              <Modal.Title>{t('Terms and conditions')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="popup-content" >
                <p dangerouslySetInnerHTML={{__html:termsConditions}}/>
            </Modal.Body>
            <Modal.Footer className="popup-accept">
               <Button variant="danger" onClick={handleRedirect}>{t('Accept')}</Button>
            </Modal.Footer>
            </div>
        </Modal>
    </>
  );
}
