import React from "react";
import cart from 'images/cart.svg';
import filter from 'images/filter.svg';
import user from 'images/user.svg';
import favicon from 'images/favicon.ico';
import './NavBar.css';
import SideNav, {  NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

function Admin(props) {
  return <>

      <SideNav
        onSelect={(selected) => {
         
        }}
      >

      <SideNav.Toggle />
      <SideNav.Nav defaultSelected="home">
          <NavItem eventKey="home">
              <NavIcon>
                  <img className="navIcon" alt="" src={favicon} />
              </NavIcon>
              <NavText>
                  Logo
              </NavText>
          </NavItem>
          <NavItem eventKey="Myaccount">
              <NavIcon>
                  <img className="navIcon" alt="" src={user} />
              </NavIcon>
              <NavText>
                  My account
              </NavText>
          </NavItem>
          <NavItem eventKey="filter">
              <NavIcon>
              <img className="navIcon" alt="" src={filter} />
              </NavIcon>
              <NavText>
                  Filter
              </NavText>
              </NavItem>
          <NavItem eventKey="mycart">
              <NavIcon>
                  <img className="navIcon" alt="" src={cart} />
              </NavIcon>
              <NavText>
                  My cart
              </NavText>
          </NavItem>
      </SideNav.Nav>
    </SideNav>
    </>
}

export default Admin;
