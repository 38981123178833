import React,{ Component } from 'react';
import './privacypolicyAndDisclaimer.css';
import {Apiservice} from 'services/Apiservices';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory} from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { t } from 'translations/Translate';
import '../commoncss/common.css';

class Disclaimer extends Component {
    constructor(props){
        super(props)
        this.state  = {
            disclaimer:"",
        }
    }
    componentDidMount(){
        if(!JSON.parse(localStorage.getItem("isLogged"))){
            // document.getElementById("img-fluid").style.display="none";
        }
        var data={
                "from":"disclaimer"
            };

        Apiservice.service(window.FETCH_PRIVACY_POLICY_AND_DISCLAIMER, 'POST', data)
        .then(response => {
            if(response!==null){
                this.setState({
                    disclaimer:response.data.data.disclaimer
                })
            }
        }).catch(error => {
            console.error("disclaimer api problem : ", error);
        })
    }

    render() {
        return(
            <div className="alignCenter">
                <Row className="privacypolicyMOvingBottom">
                    <Col className="col-sm-12 col-md-8 offset-md-2">
                        <p class="backButton disclaimerBackButton" onClick={this.props.history.goBack}> </p>
                        <h4 className="privacyPolicyDisclaimerColor">{t('Disclaimer')}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-sm-12 col-md-8 offset-md-2">
                        <p dangerouslySetInnerHTML={{__html:this.state.disclaimer}}/>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default Disclaimer;
