import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { OCAlert } from '@opuscapita/react-alerts';
import { t } from 'translations/Translate';
import {Apiservice} from 'services/Apiservices'

const SsoLoginMiddlewere = (props) => {
  const search = useLocation().search;
  const history = useHistory();
    useEffect(() => {
      let scode = new URLSearchParams(search).get('scode');
     
      if(scode === "200"){
        let token = new URLSearchParams(search).get('token');
        
        let data = {
          'auth_token': token,
          'request_from': "webshop",
          'actions': "sso-login",
        }
        
        Apiservice.service('api/v1/logins', "POST", data).then(res =>{
          if(res.status===200){
            let user_details = res.data.data.user_details[0];
            let auth_token = res.data.data.auth_token;
            
            let data ={
              id:user_details.id,
              firstname: user_details.firstName,
              lastname : user_details.lastName,
              language : user_details.webshop_active_language,
              workPhone : user_details.webWorkPhone,
              workMail : user_details.webWorkEmail,
              personalMail : user_details.webPEmail,
              group_id:user_details.group_id,
              user_role:user_details.user_role,
              lastLoginDate:user_details.lastLoginDate,
            }
            localStorage.setItem('terms_and_conditions',user_details.webshop_terms_and_conditions);
            localStorage.setItem("user",JSON.stringify(data));
            localStorage.setItem('isLogged',true);
            localStorage.setItem('loginType',"sso");
            localStorage.setItem('activeLanguage', user_details.active_language);
            localStorage.setItem('workPhone',user_details.workPhone);
            localStorage.setItem('workMail',user_details.workMail);
            localStorage.setItem('personalMail',user_details.personalMail);
            localStorage.setItem('group_id',user_details.group_id);
            localStorage.setItem('user_role',user_details.user_role);
            localStorage.setItem('auth_token', auth_token);
            localStorage.setItem('access_token', user_details.access_token);
            let uid = user_details.id;
            localStorage.setItem('u-token',(((0x0000FFFF & uid) << 16) + ((0xFFFF0000 & uid) >> 16)));
            localStorage.setItem("isLogged", true);
            OCAlert.alertSuccess(t('Login successfull.'));
            history.push('/');
        }
    })
    .catch(function(error){
        history.push('/login/email');
        OCAlert.closeAlerts();
        OCAlert.alertError(t(error.response.data.data), {timeOut: window.ALERT_ERROR_TIME});
    });

  }
  else if( scode === "401"){
    history.push('/login/email');
    OCAlert.closeAlerts();
    OCAlert.alertError(t('User is not logged in.'), {timeOut: window.ALERT_ERROR_TIME});
  }
  else if(scode === "404"){
    history.push('/login/email');
    OCAlert.closeAlerts();
    OCAlert.alertError(t('User not exists.'), {timeOut: window.ALERT_ERROR_TIME});
  }
  else if(scode === "400"){
    history.push('/login/email');
    OCAlert.closeAlerts();
    OCAlert.alertError(t('Bad request.'), {timeOut: window.ALERT_ERROR_TIME});
  }
    });
           
  return (
    <div>

    </div>
    );
  }

  export default SsoLoginMiddlewere;
