import React, { useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import { Apiservice } from '../../services/Apiservices';
import { Link } from "react-router-dom";
// import { t } from '../../Translation/Translation';
import './pickuporder.css';
import { OCAlert } from '@opuscapita/react-alerts';

function PickupSearch({ inputHandle, type, order, user, rack, HandleSearch}) {
    return (
      
         <form onSubmit={HandleSearch}>
            <div>
              
                <div className="row m-auto">
                  <div className="col-md-3 col-lg-3 col-4 px-0 overviewspace">
                    <input type="text"  className="form-control" id="order" name="order" placeholder="Order number" value={order} onChange={inputHandle}/>
                  </div>
                  <div className="col-md-3 col-lg-3 ml-2 col-4 px-0 overviewspace">
                    <input type="text"  className="form-control" id="user" name="user" placeholder="User name" value={user} onChange={inputHandle}/>
                  </div>
                  {
                    (type === 'inbound') &&
                  
                  <div className="col-md-3 col-lg-3 ml-2 col-4 px-0 overviewspace">
                    
                  </div>
                  }
                  {
                    (type === 'outbound' || type === 'pickedup') &&
                    <div className="col-md-3 col-lg-3 ml-2 col-4 px-0 overviewspace">
                        <input type="text"  className="form-control" id="rack" name="rack" placeholder="Rack number" value={rack} onChange={inputHandle}/>
                  </div>

                  }
                  
                  <div className="col-md-2 col-lg-2 ml-2 col-2 px-0 overviewspace text-right">
                    <button type="submit" className="form-control"  className ="pickup-btn">Search</button>
                  </div>
            
                </div>

              </div>
           
       </form>
      
    );
}
export default PickupSearch;