import React, { useState, useEffect} from 'react';

import './pickuporder.css';
import { t } from '../../translations/Translate';
import { Link } from "react-router-dom";
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import InfiniteScroll from "react-infinite-scroll-component";
import './pickuporder.css';
import EditOrders from './EditOrders';
import { useHistory } from 'react-router-dom';


function OrderInTab(props) {
  let loc_id = props.locationID;
  let ApiData = props.orders;
  let tab = props.tab;
  const history = useHistory();
  const handleDoubleclicked = (id) => {
    history.push({  
        pathname: "/editorders",
        state: { ordersid:id , locID:loc_id }
    });
  }

  return (
        <section className="col-md-12 col-lg-12 col-12 px-0">
            <div className="col-md-12 collg-12 col-12 px-0">
                <div className=" float-left font-style w-100 mb-5">
                        <div className="col-md-12 col-lg-12 col-12 px-0 mb-2 p-2  table-head">
                            <div className="row m-auto">
                                <div className = {tab === 'inbound' ? "col-md-3 col-lg-3 col-2 pl-0 overviewspace" : "col-md-2 col-lg-2 col-2 pl-0 overviewspace"}>
                                    <div><h6>{t('Order no')}</h6></div>
                                </div>
                                <div className = {tab === 'inbound' ? "col-md-3 col-lg-3 col-3 pl-0 overviewspace" : "col-md-3 col-lg-3 col-3 pl-0 overviewspace"}>
                                    <div><h6>{t('Username')}</h6></div>
                                </div>
                                <div className ={tab === 'inbound' ? "col-md-3 col-lg-3 col-2 pl-0 overviewspace" : "col-md-3 col-lg-3 col-2 pl-0 overviewspace"}>
                                    <div><h6>{t('Status')}</h6></div>
                                </div>
                                <div className = {tab === 'inbound' ? "col-md-2 col-lg-2 col-2 pl-0 overviewspace" : "col-md-2 col-lg-2 col-3 pl-0 overviewspace"}>
                                    <div><h6>{t('Location')}</h6></div>
                                </div>
                                {tab !== 'inbound'? 
                                <div className="col-md-1 col-lg-1 col-1 px-0 overviewspace">
                                    <div><h6>{t('Rack number')}</h6></div>
                                </div> : null
                                }
                                <div className ={tab === 'inbound' ? "col-md-1 col-lg-1 col-2 pl-0 overviewspace" : "col-md-1 col-lg-1 col-12 pl-0 overviewspace"} className="col-md-1 col-lg-1 col-1 pl-0 overviewspace">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="customscroll orders-list-container" id="scrollableDiv">
                            {props.dataLength <= 0 && props.statusCode !== 200 && props.statusCode !== 400 && props.statusCode !== undefined &&
                                <p className="msg">Data not found</p>
                            }
                            {
                                // console.log(ApiData),
                                ApiData !== "" && ApiData !==undefined &&
                                <InfiniteScroll
                                    loader={
                                        <h5 className="textcolor1 text-center">{t("Loading")}.</h5>
                                    }
                                    dataLength={props.dataLength}
                                    next={props.fetchMoreData}
                                    hasMore={props.hasMore}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {ApiData && Object.keys(ApiData).map(e => (
                                        <div className="col-md-12 col-lg-12 col-12 px-0 bg-white mb-3 p-2" key={e}>
                                            <div className="row m-auto"  onDoubleClick = {tab === 'outbound' ? (event) => handleDoubleclicked(ApiData[e].order_id): '' }> 
                                                <div className = {tab === 'inbound' ? "col-md-3 col-lg-3 col-12 pl-0 overviewspace" : "col-md-2 col-lg-2 col-12 pl-0 overviewspace"}>
                                                    {ApiData[e].order_id}
                                                </div>
                                                <div className = {tab === 'inbound' ? "col-md-3 col-lg-3 col-12 pl-0 overviewspace" : "col-md-3 col-lg-3 col-12 pl-0 overviewspace"}>
                                                    {ApiData[e].user.firstName+" "+ApiData[e].user.lastName}
                                                </div>
                                                <div className ={tab === 'inbound' ? "col-md-3 col-lg-3 col-12 pl-0 overviewspace" : "col-md-3 col-lg-3 col-12 pl-0 overviewspace"}>
                                                    {ApiData[e].order_status}
                                                </div>
                                                <div className = {tab === 'inbound' ? "col-md-2 col-lg-2 col-12 pl-0 overviewspace" : "col-md-2 col-lg-2 col-12 pl-0 overviewspace"}>
                                                    {ApiData[e].location}
                                                </div>
                                                {tab !== 'inbound'? 
                                                <div className="col-md-1 col-lg-1 col-12 pl-0 overviewspace">
                                                    {ApiData[e].rack_no}
                                                </div>: null
                                                }
                                                <div className ={tab === 'inbound' ? "col-md-1 col-lg-1 col-12 pl-0 overviewspace order-arrow" : "col-md-1 col-lg-1 col-12 pl-0 overviewspace order-arrow"}>
                                                <Link to={{pathname: '/pickuplocorder',state: { id:ApiData[e].order_id,type:tab,location:loc_id }}} title = {t('Order details')}  className="text-dark"><KeyboardArrowRightOutlinedIcon className="h-25"/></Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
        </section>
    
  );
}

export default OrderInTab;
