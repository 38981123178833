import axios from 'axios';

export const Apiservice = {
  service
};

/**
 * [service description]
 *
 * @param  {[type]} URL              [description]
 * @param  {[type]} METHOD           [description]
 * @param  {[type]} [data=undefined] [description]
 *
 * @return {[type]}                  [description]
 */

async function service(URL,METHOD,data = undefined){
  if(document.getElementById('loading-icon')!== null){
  document.getElementById('loading-icon').style.display = 'block';
}
    let response = await axios(getConfigobj(URL,METHOD,data));
    if(document.getElementById('loading-icon')!== null){
      document.getElementById('loading-icon').style.display = 'none';
    }
    return response;
}
/**
 * [getConfigobj description]
 *
 * @param  {[type]} url    [description]
 * @param  {[type]} method [description]
 * @param  {[type]} data   [description]
 *
 * @return {[type]}        [description]
 */

 function getConfigobj(url,method,data){
  let middleWare =  getMiddleWaredata();
  let token = "Bearer aAqbVgy30qDmSqT0m32rB2xON0EE_3OW";
  // if(JSON.parse(localStorage.getItem("isLogged"))){
  //   token = "Bearer "+localStorage.getItem("access_token")
  // }
  let obj = {
    method: method,
    url: process.env.REACT_APP_API_URL+ url,
    cache: "no-cache",
   headers: {
     "Content-Type": "application/json",
     "Authorization": token,
     "User-key":middleWare.utoken,
     "User-lang":middleWare.ulang,
   }
}
if(method === 'POST'|| method === 'post'|| method === 'PUT') obj.data  = JSON.stringify(data);
return obj
}
/**
 * [getMiddleWaredata description]
 *
 * @return {[type]} [description]
 */

function getMiddleWaredata(){
  const user_encrypt = localStorage.getItem('u-token');
  let utoken = 0;
  let activeSiteLanguage='en';
  if(localStorage.getItem("activeLanguage") !== null && localStorage.getItem("activeLanguage") !== undefined){
      activeSiteLanguage=localStorage.getItem("activeLanguage");
  }
  if(user_encrypt !== null && user_encrypt !== undefined){
    utoken = user_encrypt;
  }

  return{utoken:utoken,ulang:activeSiteLanguage}

}
