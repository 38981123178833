import { OCAlert } from '@opuscapita/react-alerts';
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Apiservice } from '../../services/Apiservices';


export default function UserStatus() {

  //if usser deactivated it will logout him from fronted
  const history = useHistory();
  useEffect(() => {
    var user = JSON.parse(localStorage.getItem('user'));
    const data = {
      "user_id": user ? user.id : null,
    }
    if ((user !== undefined) && (user !== null)) {
      let translations = localStorage.getItem('translations');
      Apiservice.service('api/v1/activeusers', "POST", data)
        .then(response => {
          if (response.data.status == 200) {
          } else if ((response.data.status === 403) || (response.data.status === 404)) {
            OCAlert.alertError('User inactive.', { timeOut: window.ALERT_WARNING_TIME });
            localStorage.clear();
            localStorage.setItem('translations', translations);
            // history.push('/');
            window.location.reload();
          }

        }).catch(error => {
          // OCAlert.alertError('User inactive.' + error, { timeOut: window.ALERT_WARNING_TIME });
          // localStorage.clear();
          // localStorage.setItem('translations',translations);
          // history.push('/login');
          console.error("Getting error in api response : ", error);
        })
    }
  }, []);

}