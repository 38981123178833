import React, { Component } from "react";
import ReactImageMagnify from "react-image-magnify";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
    root: {}
});
class ImageMagnifier extends Component {
    render() {
        return (
            <ReactImageMagnify
                {...{
                  smallImage: {

                    alt: "main image",
                    height: 330,
                    width: 360,
                    src: this.props.mainimage !== undefined ? this.props.mainimage : "",
                },
                  largeImage: {
                    src: this.props.mainimage !== undefined ? this.props.mainimage : "",
                    alt: "main image",
                    width:1000,
                    height:1000,
                },
                  enlargedImageContainerDimensions: {
                    width: "100%",
                    height: "100%",
                    position:"absolute",
                    display:"inline-block",
                    zIndex:"1500",

                },
                  enlargedImagePortalId: "myPortal",
                 enlargedImageClassName : "enlarged-image",
                 imageClassName:"main-product-overview",
                 imageStyle: {
                     opacity: this.props.hasUnlimitedStock === false && this.props.stock === 0 ? "0.3" : "1",
                 },
                 enlargedImageContainerStyle: {
                     backgroundColor:"white"
                 },
                 style: {
                     backgroundColor: "white"
                 }
                }}

            />
        );
    }
}

export default withStyles(styles)(ImageMagnifier);
