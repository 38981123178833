import React, { useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router";
import 'components/Login/Login.css';
import {Apiservice} from 'services/Apiservices'
import { OCAlert } from '@opuscapita/react-alerts';
import { t } from 'translations/Translate';
const UserActivation = (props) => {

    const [msg, setMsg] = useState(true);

    let params = useParams();
    useEffect(() => {
        activateUser();
    });


    const activateUser = () => {
      let data = {
          "actions" : 'activate-account',
          "uid": params.userId,
      }
      Apiservice.service('api/v1/registerusers', 'POST', data).then(result => {
          OCAlert.alertSuccess(result.data.message, { timeOut: window.ALERT_SUCCESS_TIME });
          }
        )
        .catch(error => {
          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
          setMsg(false)
        
          OCAlert.closeAlerts();
            OCAlert.alertError(t('Error occured while activating the user account.'), { timeOut: window.ALERT_ERROR_TIME });
        })
    }

    const history = useHistory();

    const login = () => {
        history.push('/login')
    }

    return (
        <div className=" changepassword">
             <div className="">
            <div className="text-left col-md-12 mt-5 pt-5 ">
                <p className="textcolor mt-4 mb-2 forgotpassword_text_font_size">{t('User activation')}</p>
            </div>
            <div className="loginForm col-md-12 pt-3">
                {msg && <p className="fontsizeofagreement pt-2">{t('Your account has been activated.')}</p>}
                <div className="text-center mt-5 pt-5 button-bottom">
                    <button onClick={login} className="btn login-btn col-12 text-center block mb-2">{t('Login')}</button>
                </div>
            </div>
        </div>
        </div>
    );
}
export default UserActivation
