import React, { } from 'react';
import { useParams } from "react-router";
import EmailForm from './EmailForm.js';
import ChangePassword from './ChangePassword.js';
import ForgotPassword from './ForgotPassword.js';
import MobileForm from './MobileForm.js';
import OtpVerify from './OtpVerify.js';
import SsoLoginForm from './SsoLoginForm.js';
import Registration from '../Registration/Register.js';
import UserActivation from '../Registration/UserActivation.js';
import './Login.css';

function SsoLogin(){
    let params = useParams();
    return (
        <section className="loginWrap">
            <div className="logininner">
                <div className="mainlogin">
                    <div className="login-bg"></div>
                    <div className="login-form">
                        {/* {params.type === "email" && <EmailForm />}
                        {params.type === "mobile" && <MobileForm />}
                        {params.type === "forgotpassword" && <ForgotPassword />}
                        {params.type === "otpverify" && <OtpVerify />}
                        {/*{params.type === "new-user" && <Registration />}*/}
                        {/* {params.type === "resetpassword" && <ChangePassword />} 
                        {params.type === "useractivation" && <UserActivation />} */}
                        <SsoLoginForm />
                    </div>
                </div>
            </div>
        </section>
    )}
export default SsoLogin;
