import React, {useState,useEffect,useCallback } from 'react'
import { Link } from "react-router-dom";
import favicon from 'images/favicon.ico';
import './LeftSideNav.css';
// import { useParams } from "react-router";
import { useHistory } from 'react-router-dom'
import Filterview from 'components/Popup/Popup.js';
import {Apiservice} from 'services/Apiservices'
import { Modal } from "react-bootstrap";
import * as Reactbootstrap from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import  home_img from './images/home.png';
import filter_img from './images/filter.png';
import search_img from './images/search.svg';
import language from './images/language.png';
import profile_img from './images/profile.png';
import shopping_img from './images/shopping-cart.png';
import { t } from '../../translations/Translate'
import { MobileView} from 'react-device-detect';
import _ from "lodash";
import SideNav, {  NavItem, NavIcon } from '@trendmicro/react-sidenav';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';


const LeftSideNav = (props) => {
    const [get_id] = useState(JSON.parse(localStorage.getItem("user")))


    const isLogged = JSON.parse(localStorage.getItem("isLogged"));
    const handleClose = () => {
        setLanguage(false);
        document.getElementById('menu-btn').checked = false;
        window.scrollTo(0,scrollPosition)
    };
    const handleCloseSearch = () => {
        setShowSearch(false);
        document.getElementById('menu-btn').checked = false;
        window.scrollTo(0,scrollPosition)
    };
    const handleCloseFilter = () => {
        document.getElementById('menu-btn').checked = false;
        setFilter(false);
    };

    useEffect(() => {
        if(get_id){
        const request = {
            "user_id": get_id.id,
            "type": "myCart"
          }
          Apiservice.service("webshopapi/shopv1/addcarts", "POST", request).then(res => {
              if(res.status === 200){
                  let qty=0;
                  let lineItems=res.data.DATA.cartDetail.lineItems;
                Object.keys(lineItems).map(e=>{
                    qty=qty+lineItems[e].qty;
                })
                document.querySelector('.cartcount').innerHTML = qty
                document.querySelector('.cartcount-main').innerHTML = qty
              }
          })
        }
    }, []);

    const history = useHistory();
    const [showHambergermenu ,setshowHambergermenu] = useState(false);
    const [showFilter ,setFilter] = useState(false);
    const [showLanguage,setLanguage] = useState(false);
    const [showSearch,setShowSearch] = useState(false);
    const [search, setSearch] = useState("");
    const [scrollPosition, setScrollPosition] = useState("");
    const [suggestions, setSuggestions] = useState("");
    const [showOptions, setShowOptions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lang, setLang] = useState(localStorage.getItem('activeLanguage'));
    const home = (e) => {

        e.preventDefault();
        history.push('/')

    }
    const expand = (e) =>{
            document.getElementById('navBar-items').style.display ="block";
    }
    const profile = (e) => {
        document.getElementById('menu-btn').checked = false;
        document.getElementById('navBar-items').style.display ="none"

        e.preventDefault();
        history.push('/myprofile/myaccount')

    }
    const mycart = (e) => {
        document.getElementById('menu-btn').checked = false;
      document.getElementById('navBar-items').style.display ="none"

        e.preventDefault();
        history.push('/mycart')
    }
    const filter = (e) =>{

      setFilter(!showFilter)
      setScrollPosition(window.pageYOffset)
      document.getElementById('menu-btn').checked = false;
      document.getElementById('navBar-items').style.display ="none"

    }
    const searchMob = (e) =>{
      setShowSearch(!showSearch)
      setScrollPosition(window.pageYOffset)
      setSearch('')
      document.getElementById('menu-btn').checked = false;
      document.getElementById('navBar-items').style.display ="none"

    }
    const lanMob = (e) =>{
       setLanguage(true)
       setScrollPosition(window.pageYOffset)
       document.getElementById('menu-btn').checked = false;
       document.getElementById('navBar-items').style.display ="none"

    }
    const handleLang =(lang)=>{
      // var user = JSON.parse(localStorage.getItem("user"));
      setLang(lang)
    //   if(user !== null){
    //   let data = {
    //       'webshopDefaultLanguage': lang,
    //     }
    // Apiservice.service(window.UPDATE_USER_LANG+'/'+user.id,'PUT',data)
    //   .then(result => {
    //     localStorage.setItem('activeLanguage',lang);
    //     window.location.reload()
    //   }).catch(function (error) {
    //     if(document.getElementById('loading-icon')!== null){
    //       document.getElementById('loading-icon').style.display = 'none';
    //     }
    //       OCAlert.alertError(error.response.data.message, { timeOut: window.ALERT_ERROR_TIME });
    //   });
    // }else{
      localStorage.setItem('activeLanguage',lang);
      window.location.reload()
    // }
    }
    const handleSubmitSearch = (e,query = undefined) => {
      if(e !== undefined){
        e.preventDefault();
      }
        let href, url
        if (window.location.href.includes('products/sale')) {
             href = window.location.pathname.split('/');

             url = '/middleware/sale/search/' + href[href.length-1] + '?q='
        }
        else if (window.location.href.includes('/productoverview/sale')) {
             href = window.location.pathname.split('/');
             url = '/middleware/sale/search/' + href[3] + '?q='
        }
        else {
             url = '/middleware/search?q='
        }
      let searchTerm =  query !== undefined ? query :search
        history.push(url+searchTerm);
        setShowSearch(false)
    }

    const handleSearch = (e) => {

      setSearch(e);
      delayedQuery(e)
    }
const delayedQuery = useCallback(_.debounce(q => getSuggestionsApi(q), 500), []);

    const getSuggestionsApi = (e) => {
        setSearch(e)
        let href, saleId
        if (e.length > 0) {
        if (window.location.href.includes('products/sale')) {
             href = window.location.pathname.split('/');
             saleId = href[href.length-1]
             var data = {
                 'autosuggestion': e,
                 'saleIds': [Number(saleId)],
                 "user_id":JSON.parse(localStorage.getItem('user')).id,
             }
        }
        else if (window.location.href.includes('/productoverview/sale')) {
             href = window.location.pathname.split('/');
             saleId = href[3]
             var data = {
                 'autosuggestion': e,
                 'saleIds': [Number(saleId)],
                 "user_id":JSON.parse(localStorage.getItem('user')).id,
             }
        }
        else {
            var data = {
                "user_id":JSON.parse(localStorage.getItem('user')).id,
                'autosuggestion': e,
                'id'    : get_id.id,
                'userSaleIds' : (localStorage.getItem('user_sales') !== null ) ? localStorage.getItem('user_sales').split(","):[],

            }
        }
        setLoading(true)
    Apiservice.service(window.FETCH_FILTER_PRODUCTS, 'POST', data).then(res => {
        setSuggestions(res.data.data)
        setShowOptions(true)
        setLoading(false)
    })
}
}
    const searchClick = (e) => {
      setSearch(e.currentTarget.innerText)
      setSuggestions('')
      setShowOptions(false)
      handleSubmitSearch(undefined,e.currentTarget.innerText);
  };
  const handleonEntered =()=> {
    if(document.getElementById('search') !== null){
    document.getElementById('search').focus();
  }
  }

    const getSearchSuggestion = () => {
        let html
        if (showOptions && search) {

        if (suggestions !== undefined && suggestions.length) {
         html = (
          <div className = 'label'>
          <ul className="global-search-sec suggestions">
          {Object.values(suggestions).map((suggestion) =>{
             return <li onClick={e => searchClick(e)} className="col-md-12"> {suggestion} </li>
          })}
          </ul>
          </div>
        )
    } else {
        html = (
         <div className = 'label'>
         <ul className="global-search-sec suggestions">
            <li className="col-md-12"> {t('No results')} </li>
         </ul>
         </div>
     )
    }
        return html;
    }
}

return(
    <section  className='desktop-left-content'>
    {showFilter && <Filterview scrollPosition={scrollPosition} handleCloseFilter={handleCloseFilter} />}
    {showLanguage &&
    <Modal
    size="sm"
    show={setLanguage}
    backdrop="static"
    dialogClassName="language-popup"
    onHide={handleClose}
    aria-labelledby="example-modal-sizes-title-sm"
    keyboard={false}
>
    <Modal.Header closeButton className="language-header" >
        <Modal.Title>{t('Select your active language')} </Modal.Title>
    </Modal.Header>
    <Modal.Body className="language-body">
       {/* <Form.Check
           type="radio"
           value="en"
           label="English"
           name="language"
           id = "english"
           checked={lang === 'en' ? true:false}
           onChange={()=>handleLang('en')}
        /> */}

        <div className="design-language">
            <h6>English</h6>
            <label class="radioWrap mb-0">
            <input type="radio" name="language" value="en" id = "english" checked={lang === 'en' ? true:false} onChange={()=>handleLang('en')}/>
            <span class="checkmark"></span>
            </label>
        </div>

        <div className="design-language">
            <h6>Dutch</h6>
            <label class="radioWrap mb-0 dutch">
            <input type="radio" name="language" value="nl" id = "dutch" checked={lang === 'nl' ? true:false} onChange={()=>handleLang('nl')}/>
            <span class="checkmark"></span>
            </label>
        </div>

        <div className="design-language">
            <h6>French</h6>
            <label class="radioWrap mb-0 french">
            <input type="radio" name="language" value="fr" id = "french" checked={lang === 'fr' ? true:false} onChange={()=>handleLang('fr')}/>
            <span class="checkmark"></span>
            </label>
        </div>


    </Modal.Body>
</Modal>

    }

    {showSearch &&
        <Reactbootstrap.Modal
            size="sm"
            show={setShowSearch}
            dialogClassName="search-popup"
            onHide={handleCloseSearch}
            aria-labelledby="example-modal-sizes-title-sm"
            keyboard={false}
            autoFocus={false}
            onEntered = {handleonEntered}
        >
        <div class="filterhead">
            <h3 >{t('Search')}</h3>
        </div>
        <Reactbootstrap.Modal.Header closeButton  className="filter-close">
        </Reactbootstrap.Modal.Header>
            <Reactbootstrap.Modal.Body className="popup-body filter-popup-body">
                <div className="design-language">
                    <form  onSubmit={handleSubmitSearch} className="loginForm col-md-12 pt-3">
                        <div className="form-group mt-3">
                            <label className="textcolor">{t('Enter the product name')}</label>
                            <input
                                type="text"
                                name="search"
                                className="form-control"
                                value={search}
                                autocomplete="off"
                                onChange={(e) => handleSearch(e.target.value)}
                                id="search"
                                required
                                aria-describedby="emailHelp"
                                placeholder={t('Search with product title')}
                                autoFocus = {true}
                            />
                        </div>
                        {!loading && getSearchSuggestion()}
                        {loading && <p className = "textcolor text-center mt-3">{t('Loading')}...</p>}
                        <button className="btn login-btn float-right">{t('Apply')}</button>
                    </form>
                </div>
            </Reactbootstrap.Modal.Body>
        </Reactbootstrap.Modal>
    }

    {isLogged && <div className="header_container">
        <div className="header">

            <input className="menu-btn" type="checkbox" id="menu-btn" onClick ={expand}/>
            <div className="mob-menu"><label className="menu-icon" for="menu-btn"><span className="navicon"></span></label></div>
            {<div className="mob-logo"><a   onClick={home}><img className="navIcon-menu" alt="" src={home_img} /></a></div>}
            <ul className="menu list-group" id = "navBar-items">
                <li className="navList list-group-item"> <MobileView><span className="cartcounts cartcount navicon badge badge-danger badge-pill" id="cartcount"></span></MobileView><img className="navIcon-header" alt="" src={shopping_img} /><a  onClick={mycart}>{t('Cart')}</a></li>
                <li className="navList list-group-item"><img className="navIcon-header" alt="" src={profile_img} /><a  onClick={profile}>{t('My profile')}</a></li>
                <li className="navList list-group-item"><img className="navIcon-header" alt="" src={search_img} /><a  onClick={searchMob}>{t('Search')}</a></li>
                <li className="navList list-group-item"><img className="navIcon-header" alt="" src={filter_img} /><a  onClick={filter}>{t('Filter')}</a></li>
                <li className="navList list-group-item"><img className="navIcon-header" alt="" src={language} /><a  onClick = {lanMob}>{t('Language')}</a></li>
            </ul>

        </div>
    </div>}

    {!isLogged && <div className="header_container">
        <div className="header">
            {/* <div className="col-10 float-left"><a   onClick={home}><img className="navIcon-menu" alt="" src={home_img} /></a></div> */}
            <div className="col-2 float-left"><a  onClick = {e => setLanguage(true)}><img className="navIcon-header" alt="" src={language} /></a></div>
        </div>
    </div>}

      <SideNav
        onSelect={(selected) => {

        }}
      >



    <SideNav.Toggle />
    <div className="nav-parent">
    <SideNav.Nav defaultSelected="home" className="nav-inner">
      {isLogged && <NavItem eventKey="home" navitemClassName="defaultClass">
            <NavIcon title={t('Home')}>
                <Link className="navLink" to="/"><img className="navIcon favIcon" alt="" src={home_img} /></Link>
            </NavIcon>
        </NavItem> }
        {isLogged && <NavItem eventKey="mycart" >
        {/* <div className = "cartcount badge" ></div> */}
            <NavIcon  title={t('My cart')}>
                <Link className="navLink" to="/mycart">
                 <img className="leftnavicon cartIcon" src={shopping_img} alt="Cart" />
                    <span className="cartcount cartcount-main badge badge-danger badge-pill" id="cartcount"></span>
                    <MobileView><span className="cartcount badge badge-danger badge-pill" id="cartcount"></span></MobileView>
                </Link>

            </NavIcon>
        </NavItem>}
        {isLogged && <NavItem eventKey="Myaccount" >
            <NavIcon title={t('My account')}>
                <Link className="navLink" to="/myprofile/myaccount">
                    <img className="leftnavicon accountIcon" src={profile_img} alt="Account" />
                </Link>
            </NavIcon>
        </NavItem>}
        {/* {isLogged && <NavItem eventKey="PickupMainpage" >
            <NavIcon title={t('Pickup locations')}>
                <Link className="navLink" to="/pickup">
                    <img className="leftnavicon accountIcon" src={profile_img} alt="Account" />
                </Link>
            </NavIcon>
        </NavItem>} */}
        {isLogged && <NavItem eventKey="search" >
            <NavIcon title={t('Search')}>
                <span className="navLink"  onClick = {e => (setShowSearch(!showSearch), setSearch(''), setScrollPosition(window.pageYOffset), window.scrollTo(0, 0))}>
                    <img className="leftnavicon" src={search_img} alt="Search" />
                </span>
            </NavIcon>
        </NavItem>}
        {isLogged && <NavItem eventKey="filter" >
            <NavIcon title={t('Apply filter')}>
                <span className="navLink"  onClick = {e => (setFilter(!showFilter), setScrollPosition(window.pageYOffset), window.scrollTo(0, 0))}>
                    <img className="leftnavicon" src={filter_img} alt="Filter" />
                </span>
            </NavIcon>
        </NavItem>}
        <NavItem>
        <NavIcon title={t('Change language')}>
                <span className="navLink"  onClick = {e => (setLanguage(true), setScrollPosition(window.pageYOffset), window.scrollTo(0, 0))}>
                   <img className="leftnavicon" src={language} alt="language" />
                </span>
            </NavIcon>

        </NavItem>
    </SideNav.Nav>
    </div>
  </SideNav>

    </section>
    )
}

export default LeftSideNav;
