import React, { useEffect } from 'react'
import { OCAlert } from '@opuscapita/react-alerts';
import { Apiservice } from '../../services/Apiservices';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useParams } from "react-router";
import { t } from 'translations/Translate';
import './paymentsuccess.css';
import download from './download.png';
import tickmark from './Tick.png';

// export default class ProductsView extends Component {

//     constructor(props) {
//         super(props)
//         this.state = {

//         }
//     }
//     componentDidMount(props) {
//         OCAlert.alertSuccess('Order placed successfully', { timeOut: window.ALERT_ERROR_TIME });
//     }
function OrderSuccessPage(props) {
    const history = useHistory();

    useEffect(() => {
            OCAlert.alertSuccess(t('Order placed successfully'), { timeOut: window.ALERT_ERROR_TIME });
    }, [])
//     history.push(null, null);
// window.addEventListener('popstate', function () {
//     history.push(null, null);
// });

    let params = useParams();
    const Invoice=()=>{
        const request = {
            "orderId":params.id
        }
        Apiservice.service(window.INVOICE, "POST", request).then(res => {
            
            try {
                if (res) {
                   window.open(res.data)
                }
            } catch (e) {
                console.error(e);
            }
        })
        }
    //     history.push(null, null, window.location.href);
    //      history.back();
    // history.forward();
    // window.onpopstate = function () { history.go(1); };


    useEffect(() => {
//         // return () => {
//         //     props.history.goForward();
//         // }
//         // history.block("Block string", { revertPopState: false });
//         // history.goForward(1)
//         disableBackButton()
window.history.pushState(null, window.document.title, window.location.href);
window.addEventListener('popstate', function (event)
{
    // alert()
    window.history.pushState(null, window.document.title, window.location.href);
});
    })
//     const disableBackButton=(e)=>
//    {
//     //  history.goForward()
//     e.preventDefault();
//    }

// window.addEventListener("onload", function (event) {
//    history.goForward(1);
//    history.push(null,null,(window.location.href))
// })
// alert(window.document.title)



        return (
            <section className="container" onAbort={history.goForward()}>
                <div className="mainAlign">
                    <div className="inneralign">
                        <img src={tickmark} className="tick colorChange" />
                        <h4 className={props.status === 'pending'?"hide-class":"mt-4 mb-2"}>{t('Your payment is successfull')}</h4>
                        <h4 className={props.status === 'pending'?"mt-4 mb-2":"hide-class"}>{t('Your payment is pending')}</h4>
                        <p className="mb-4">{t('Thank you for payment. An automated payment receipt will be sent to your registered email.')}</p>
                        <div className="col-md-12">
                            <button className=" download bg-color"  onClick={()=>Invoice()}><img src={download} />{t('Download invoice')}</button>
                            <div><Link className="text-center textcolor shop-link" to="/">{t('Continue shopping')}</Link></div>
                        </div>
                    </div>
                </div>
            </section>
        )
}
export default OrderSuccessPage;
