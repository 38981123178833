import React, { useState, useEffect } from 'react';
import './Login.css';
import { useHistory, useLocation } from "react-router-dom";
import firebase from "firebase";
import { OCAlert } from '@opuscapita/react-alerts';
import { Link } from "react-router-dom";
import LoginNav from './LoginNav.js';
import OtpInput from 'react-otp-input';
import { t } from 'translations/Translate'

const OtpVerify = (props) => {

  const [otp, setOtp] = useState('');
  const [otpStatus, setOtpStatus] = useState(true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
      document.getElementById("number_nav").className = "navbar_link btn col-6 text-left block mb-2 navbar_link--active btn-danger";
    setTimeout(function(){ setOtpStatus(false); }, 120000);
    // Auth.currentCredentials();
  }, []);

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': function (response) {

        //   // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
      }
    });
  };

  //resend otp function
  const signIn = () => {
      if (location.state === undefined) {
          return history.push("/login/mobile");
      }
    setUpRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    let mobile = location.state.number

    //Firebase function to send OTP to given number
    firebase.auth().signInWithPhoneNumber(mobile, appVerifier)
      .then(function (confirmationResult) {
        window.confirmationResult = confirmationResult;
        setOtpStatus(true)
        OCAlert.alertSuccess(t('Verification code has been sent to your mobile number.'));
      })
      .catch(function (error) {
        setOtpStatus(false)
        setOtp('')
        OCAlert.alertError(t('All otp request from this device have been temporarily blocked due to too many otp request. please try again later.'), { timeOut: window.ALERT_ERROR_TIME });

        return;
      });
  }

    //verify or send function
    function onSubmitOtp(event) {
      event.preventDefault();

        //verify otp
        let optConfirm = window.confirmationResult;
        if (optConfirm !== undefined) {
          optConfirm
            .confirm(otp)
            .then(function (result) {
              // User signed in successfully.

              let data1 ={
                id: location.state.data.id,
                firstname: location.state.data.firstName,
                lastname : location.state.data.lastName,
                language : location.state.data.active_language,
                workPhone : location.state.data.webWorkPhone,
                workMail : location.state.data.webWorkEmail,
                personalMail : location.state.data.webPEmail,
                group_id:  location.state.data.group_id,
                user_role : location.state.data.user_role,
                lastLoginDate:  location.state.data.lastLoginDate,
                terms_and_conditions:location.state.data.terms_and_conditions,
              }

              localStorage.setItem('terms_and_conditions',data1.terms_and_conditions);
              localStorage.setItem("user",JSON.stringify(data1));
              localStorage.setItem('isLogged',true);
              localStorage.setItem('activeLanguage',data1.language);
              localStorage.setItem('workPhone',data1.workPhone);
              localStorage.setItem('workMail',data1.workMail);
              localStorage.setItem('personalMail',data1.personalMail);
              localStorage.setItem('group_id',data1.group_id);
              localStorage.setItem('user_role',data1.user_role);
              let uid = location.state.data.id;
              localStorage.setItem('u-token',(((0x0000FFFF & uid) << 16) + ((0xFFFF0000 & uid) >> 16)));
              localStorage.setItem("isLogged", true);
              OCAlert.alertSuccess(t('Login successfull.'));
              history.push("/");
            })
            .catch(function (error) {

              OCAlert.alertError(t('Error. Please enter correct verification code or resend otp.'), { timeOut: window.ALERT_ERROR_TIME });
              setOtpStatus(false)
              setOtp('')
            });
        }
      }

      return (
          <section>
                <div className="">
                <div className="">
                    <p class="backButton topAdjustment" onClick={history.goBack}> </p>
                <LoginNav />
                      <form onSubmit={onSubmitOtp}>
                      <div className="mt-5">
                          <div className="form-group mb-3">
                          <OtpInput
                          value={otp}
                          containerStyle="otpContainer"
                          inputStyle="otpInput"
                          shouldAutoFocus={true}
                          onChange={(e) => setOtp(e)}
                          numInputs={6}
                          separator={<span></span>}
                          />
                        </div>
                        <div id="recaptcha-container"></div>

                        <p className="text-center"><Link onClick={signIn} className="textcolor password_text_fontsize">{t('Resend code')}</Link></p>
                        <br></br><br></br>
                        <div className='send-my-code'>
                        <button className="btn login-btn col-12 text-center block mb-2">{t('Submit')}</button>
                            {/*<p className="text-center">{t("Don't have an account?")} <Link to="/login/new-user" className="textcolor password_text_fontsize">{t('Register')}</Link></p>*/}
                        </div>
                            
                            </div>
                      </form>
                    </div>
                    </div>
          </section>
      );
  }

  export default OtpVerify;
