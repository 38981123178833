import React, { useState, useEffect } from 'react';
import { Apiservice } from '../../services/Apiservices';
import { Link } from "react-router-dom";
import { t } from '../../translations/Translate';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import './myProfile.css';
import moment from 'moment'

function Order() {
    const [ApiData, setApiData] = useState();
    const [msg, setMsg] = useState('');
    const [orderdetailPage, setorderdetailPage] = useState();
    const [get_id] = useState(JSON.parse(localStorage.getItem("user")))
    useEffect(() => {
        const request = {
            "user_id": get_id.id,
            "type": "myOrders"
        }
        Apiservice.service("webshopapi/shopv1/myorders", "POST", request).then(res => {

            try {
                if (res.status === 201) {
                    console.log(res.data.DATA);
                    setApiData(res.data.DATA);
                }
                else if (res.status === 204) {
                    setMsg(t('No orders found'))
                }
            } catch (e) {
                console.error(e);
            }
        })
    }, []);
    let len;
    if (ApiData) {
        len = ApiData.length;
    }
    let date;
    return (
        <section>
            <div className={orderdetailPage === "orderDetail" ? "hide-class" : ""}>
                {ApiData === undefined && msg === "" ? <h6 className="text-center textcolor">{t('Loading orders')}...</h6> : null}
                <p className="text-center">{msg}</p>
                <div className=" float-left font-style w-100 mb-5">
                    {ApiData && Object.keys(ApiData).map(e => (
                        <div className="col-md-12 col-lg-12 col-12 px-0 bg-white mb-3 p-3" key={e}>
                            <div className="row m-auto">
                                <div className="col-md-4 col-lg-3 col-12 pl-0 overviewspace">
                                    <div><h6 >{t('Order reference')}</h6></div>
                                    {ApiData[e].orderDetails.reference, <div><h6 className="mb-0 font-weight-normal pt-1 order-info">{ApiData[e].orderDetails.reference}</h6></div>}
                                </div>
                                <div className="col-md-4 col-lg-3 col-12 pl-0 overviewspace">
                                    <div><h6 >{t('Order placed')}</h6></div>
                                    {date = ApiData[e].orderDetails.dateOrdered.date, <div><h6 className="mb-0 font-weight-normal pt-1 order-info">{moment(date, 'DD/MM/YYYY').format("DD MMMM YYYY")}</h6></div>}
                                </div>
                                <div className="col-md-4 col-lg-3 col-12 pl-0 overviewspace">
                                    {ApiData[e].shipingAddress !== null && ApiData[e].shipingAddress.attention === 'pickupLocation' ? <div><h6>{t('Pickup location')}</h6></div> : <div><h6>{t('Ship to')}</h6></div>}
                                    {ApiData[e].shipingAddress !== null && ApiData[e].shipingAddress.attention === 'pickupLocation' ?
                                        <div><div><h6 className="mb-0 font-weight-normal pt-1 order-info">{ApiData[e].shipingAddress !== null && ApiData[e].shipingAddress.title}</h6></div></div> :
                                        <div><div><h6 className="mb-0 font-weight-normal pt-1 order-info">{ApiData[e].shipingAddress !== null && ApiData[e].shipingAddress.firstName + " " + ApiData[e].shipingAddress.lastName}</h6></div></div>
                                    }
                                </div>
                                <div className="col-md-3 col-lg-2 col-11 pl-0 overviewspace">
                                    <div><h6>{t('Status')}</h6></div>
                                    <div>{ApiData[e].orderStatuses !== null && <h6 className="mb-0 font-weight-normal pt-1 order-info">{ApiData[e].orderStatuses.shippingStatus === null ? (t('In process')) : ApiData[e].orderStatuses.shippingStatus}</h6>}</div>
                                </div>
                                <div className="col-md-1 col-lg-1 col-1 px-0 text-right order-arrow">
                                    <Link title={t('Order details')} to={"myOrderDetails/" + ApiData[e].orderDetails.id} className="text-dark"><KeyboardArrowRightOutlinedIcon className="h-25" /></Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Order;
