import React from 'react';
import { useEffect,useState } from 'react';
import { Apiservice } from '../../services/Apiservices';
import { useHistory } from 'react-router-dom'
import OrderSuccessPage from './OrderSuccessPage';
import ErrorPage from './ErrorPage';
import { useParams } from "react-router";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import './orderOverview.css';
export default function PaymentStatus() {
    let params = useParams();
    const history = useHistory();
    const [switching,setSwitching]=useState();
    const [get_id] = useState(JSON.parse(localStorage.getItem("user")))
    const [status,SetStatus] = useState();
   useEffect(() => {
    const request = {
        "user_id": get_id.id,
        "type":"orderStatus",
        "orderId":params.id
      }
      /**This function disabled the browser back button
       * npm install disable-browser-back-navigation --save for refernce
       *
       */
      Apiservice.service(window.MYORDERS, "POST", request).then(res => {

          if(res.status === 201 && res.data.DATA.status !== null){
            disableBrowserBackButton();
            
            if((res.data.DATA.lockExpiredStatus) !== null ){
              if((res.data.DATA.lockExpiredStatus).includes('duplicate payment')){
                SetStatus('duplicate')
                setSwitching('failure');
            }else {
              history.push('/order-overview/'+params.id+"/"+"2")
            }
            }
            else if((res.data.DATA.status).includes('paid') === true || (res.data.DATA.status).includes('pending') === true){
              setSwitching('success');
              if((res.data.DATA.status).includes('pending') === true){
                SetStatus('pending')
              }
              // history.replace('order-success')
            }
            else if((res.data.DATA.status).includes('canceled')=== true){
              history.push('/order-overview/'+params.id+"/"+"1")
            }
            else if((res.data.DATA.status).includes('expired')=== true){
              history.push('/order-overview/'+params.id+"/"+"2")
            }
            else{
              history.push('/order-overview/'+params.id+"/"+"1")
            }
          }
          else{
            history.push('/order-overview/'+params.id+"/"+"1")
          }
      })
},[] );
  return (
    <div>
     {switching &&(switching === 'failure'?<ErrorPage status={status} />:switching === 'success'?<OrderSuccessPage status={status}/>:null)}
    </div>
  );
}
