import React from "react";
import { Route,Switch, Redirect } from "react-router-dom";
import admin from 'pages/admin.js';
import LoginSelection from 'components/Login/LoginSelection.js';
import TermsConditions from 'components/Login/TermsConditions.js';
import Home from 'components/home/Home.js';
import ProductsView from 'components/products/ProductsView.js';
import Cart from 'components/mycart/MyCartView.js';
import Filters from 'components/filters/Filterview.js';
import MyProfileView from 'components/myprofile/MyProfileView.js'
import Address from 'components/Address/Addaddress.js';
import ProductOverview from 'components/products/ProductOverview.js';
import ManageAddress from 'components/Address/ManageAddress.js';
import Orderoverview from 'components/Orders/Orderoverview.js';
import Pickuplocation from 'components/Address/Pickuplocations.js';
import OrderSuccessPage from 'components/Orders/OrderSuccessPage.js';
import ErrorPage from 'components/Orders/ErrorPage';
import PaymentStatus from 'components/Orders/PaymentStatus';
import UpdateMyProfile from 'components/myprofile/UpdateMyProfile.js';
import Disclaimer from 'components/privacypolicy/Disclaimer.js';
import PrivacyPolicy from 'components/privacypolicy/PrivacyPolicy.js';
import Transportservice from 'components/Orders/Transportservice.js';
import SsoLogin from 'components/Login/SsoLogin.js';
import SsoLoginMiddlewere from 'components/Login/SsoLoginMiddlewere.js';
import SsoLogoutMiddlewere from 'components/logout/SsoLogoutMiddleware.js';
import Pickup from 'components/PickupOrders/Pickup.js';
import PickupLocOrders from 'components/PickupOrders/PickupLocOrders.js';
import ScanOrder from 'components/PickupOrders/ScanOrder.js';
import EditOrders from 'components/PickupOrders/EditOrders.js';

const isLogged = JSON.parse(localStorage.getItem("isLogged"));
const NotFoundRedirect = () => {
                  return<>
                     {!isLogged && <Redirect to='/login/email' />}
                     {isLogged && <Redirect to='/' />}
                     </>
                   }
const BaseRouter =(props) =>(
      <Switch>
      {isLogged &&<Route exact path="/admin" component={admin} />}
      {isLogged &&<Route exact path="/" component={Home} />}
      {isLogged && <Route exact path = "/flashsale" component = {Home} />}
      {isLogged && <Route exact path = "/middleware/sale/filters/:id" component ={Filters} />}
      {isLogged && <Route exact path = "/middleware/filters" component ={Filters} />}
      {isLogged && <Route exact path = "/middleware/sale/search/:saleId" component ={Filters} />}
      {isLogged && <Route exact path = "/middleware/search" component ={Filters} />}
      <Route exact path="/login/:type" component={LoginSelection} />
      <Route exact path="/sso-middlewere" component={SsoLoginMiddlewere} />
      <Route exact path="/sso-login" component={SsoLogin} />
      <Route exact path="/login/:type/:userId" component={LoginSelection} />
      <Route exact path="/termsconditions" component={TermsConditions} />
      {isLogged && <Route exact path="/sso-logout" component={SsoLogoutMiddlewere} />}
      {isLogged && <Route exact path="/products" component={ProductsView} />}
      {isLogged && <Route exact path="/products/sale/:sale/:saleId" component={ProductsView} />}
      {isLogged && <Route exact path="/products/sale/filters/:filtersId" component={ProductsView} />}
      {isLogged && <Route exact path="/products/sale/search/:saleId" component={ProductsView} />}
      {isLogged && <Route exact path="/products/filter" component={ProductsView} />}
      {isLogged && <Route exact path="/products/search" component={ProductsView} />}
      {isLogged && <Route exact path="/productoverview/sale/:saleId/:productId/:variantId" component={ProductOverview} />}
      {isLogged && <Route exact path="/productoverview/filters/:productId/:variantId" component={ProductOverview} />}
      {isLogged && <Route exact path="/productoverview/cart/:productId/:variantId" component={ProductOverview} />}
      {isLogged && <Route exact path="/myprofile" component={MyProfileView} />}
      {isLogged && <Route exact path="/myprofile/:myorders" component={MyProfileView} />}
      {isLogged && <Route exact path="/myprofile/:myorders/:id" component={MyProfileView} />}
      {isLogged && <Route exact path="/myprofile/:myorders/:id/:cancelledorder" component={MyProfileView} />}
      {isLogged && <Route exact path = "/mycart" component ={Cart} />}
      {isLogged && <Route exact path = "/shippingaddress" component = {ManageAddress} />}
      {isLogged && <Route exact path = "/shippingaddress/:orderId" component = {ManageAddress} />}
      {isLogged && <Route exact path = "/billingaddress" component = {ManageAddress} />}
      {isLogged && <Route exact path = "/billingaddress/:orderId" component = {ManageAddress} />}
      {isLogged && <Route exact path = "/editAddress/:id" component = {Address}/>}
      {isLogged && <Route exact path = "/editAddress/:id/:orderId" component = {Address}/>}
      {isLogged && <Route exact path = "/editAddress/:id/:orderId/:check" component = {Address}/>}
      {isLogged && <Route exact path = "/addbilling/:shipppingId" component = {Address} />}
      {isLogged && <Route exact path = "/order-overview" component = {Orderoverview} />}
      {isLogged && <Route exact path = "/order-overview/:orderId" component = {Orderoverview} />}
      {isLogged && <Route exact path = "/order-overview/:orderId/:status" component = {Orderoverview} />}
      {isLogged && <Route exact path = "/locations" component = {Pickuplocation} />}
      {isLogged && <Route exact path = "/updateprofile" component = {UpdateMyProfile} />}
       <Route exact path = "/privacypolicy" component = {PrivacyPolicy} />
       <Route exact path = "/disclaimer" component = {Disclaimer} />
      {isLogged && <Route exact path = "/order-success/:id" component = {OrderSuccessPage} />}
      {isLogged && <Route exact path = "/order-success" component = {OrderSuccessPage} />}
      {isLogged && <Route exact path = "/errorPage" component = {ErrorPage} />}
      {isLogged && <Route exact path = "/paymentStatusPage/:id" component = {PaymentStatus} />}
      {isLogged && <Route exact path = "/transport/:orderId" component = {Transportservice} />}
      {isLogged && <Route exact path = "/pickup" component = {Pickup} />}
      {isLogged && <Route exact path = "/pickuplocorder" component = {PickupLocOrders} />}
      {isLogged && <Route exact path = "/scanorder" component = {ScanOrder} />}
      {isLogged && <Route exact path = "/editorders" component = {EditOrders} />}
 

      <Route exact path = "*" component= {NotFoundRedirect} />
      </Switch>
)
export default BaseRouter;
