import React,{useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import {Apiservice} from 'services/Apiservices'
import { t } from '../../translations/Translate'
import './Login.css';
import { OCAlert } from '@opuscapita/react-alerts';
import LoginNav from './LoginNav.js';

const EmailForm = () => {
    useEffect(() => {

     if(JSON.parse(localStorage.getItem("isLogged"))){
       window.location.href = '/';
     }
        //  document.getElementById("img-fluid").style.display="block";
    }, []);
    const [loginData, setLoginData] = useState({ email: '', password: ''});
    const [showPassword, setShowPassword] = useState(false);
    const handleChange = (event) => {
            setLoginData({...loginData, [event.target.name]: event.target.value})
        }
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
          "actions" : 'login-password-webshop',
          "username": loginData.email,
          "password": loginData.password,
          "from":window.ORIGIN_REQUEST,
        }
        Apiservice.service('api/v1/logins', 'POST', data).then(res =>{
            if(res.status===200){
                let data ={
                  id:res.data.data[0].id,
                  firstname: res.data.data[0].firstName,
                  lastname : res.data.data[0].lastName,
                  language : res.data.data[0].webshop_active_language,
                  workPhone : res.data.data[0].webWorkPhone,
                  workMail : res.data.data[0].webWorkEmail,
                  personalMail : res.data.data[0].webPEmail,
                  group_id:res.data.data[0].group_id,
                  user_role:res.data.data[0].user_role,
                  lastLoginDate:res.data.data[0].lastLoginDate,

                }
                localStorage.setItem('terms_and_conditions',res.data.data[0].webshop_terms_and_conditions);
                localStorage.setItem("user",JSON.stringify(data));
                localStorage.setItem('isLogged',true);
                localStorage.setItem('activeLanguage',data.language);
                localStorage.setItem('workPhone',data.workPhone);
                localStorage.setItem('workMail',data.workMail);
                localStorage.setItem('personalMail',data.personalMail);
                localStorage.setItem('group_id',data.group_id);
                localStorage.setItem('user_role',data.user_role);
                let uid = res.data.data[0].id;
                localStorage.setItem('u-token',(((0x0000FFFF & uid) << 16) + ((0xFFFF0000 & uid) >> 16)));
                localStorage.setItem("isLogged", true);
                OCAlert.alertSuccess(t('Login successfull.'));
                history.push('/');
            }
        })
        .catch(function (error) {

          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
          if (error.response.data.message === 'Incorrect password') {
              OCAlert.closeAlerts();
              OCAlert.alertError(t('Incorrect password'), {timeOut: window.ALERT_ERROR_TIME});
          }
          else if (error.response.data.message === 'Email does not exist') {
              OCAlert.closeAlerts();
              OCAlert.alertError(t('Email does not exist'), {timeOut: window.ALERT_ERROR_TIME});
          }
          else if (error.response.data.message === 'User inactive') {
              OCAlert.closeAlerts();
              OCAlert.alertError(t('User inactive'), {timeOut: window.ALERT_ERROR_TIME});
          }
          else {
              OCAlert.alertError(error.response.data.message, { timeOut: window.ALERT_ERROR_TIME });
          }
        });
    }

    const history = useHistory();
    const isLogged = JSON.parse(localStorage.getItem("isLogged"));
    return (

        <div className="login-form1" >
        {!isLogged &&
            <div className="" >
            <p class="Email-form-class mob-top-space topAdjustment" > </p>
            <LoginNav />
                    <form onSubmit={handleSubmit} className="loginForm" >
                        <div className="mt-3">
                            <div className="form-group mb-3">
                                <label className="textcolor email_text_fontsize">{t('Email')}</label>
                                <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={loginData.email}
                                    onChange={handleChange}
                                    id="email"
                                    required
                                    aria-describedby="emailHelp"
                                    placeholder={t('Enter email')}
                                    autoFocus
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="textcolor password_text_fontsize">{t('Password')}</label>
                                <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                                <div className="passsection">
                                    <input
                                        type={(showPassword ? "text" : "password")}
                                        name="password"
                                        id="password"
                                        required
                                        className="form-control"
                                        placeholder={t('Enter password')}
                                        value={loginData.password}
                                        onChange={handleChange}
                                    />
                                    <span className={(showPassword ? "passvisible" : "passinvisible")} onClick={() => setShowPassword(!showPassword)}></span>
                                </div>
                            </div>
                            <div className="form-group">
                                <p className="text-right">
                                    <Link to="/login/forgotpassword" className="textcolor password_text_fontsize">{t('Forgot password?')}</Link>
                                </p>
                            </div>

                            <div className="col-md-12 text-center  mb-3 p-0">
                                <button type="submit" className="btn login-btn col-12 text-center block mb-2">{t('Login')}</button>
                            </div>
                            {/*<p className="text-center mb-5">{t("Don't have an account?")} <Link to="/login/new-user" className="textcolor password_text_fontsize">{t('Register')}</Link></p>*/}
                        </div>
                    </form>
                    </div>
                }
        </div>
    )
}
export default EmailForm
